import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const CircularProgressWithLabel = (props) => {
  return (
    <div 
      style={{ 
        position: "relative", 
        display: "inline-flex",           
        marginLeft: 10,
        top: 15 
      }}>
      <CircularProgress variant="determinate" {...props} />
      <div 
        style={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0, 
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </div>
    </div>
  );
};

const CircularStatic = ({ actionProgress }) => {
  return <CircularProgressWithLabel value={actionProgress.total > 0? actionProgress.complete / actionProgress.total * 100: 0} />;
};

export default CircularStatic;
