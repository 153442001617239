import React from "react";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useTranslation } from 'react-i18next';

const NewPlaceAlert = ({ alertNewPlace, hideAlert, getRecentPlaces }) => {
  const { t } = useTranslation();

  return (
    <div className={`new-places-found ${alertNewPlace ? "has-new" : "no-new"}`}>
      <div className="close-new-places-alert">
        <button onClick={() => hideAlert()}>x</button>
      </div>
      <Typography>{t("There is a new report.")}</Typography>
      <div className="load-new-places">
        <Button onClick={() => { getRecentPlaces(); hideAlert(); }}>{t("New Update")}</Button>
      </div>
    </div>
  );
};

export default NewPlaceAlert;
