import React from "react";

import PlaceSelectDropdown from "../components/PlaceSelectDropdown";

const PlaceSelect = ({
  isLiveStream,
  selectedOpen,
  incidentTagFormOpen,
  handleSelectedOpen,
  handleSelectedClose,
  handleCheckAll,
  handleMakeTaisaku,
  handleMarkSelectedAsDeleted,
  handlePrintSelected,
  handleExportSelected,
  selectedAction,
  loadingAction,
  getPlacesByArea,
  handlePrintViewMode,
  handleCreateIncidentTag,
  incidentTags,
  hasTaisaku,
  disabledTaisaku,
  allChecked,
  actionProgress
}) => {
  return (
    <div className={`amount-per-page-action-container ${isLiveStream? "is-live" : null}`}>
      {isLiveStream ? null : (
        <div className="place-select-menu-container">
          <PlaceSelectDropdown
            selectedOpen={selectedOpen}
            incidentTagFormOpen={incidentTagFormOpen}
            handleSelectedOpen={handleSelectedOpen}
            handleSelectedClose={handleSelectedClose}
            selectedAction={selectedAction}
            actionProgress={actionProgress}
            loadingAction={loadingAction} 
            handleCheckAll={handleCheckAll}
            handleMakeTaisaku={handleMakeTaisaku}
            handlePrintSelected={handlePrintSelected}
            handleExportSelected={handleExportSelected}
            handleMarkSelectedAsDeleted={handleMarkSelectedAsDeleted}
            getPlacesByArea={getPlacesByArea}
            handlePrintViewMode={handlePrintViewMode} 
            handleCreateIncidentTag={handleCreateIncidentTag} 
            incidentTags={incidentTags}
            hasTaisaku={hasTaisaku}
            disabledTaisaku={disabledTaisaku}
            allChecked={allChecked}
          />
        </div>
      )}
    </div>
  );
};

export default PlaceSelect;
