import React from "react";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";

import "../styles/filter-edit-dialog.scss";
import FilterDialogNavbar from "./FilterDialogNavbar";
import FilterDialogTable from "./FilterDialogTable";
import FilterDialogForm from "./FilterDialogForm";
import AlertSnackbar from "./AlertSnackbar";
import { withTranslation } from 'react-i18next';

const geocore = require("@mekasmith/geocore");

const Transition = props => {
  return <Slide direction="left" {...props} />;
};

class FilterDialog extends React.Component {
  state = {
    hasFilterForm: false,
    filterObj: {},
    editing: false,
    facilities: null,
    hasFacilities: false,
    selectedFacilityGroup: null,
    openDelete: false,
    deletedAlert: false,
    deletedAlertMessage: "",
    loadingDelete: false,
  };

  toggleFilterForm = filterObj => {
    if (filterObj) {
      this.setState(prevState => ({
        hasFilterForm: !prevState.hasFilterForm,
        filterObj,
        editing: true
      }));
    } else {
      this.setState(prevState => ({
        hasFilterForm: !prevState.hasFilterForm,
        filterObj: {},
        editing: false
      }));
    }
  };

  openAddForm = () => {
    this.setState({
      hasFilterForm: true,
      filterObj: {},
      editing: false
    });
  };

  toggleFacilityPlaces = facilityGroup => {
    if (facilityGroup) {
      new geocore.places.query()
        .withTagIds(facilityGroup.id)
        .all()
        .then(facilities => {
          this.setState({
            facilities,
            hasFacilities: true,
            selectedFacilityGroup: facilityGroup
          });
        });
    } else {
      this.setState({
        hasFilterForm: false,
        filterObj: {},
        editing: false,
        facilities: null,
        hasFacilities: false,
        selectedFacilityGroup: null
      });
    }
  };

  addTableFacility = (facilityGroup, facility) => {
    let facilities = [...this.state.facilities];
    facilities.push(facility);

    this.setState({
      facilities,
      hasFacilities: true,
      selectedFacilityGroup: facilityGroup,
      hasFilterForm: false
    });
  };

  editTableFacility = (facilityGroup, editedFacility) => {
    let facilities = [...this.state.facilities];
    const facilityIndex = facilities.findIndex(facility => facility.id === editedFacility.id);

    this.setState(prevState => ({
      facilities: [
        ...prevState.facilities.slice(0, facilityIndex),
        editedFacility,
        ...prevState.facilities.slice(facilityIndex + 1)
      ],
      hasFacilities: true,
      selectedFacilityGroup: facilityGroup,
      hasFilterForm: false,
      editing: false
    }));
  };

  handleOpenDelete = () => {
    this.setState({ openDelete: true });
  };

  handleCloseDelete = deletedItem => {
    const { t } = this.props;

    if(deletedItem) {
      if(this.props.tag === t("Facility Information") && this.state.selectedFacilityGroup) {
        this.removeFacilityPlace(deletedItem.id);
        this.props.removeFacilityMarker(deletedItem.id, this.state.selectedFacilityGroup);
      } else if (this.props.tag === t("Facility Information")) {
        this.handleRemoveFacility(deletedItem.id);
      } else if (this.props.tag === t("Admin Comment")) {
        this.props.removeCommentMarker(deletedItem.id);
      } else if (this.props.tag === t("Incident")) {
        this.props.removeIncidentTag(deletedItem.id);
        this.props.removePlaceIncident(deletedItem.id);
      } else if (this.props.tag === t("Devices")) {
        this.handleRemoveGroup(deletedItem.id);
      }

      this.setState({ 
        hasFilterForm: false,
        filterObj: {},
        editing: false,
        openDelete: false,
        loadingDelete: false,
        deletedAlert: true, 
        deletedAlertMessage: `${deletedItem.name} ${"successfully deleted."}`
      });

    } else {
      this.setState({ openDelete: false, loadingDelete: false });
    }
  };

  toggleLoadingDelete = loadingDelete => {
    this.setState({ loadingDelete });
  };

  handleAlertClick = () => {
    this.setState({ 
      deletedAlert: false, 
      deletedAlertMessage: "" 
    });
  };

  removeFacilityPlace = placeId => {
    const { facilities } = this.state;
    
    const filteredFacilities = facilities.filter(facility => {
      return facility.id !== placeId;
    });

    this.setState({ 
      facilities: filteredFacilities
    });
  };

  handleRemoveGroup = groupId => {
    const groups = this.props.filterListObjs;

    const filteredGroups = groups.filter(group => {
      return group.id !== groupId;
    });

    this.setState({ 
      filterListObjs: filteredGroups
    });

    this.props.removeGroup(groupId);
    this.props.removeGroupPlaces(groupId);
  };

  handleRemoveFacility = facilityId => {
    const facilities = this.props.filterListObjs;

    const filteredFacilities = facilities.filter(facility => {
      return facility.id !== facilityId;
    });

    this.setState({ 
      filterListObjs: filteredFacilities
    });

    this.props.removeFacilityGroup(facilityId);
    this.props.removeFacilityMarkers(facilityId);
  };

  render() {
    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.props.handleClose}
        TransitionComponent={Transition}
        style={{ margin: "0 0 0 50%" }}
        className={this.props.isLiveApp? "dark-mode-menu": "light-mode-menu"}
      >
        <AlertSnackbar
          isType="error"
          isOpen={this.state.deletedAlert}
          handleClick={this.handleAlertClick}
          message={this.state.deletedAlertMessage}
        />
        <div className="filter-edit-dialog-container">
          <FilterDialogNavbar
            hasFilterForm={this.state.hasFilterForm}
            toggleFilterForm={this.toggleFilterForm}
            hasFacilities={this.state.hasFacilities}
            toggleFacilityPlaces={this.toggleFacilityPlaces}
            handleClose={this.props.handleClose}
            tag={this.props.tag}
            openAddForm={this.openAddForm}
            editing={this.state.editing}
            handleOpenDelete={this.handleOpenDelete}
          />
        </div>
        <div className="cat-drill-down-list-container">
          {this.state.hasFilterForm ? (
            <FilterDialogForm
              user={this.props.user}
              toggleFilterForm={this.toggleFilterForm}
              addTableFacility={this.addTableFacility}
              editTableFacility={this.editTableFacility}
              parentGroup={this.props.parentGroup}
              getGroups={this.props.getGroups}
              getTags={this.props.getTags}
              getComments={this.props.getComments}
              filterObj={this.state.filterObj}
              editing={this.state.editing}
              tag={this.props.tag}
              hasFacilities={this.state.hasFacilities}
              selectedFacilityGroup={this.state.selectedFacilityGroup}
              openDelete={this.state.openDelete}
              handleCloseDelete={this.handleCloseDelete}
              createNewFacilityMarker={this.props.createNewFacilityMarker}
              addComment={this.props.addComment}
              editComment={this.props.editComment}
              updateIncidentPlaces={this.props.updateIncidentPlaces}
              geocoreAddIncidentTag={this.props.geocoreAddIncidentTag}
              updateFacilityGroup={this.props.updateFacilityGroup}
              updateFacilityMarkers={this.props.updateFacilityMarkers}
              editFacility={this.props.editFacility}
              loadingDelete={this.state.loadingDelete}
              toggleLoadingDelete={this.toggleLoadingDelete}
            />
          ) : (
            <FilterDialogTable
              filterListObjs={
                this.state.hasFacilities
                  ? this.state.facilities
                  : this.props.filterListObjs
              }
              toggleFilterForm={this.toggleFilterForm}
              toggleFacilityPlaces={this.toggleFacilityPlaces}
              tag={this.props.tag}
              hasFacilities={this.state.hasFacilities}
            />
          )}
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(FilterDialog)
