  
import React from "react";

import DefaultLocationMap from "../components/DefaultLocationMap";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from 'react-i18next';

import Loader from "react-loader-spinner";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
    },
  },
};

class DefaultSettingsDialog extends React.Component {
  state = {
    geosearch: "",
    geosearchAddress: "",
    lat: null,
    lng: null,
    zoom: "",
    initialLocation: null,
    initialLocationText: null,
    map: null,
    marker: null
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.map) {
      window.google.maps.event.addListenerOnce(
        this.state.map,
        "tilesloaded",
        () => {
          if (!this.state.marker) {
            this.createInitialMarker();
          }
          this.initMapAutocomplete();
        }
      );
    }
    if (
      this.props.open !== prevProps.open &&
      this.state.initialLocation === null
    ) {
      this.geocodeLatLng(this.props.initialLat, this.props.initialLng);
    }
    if(this.state.marker) {
      this.state.marker.setMap(this.state.map);
    }
  }

  initMapAutocomplete = () => {
    const { map, marker, zoom } = this.state;
    if (map) {
      // Link MapSearchBar to map and position it
      let input = document.getElementById("default-geosearch");
      let searchBox = new window.google.maps.places.SearchBox(input);

      // Listen for user's selection and retrieve details for that selection
      searchBox.addListener("places_changed", () => {
        let places = searchBox.getPlaces();

        if (places.length === 0) {
          return;
        }

        // For each place, get the icon, name and location.
        let bounds = new window.google.maps.LatLngBounds();
        places.forEach(place => {
          if (!place.geometry) {
            console.log("Returned place contains no geometry");
            return;
          }

          marker.setPosition({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          });

          this.setState({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            geosearch: place.formatted_address,
            initialLocation: place.formatted_address,
            geosearchAddress: place.formatted_address,
          });

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });

        // fitBounds works asynchronously and throws off the zoom, so set max zoom to the initialZoom, then reset
        const maxZoom = map.maxZoom;

        map.setOptions({ maxZoom: zoom? zoom: this.props.initialZoom?  this.props.initialZoom: maxZoom });
        map.fitBounds(bounds);
        map.setOptions({ maxZoom });
      });
    }
  };

  createInitialMarker = () => {
    const { map, lat, lng } = this.state;

    if (map) {
      const selectedLat = lat ? lat : this.props.initialLat;
      const selectedLng = lng ? lng : this.props.initialLng;

      const marker = new window.google.maps.Marker({
        position: {
          lat: selectedLat,
          lng: selectedLng
        },
        map,
        animation: window.google.maps.Animation.DROP,
        draggable: true
      });

      this.setState({ marker });

      marker.addListener("dragend", () => {
        this.geocodeLatLng(marker.position.lat(), marker.position.lng());
        this.setState({ 
          marker,
          lat: marker.position.lat(),
          lng: marker.position.lng()
        });
      });
    }
  };

  zoomRange = (min, max) =>
    Array(max - min + 1)
      .fill()
      .map((_, idx) => min + idx);

  handleZoomLevels = (zoomRange) => {
    const zoomLevels = zoomRange(4, 30);
    return zoomLevels.map((zoomLevel) => (
      <MenuItem key={`zoomkey-${zoomLevel}`} value={zoomLevel}>
        {zoomLevel}
      </MenuItem>
    ));
  };

  handleChange = (e) => {
    const eventName = e.target.name;

    if (eventName === "zoom") {
      const selectedZoom = e.target.value;
      this.state.map.setZoom(selectedZoom)
      this.setState({ [eventName]: selectedZoom });
    } else {
      this.setState({ [eventName]: e.target.value });
    }
  };

  geocodeLatLng = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const LatLng = { lat, lng };

    geocoder.geocode({ location: LatLng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          this.setState({ 
            initialLocation: results[0].formatted_address,
            geosearch: results[0].formatted_address,
            geosearchAddress: results[0].formatted_address
          });
          if (!this.state.initialLocationText) {
            this.setState({ initialLocationText: results[0].formatted_address });
          }
        } else {
          console.log("No results found");
        }
      } else {
        console.log("Geocoder failed due to: " + status);
      }
    });
  };

  truncate = (input, length) => {
    if (input.length > length) return input.substring(0, length) + "...";
    else return input;
  };

  updateInitialLocation = () => {
    this.setState({ 
      initialLocation: this.state.geosearchAddress,
      initialLocationText: this.state.geosearchAddress
    });
  };

  adjustSelectedZoom = zoom => {
    this.setState({ zoom });
  };

  render() {
    const {
      open,
      close,
      initialZoom,
      initialLat,
      initialLng,
      loadingAction,
      updateSettings,
    } = this.props;

    const { lat, lng, zoom, initialLocationText, geosearch } = this.state;

    const selectedLat = lat ? lat : initialLat;
    const selectedLng = lng ? lng : initialLng;
    const selectedZoom = zoom ? zoom : initialZoom;
    const { t } = this.props;

    return (
      <Dialog open={open} onClose={close} className="initial-location-modal">
        <Typography variant="h6" className="zoom-level-select-header">
          {t("Default Settings")}
        </Typography>
        <div className="default-zoom-container">
          <Typography className="default-zoom-title">{t("Default Zoom")}</Typography>
          <FormControl className="default-zoom-select">
            <InputLabel id="zoom-level-select-label">
              {initialZoom ? initialZoom : t("Zoom")}
            </InputLabel>
            <Select
              id="zoom-level-select"
              name="zoom"
              value={zoom}
              onChange={this.handleChange}
              MenuProps={MenuProps}
            >
              {this.handleZoomLevels(this.zoomRange)}
            </Select>
          </FormControl>
        </div>
        <div className="default-location-container">
          <Typography>{t("Default Location")}</Typography>
          {initialLocationText ? (
            <Typography className="default-location-text">
              {initialLocationText}
            </Typography>
          ) : (
            <div></div>
          )}
          <FormControl margin="normal" fullWidth>
            <TextField
              name="geosearch"
              type="default-geosearch"
              id="default-geosearch"
              label={t("Search Location")}
              value={geosearch}
              autoComplete="off"
              variant="outlined"
              onChange={this.handleChange}
            />
          </FormControl>
          <div className="comment-map-container">
            <DefaultLocationMap
              id="default-location-map"
              options={{
                center: {
                  lat: selectedLat,
                  lng: selectedLng
                },
                zoom: selectedZoom,
                maxZoom: 30
              }}
              onMapLoad={(map) => {
                this.setState({ map });
              }}
              adjustSelectedZoom={this.adjustSelectedZoom}
            />
          </div>
        </div>
        <div className="live-snapshot-buttons">
          <Button
            variant="contained"
            color="primary"
            className="close-button"
            onClick={close}
          >
            {t("Back")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="confirm-button"
            onClick={() => {
              updateSettings(selectedZoom, selectedLat, selectedLng);
              this.updateInitialLocation();
            }}
          >
            {t("Confirm")}
            {loadingAction ? (
              <div>
                <Loader type="TailSpin" color="white" height={15} width={15} />
              </div>
            ) : null}
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(DefaultSettingsDialog);