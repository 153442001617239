import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import EditPlaceListRadioButtons from "./EditPlaceListRadioButtons";

import HazardPhotoUploader from "./HazardPhotoUploader";

import AddPublicPlaceDialog from "./AddPublicPlaceDialog";
import DeletePlaceDialog from "./DeletePlaceDialog"
import EditPlaceListDialogNavbar from "./EditPlaceListDialogNavbar";

import axios from "axios";
import EditPlaceMap from "./EditPlaceMap";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { addTagsById } from '../helpers/geocore-helpers';
import { withTranslation } from 'react-i18next';

const geocore = require("@mekasmith/geocore");

const styles = {
  publicBtn: {
    backgroundColor: "#0D6AAD",
    color: "white",
    "&:hover": {
      backgroundColor: "#0A5387",
    },
  },
  taisakuLabelContainer: {
    margin: "0 auto",
    textAlign: "center",
  },
  taisakuLabel: {
    fontWeight: "bolder"
  }
};

const CustomCheckbox = withStyles({
  root: {
    color: "#57697d",
    "&$checked": {
      color: "#57697d",
    },
  },
  checked: {},
})(Checkbox);

class FilterDialogForm extends React.Component {
  state = {
    map: null,
    marker: null,
    title: null,
    userName: null,
    comment: null,
    hazardLevel: null,
    uploading: false,
    acceptedFile: null,
    rejectedFile: null,
    uploaded: false,
    checkedTaisaku: false,
    checkedIncidents: {},
    viewMoreIncidents: false,
    parsedLevels: null
  };

  componentDidMount() {
    if (this.props.levels) {
      this.parseLevels();
    }
    if (this.props.selectedPlace.taisaku === "true") {
      this.setState({ checkedTaisaku: true });
    }
    if (this.props.selectedPlace.incidentTags.length > 0) {
      let checkSelectedPlaceIncidents = {};
      this.props.selectedPlace.incidentTags.forEach(incidentTag => checkSelectedPlaceIncidents[incidentTag.id] = true);
      this.setState({
        checkedIncidents: checkSelectedPlaceIncidents
      });
    }
  }

  componentDidUpdate(prevState) {
    if (!prevState.map) {
      window.google.maps.event.addListenerOnce(
        this.state.map,
        "tilesloaded",
        () => {
          if (!this.state.marker) {
            this.createInitialMarker();
          }
        }
      );
    }
  }

  initAutocomplete = (map) => {	
    if (map) {	
      // Link MapSearchBar to map and position it	
      let input = document.getElementById("pac-input-edit-place");	
      input.setAttribute("placeholder", "検索する"); 
      let searchBox = new window.google.maps.places.SearchBox(input);	
      map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);
      // Listen for user's selection and retrieve details for that selection	
      searchBox.addListener("places_changed", () => {	
        let places = searchBox.getPlaces();	

        if (places.length === 0) {	
          return;	
        }	

        // For each place, get the icon, name and location.	
        let bounds = new window.google.maps.LatLngBounds();	
        places.forEach((place) => {	
          if (!place.geometry) {	
            console.log("Returned place contains no geometry");	
            return;	
          }	

          let marker = this.state.marker;	

          marker.setPosition({	
            lat: place.geometry.location.lat(),	
            lng: place.geometry.location.lng(),	
          });	

          if (place.geometry.viewport) {	
            // Only geocodes have viewport.	
            bounds.union(place.geometry.viewport);	
          } else {	
            bounds.extend(place.geometry.location);	
          }	
        });	
        // fitBounds works asynchronously and throws off the zoom, so set max zoom to the initialZoom, then reset
        const initialZoom = this.props.user.customData && this.props.user.customData.initialZoom? parseInt(this.props.user.customData.initialZoom): 6;

        map.setOptions({ maxZoom: initialZoom });
        map.fitBounds(bounds);
        map.setOptions({ maxZoom: map.maxZoom });
      });	
    }	
  };

  editPlace = () => {
    let selectedPlace = this.props.selectedPlace;
    let taisakuFilter = this.state.checkedTaisaku ? "true" : null;
    let comment = this.state.comment
      ? this.state.comment
      : selectedPlace.comment;

    let updatedBy = this.parseUpdatedBy(selectedPlace.updatedBy);

    let place = {
      id: selectedPlace.placeId,
      name: selectedPlace.placeId,
      description: comment,
      point: {
        latitude: this.state.marker? this.state.marker.position.lat() : selectedPlace.geo.latitude,
        longitude: this.state.marker? this.state.marker.position.lng() : selectedPlace.geo.longitude,
      },
      customData: {
        "comment.1": comment,
        taisaku: taisakuFilter,
        updatedBy
      },
    };

    if (selectedPlace.isLiveStream) {
      let title = this.state.title ? this.state.title : selectedPlace.title;
      place["shortDescription"] = title;
    }

    this.geocorePlacesUpdate(selectedPlace, place);
  };

  parseUpdatedBy = (updatedBy) => {
    let updatedByArr = [];

    if (updatedBy) {
      let parsedUpdatedBy = JSON.parse(updatedBy);

      if (updatedBy.includes(this.props.user.id)) {
        let updatedByIndex = parsedUpdatedBy.findIndex(updatedBy => updatedBy.id === this.props.user.id);
        parsedUpdatedBy[updatedByIndex].timestamp = new Date().getTime();
      } else {
        parsedUpdatedBy.push({ id: this.props.user.id, timestamp: new Date().getTime() });
      }

      updatedByArr = parsedUpdatedBy;
    } else {
      updatedByArr = [{ id: this.props.user.id, timestamp: new Date().getTime() }];
    }

    return JSON.stringify(updatedByArr);
  };

  geocoreTagUpdate = async (placeId) => {
    const updatedTag = await geocore.places.tags
      .update(placeId, [this.state.hazardLevel])
      .then((place) => {
        return place;
      });
    return updatedTag;
  };

  geocorePlacesUpdate = async (selectedPlace, placeUpdate) => {
    try {
      const updatedPlace = await geocore.places.update(selectedPlace.placeId, placeUpdate);

      let isCategoryPlaceId = this.isCategoryPlaceId(updatedPlace.id);

      if (!isCategoryPlaceId) {
        let incidentTags = [];

        if (this.state.checkedIncidents) {
          let selectedIncidents = "";

          const incidentIds = Object.keys(this.state.checkedIncidents);          
          const addIncidentIds = [];
          const removeIncidentsIds = [];

          incidentIds.forEach(incidentId => {
            // check if tag is already attached to the place
            if (this.props.selectedPlace.incidentTags.some(placeIncidentTag => placeIncidentTag.id === incidentId)) {
              if (this.state.checkedIncidents[incidentId]) {
                // since tag is already attached and is checked, do nothing
              } else {
                // tag is attached but has been unchecked, needs removed from place
                removeIncidentsIds.push(incidentId);
              }
            } else {
              // tag is not attached to place
              if (this.state.checkedIncidents[incidentId]) {
                // tag has been checked and needs attached to the place
                addIncidentIds.push(incidentId)
              } else {
                // tag is not attached and the checkbox was toggled, do nothing
              }
            }
          });

          // gather incident ids for incidents that are to be deleted in a comma separated string
          if (removeIncidentsIds.length > 0) {
            removeIncidentsIds.forEach(incidentId => {
              selectedIncidents += `${incidentId},`;
            });
            
            const deleteIncidentTagsPromise = await axios({
              method: 'POST',
              url: `${geocore.SETTINGS.BASE_URL}/places/${updatedPlace.id}/tags?del_tag_ids=${selectedIncidents}`,
              headers: {
                "Geocore-Access-Token": `${geocore.ACCESS_TOKEN}`
              }
            });
  
            const deleteIncidentTagsSuccess = deleteIncidentTagsPromise.data && deleteIncidentTagsPromise.data.status === "success"
  
            if (deleteIncidentTagsSuccess) {
              incidentTags = deleteIncidentTagsPromise.data.result.filter((updatedTag) =>
                updatedTag.id.startsWith("TAG-TNHP-1-INC")
              );
            } else {
              this.props.handleError("編集中にエラーが発生しました。");
            }
          }

          // add incidents 
          if (addIncidentIds.length > 0) {
            const addIncidentTagsPromise = await addTagsById(updatedPlace.id, addIncidentIds);
            incidentTags = addIncidentTagsPromise.filter(tag => tag.id.includes("TAG-TNHP-1-INC-"));
          }

          // if none have been added or removed, set the incident tags to the tags already attached to the place
          if (addIncidentIds.length === 0 && removeIncidentsIds.length === 0) {
            incidentTags = selectedPlace.incidentTags;
          }
        } else {
          incidentTags = selectedPlace.incidentTags;
        }

        const previousHazardLevel = this.getCategoryTagName(updatedPlace);

        if (
          previousHazardLevel !== this.state.hazardLevel &&
          this.state.hazardLevel
        ) {
          await geocore.places.tags.del(updatedPlace.id, [previousHazardLevel]);

          const tags = await this.geocoreTagUpdate(updatedPlace.id);

          const updatedHazardLevel = tags.find((tag) => {
            return tag.id.startsWith("TAG-TNHP-1-SEV");
          });

          this.props.updatePlace(
            updatedPlace,
            incidentTags,
            updatedHazardLevel
          );
          this.props.handleClose();
        } else {
          this.props.updatePlace(updatedPlace, incidentTags);
          this.props.handleClose();
        }
      }
    } catch (e) {
      this.props.handleError("編集中にエラーが発生しました。");
    }
  };

  geocoreUploadPhoto = (placeId, file, filename) => {
    this.setState({ uploading: true });
    geocore.objects.bins
      .upload(placeId, "photo.1", file, filename)
      .then((res) => {
        this.props.updatePhoto(this.props.selectedPlace, res.url);
        this.setState({ uploading: false, uploaded: true });
      });
  };

  onDrop = (acceptedFile, rejectedFile) => {
    if (acceptedFile) {
      this.geocoreUploadPhoto(
        this.props.selectedPlace.placeId,
        acceptedFile[0],
        acceptedFile[0].name
      );
    }
  };

  isCategoryPlaceId = (id) => {
    var prefix = "PLA-TNHP-1-GRO";
    return id && id.substring(0, prefix.length) === prefix;
  };

  getCategoryTagName = (place) => {
    for (var i = 0; i < place.tags.length; i++) {
      var tag = place.tags[i];
      if (tag.id.startsWith("TAG-TNHP-1-SEV")) {
        return tag.name;
      }
    }
  };

  getIncidentTagNames = (tags, incidentIds) => {
    const incidentTagNames = [];

    tags.forEach((tag) => {
      if (incidentIds.includes(tag.id)) {
        incidentTagNames.push(tag.name);
      }
    });

    return incidentTagNames;
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  setSelectedColor = (color) => this.setState({ selectedColor: color });

  createInitialMarker = () => {
    if (this.state.map) {
      const map = this.state.map;
      const lat = this.props.selectedPlace.geo.latitude;
      const lng = this.props.selectedPlace.geo.longitude;

      const marker = new window.google.maps.Marker({
        position: {
          lat,
          lng,
        },
        map,
        animation: window.google.maps.Animation.DROP,
        draggable: true,
      });

      this.setState({ marker });

      marker.addListener("dragend", () => {
        this.setState({ marker });
      });

      let input = document.getElementById("pac-marker-coords-container");	
      map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(input);
    }
  };

  setHazardLevel = (hazardLevel) => {
    this.setState({ hazardLevel });
  };

  handleTaisakuCheckbox = () => {
    this.setState((prevState) => ({
      checkedTaisaku: !prevState.checkedTaisaku,
    }));
  };

  handleIncidentCheckbox = incidentId => {
    this.setState(prevState => ({
      checkedIncidents: {
        ...prevState.checkedIncidents,
        [incidentId]: !prevState.checkedIncidents[incidentId]
      }
    }));
  };

  truncate = (input, length) => {
    if (input.length > length) return input.substring(0, length) + "...";
    else return input;
  };

  toggleViewMoreIncidents = () => {
    this.setState(prevState => ({
      viewMoreIncidents: !prevState.viewMoreIncidents
    }));
  };

  parseLevels = () => {
    var { customData } = this.props.parentGroup;
    const parsedLevels = [];

    this.props.levels.forEach(level => {
      let levelName = level.pk.tag.name;
      let hazardName = level.customData && level.customData.name? level.customData.name : levelName;
      let customName = customData && customData[levelName]? customData[levelName] : hazardName;
      parsedLevels.push({ 
        name: customName, 
        levelName,
        id: level.pk.tag.id
      });
    });

    this.setState({ parsedLevels });
  };

  render() {
    const { classes } = this.props;

    const sortedIncidentTags = [...this.props.incidentTags].sort((firstTag, secondTag) =>
      this.props.selectedPlace.incidentTags.some(placeIncidentTag => placeIncidentTag.id === secondTag.id)? 1: -1
    );
    const { t } = this.props;

    return (
      <React.Fragment>
        <EditPlaceListDialogNavbar
          handleClose={this.props.handleClose}
          selectedPlace={this.props.selectedPlace}
        />
        <div className="edit-place-form-container">
          <form>
            <div className="edit-place-upper-container">
              <div className="form-upper">
                <div className="form-left">
                  <div className="delete-row">
                    <Typography variant="h6">{t("Edit Info")}</Typography>
                    <Typography	
                      className="delete-place-btn large-fill"	
                      onClick={() =>	
                        this.props.handleEditPlaceDeleteOpen(	
                          this.props.selectedPlace.placeId	
                        )	
                      }	
                    >	
                      {t("Delete")}
                    </Typography>
                    <Typography	
                      className="update-place-btn large-fill"
                      onClick={() => this.editPlace()}	
                    >	
                      {t("Update")}
                    </Typography>
                  </div>
                  <div className="edit-place-color-picker-row">
                    <div className="edit-place-color-picker">
                      <div>
                        <Typography className="color-picker-text">{t("Tag colors")}</Typography>
                      </div>
                      <div>
                        <EditPlaceListRadioButtons
                          setHazardLevel={this.setHazardLevel}
                          hazardLevel={this.props.selectedPlace.hazardLevel.name}
                          parsedLevels={this.state.parsedLevels}
                        />
                      </div>
                    </div>
                    <div className="edit-taisaku-checkbox">
                      <Typography>{t("Resolved")}</Typography>
                        <div className={`checkbox-pos center-check-${this.state.checkedTaisaku} ${this.props.selectedPlace.taisaku === "true"? 'is-taisaku': 'no-taisaku'}`}>
                          <FormControlLabel
                            control={                      
                              <CustomCheckbox
                                checked={this.state.checkedTaisaku}
                                onClick={this.handleTaisakuCheckbox}
                              />
                            }
                            className={classes.taisakuLabelContainer}
                          />
                        </div>
                      <Typography className={classes.taisakuLabel}>
                          {this.state.checkedTaisaku &&
                          this.props.selectedPlace.taisaku !== "true" ?
                          t("Move to resolved folder"):
                          !this.state.checkedTaisaku &&
                          this.props.selectedPlace.taisaku === "true" ?
                          t("Move out of resolved folder"):
                          null}
                      </Typography>
                    </div>
                    {this.props.incidentTags.length > 0 ? (
                    <div className={`incident-checkbox-container ${this.state.viewMoreIncidents? "view-more-incidents": ""}`}>
                      <Typography>{t("Incident")}</Typography>
                      {this.state.viewMoreIncidents?
                      sortedIncidentTags.map(
                        (incidentTag) => (
                          <ListItem
                            dense
                            className="incident-edit-list-item"
                            key={`incident-${incidentTag.id}`}
                          >
                            <CustomCheckbox
                              checked={
                                this.state.checkedIncidents[incidentTag.id]? true: false
                              }
                              onClick={() =>
                                this.handleIncidentCheckbox(incidentTag.id)
                              }
                            />
                            <div className={`color-category-label ${incidentTag.customData.color}`} />
                            <ListItemText
                              className="incident-edit-list-item-text"
                              primary={incidentTag.name}
                            />
                          </ListItem>
                        )
                      ): 
                        <>
                        {sortedIncidentTags.slice(0, 4).map(
                          (incidentTag) => (
                            <ListItem
                              dense
                              className="incident-edit-list-item"
                              key={`incident-${incidentTag.id}`}
                            >
                              <CustomCheckbox
                                checked={
                                  this.state.checkedIncidents[incidentTag.id]? true: false
                                }
                                onClick={() =>
                                  this.handleIncidentCheckbox(incidentTag.id)
                                }
                              />
                              <div className={`color-category-label ${incidentTag.customData.color}`} />
                              <ListItemText
                                className="incident-edit-list-item-text"
                                primary={incidentTag.name}
                              />
                            </ListItem>
                          )
                        )}
                        {this.props.incidentTags.length > 4?
                          <Typography className="view-more-incidents-btn" onClick={this.toggleViewMoreIncidents}>{t("View More")}</Typography>
                        :null}
                        </>
                      }
                    </div>
                  ) : null}
                  {this.props.selectedPlace.isLiveStream ||
                    this.props.selectedPlace.is_webrtc ? null : (
                      <div className="make-public-button-container">
                        {this.props.selectedPlace.isPublic ? (
                          <div className="public-row">
                            <div className="public-btn-side-text">
                              <Typography>
                              {t("Remove from Public Listing")}
                              </Typography>
                            </div>
                            <Button
                              variant="contained"
                              className={classes.publicBtn}
                              onClick={this.props.handlePublicOpen}
                            >
                              {t("Make Private")}
                            </Button>
                          </div>
                        ) : (
                          <div className="public-row">
                            <div className="public-btn-side-text">
                              <Typography>
                              {t("Share to Public Listing")}
                              </Typography>
                            </div>
                            <Button
                              variant="contained"
                              className={classes.publicBtn}
                              onClick={this.props.handlePublicOpen}
                            >
                              {t("Make Public")}
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {this.props.selectedPlace.isLiveStream ? (
                    <FormControl margin="normal" disabled fullWidth>
                      <TextField
                        defaultValue={this.props.selectedPlace.title}
                        id="title"
                        name="title"
                        label={t("Title")}
                        autoComplete="off"
                        onChange={this.handleChange}
                        variant="outlined"
                      />
                    </FormControl>
                  ) : (
                    <div></div>
                  )}
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      multiline
                      defaultValue={this.props.selectedPlace.comment}
                      rows="1"
                      rowsMax="4"
                      id="comment"
                      name="comment"
                      label={t("Comment")}
                      autoComplete="off"
                      onChange={this.handleChange}
                      variant="outlined"
                    />
                  </FormControl>
                  <div className="edit-place-map-container">
                    <EditPlaceMap
                      id="edit-place-map"
                      options={{
                        center: {
                          lat: this.props.selectedPlace.geo.latitude,
                          lng: this.props.selectedPlace.geo.longitude,
                        },
                        zoom: this.props.user.customData && this.props.user.customData.initialZoom? parseInt(this.props.user.customData.initialZoom): 6,
                        maxZoom: 30,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: true
                      }}
                      onMapLoad={(map) => {
                        this.initAutocomplete(map);
                        this.setState({ map });
                      }}
                    />
                  </div>
                </div>
                <div className="edit-photo">
                  {this.state.uploaded ||
                  (this.props.selectedPlace.images &&
                    this.props.selectedPlace.images.length > 0) ? (
                    <React.Fragment>
                      {this.props.selectedPlace.isLiveStream ? (
                        <div></div>
                      ) : (
                        <img
                          src={this.props.selectedPlace.images.filter((im) => !im.includes("low"))[0]}
                          alt="User Place"
                        />
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {this.props.selectedPlace.isLiveStream ? (
                        <React.Fragment></React.Fragment>
                      ) : (
                        <HazardPhotoUploader
                          uploadPhoto={this.geocoreUploadPhoto}
                          onDrop={this.onDrop}
                          uploading={this.state.uploading}
                          acceptedFile={this.state.acceptedFile}
                        />
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </form>
          <AddPublicPlaceDialog
            open={this.props.isPublicOpen}
            close={this.props.handlePublicOpen}
            handlePublicPlace={this.props.handlePublicPlace}
            selectedPlace={this.props.selectedPlace}
            loadingPublicPlace={this.props.loadingPublicPlace}
          />
          <DeletePlaceDialog
            open={this.props.editPlaceDeleteOpen}
            close={this.props.handleEditPlaceDeleteOpen}
            handleMarkAsDeleted={this.props.handleMarkAsDeleted}
            selectedPlace={this.props.selectedPlace}
            loadingDelete={this.props.loadingDelete}
          />
          <div>
            <input id="pac-input-edit-place" className="controls-edit-place" type="text"></input>
          </div>
          {this.state.marker?
            <div className="pac-marker-coords-container"  id="pac-marker-coords-container">
                <p className="marker-coords">{`${this.state.marker.position.lat()}, ${this.state.marker.position.lng()}`}</p>
            </div>
          : null}
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(withStyles(styles)(FilterDialogForm));
