import React from "react";
import Loader from "react-loader-spinner";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from 'react-i18next';

const DeleteNotificationDialog = ({
  open,
  close,
  handleDeleteNotification,
  selectedNotification,
  loadingDelete,
}) => {
  const {t} = useTranslation();
  return (
    <Dialog open={open? open: false} onClose={close} className="delete-place-modal">
      <div className="header">
        <Typography variant="h6">{`「${selectedNotification && selectedNotification.msg_title ? selectedNotification.msg_title : null} ${t("will be deleted")}`}</Typography>
      </div>
      <Divider />
      <div className="body">
        <Typography>{t("Are you sure you want to delete this?")}</Typography>
      </div>
      <Divider />
      <div className="footer">
        <div style={{ marginRight: "10px" }}>
          <Button
            variant="contained"
            style={{ backgroundColor: "#0D6AAD", color: "white" }}
            onClick={close}
          >
            <CancelIcon />
            {t("Cancel")}
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDeleteNotification(selectedNotification)}
          >
            <DeleteIcon />
            {t("Delete")}
            {loadingDelete ? (
              <div>
                <Loader type="TailSpin" color="white" height={15} width={15} />
              </div>
            ) : null}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteNotificationDialog;
