import React from "react";

import Snackbar from "@material-ui/core/Snackbar";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

import "../styles/alerts.scss";

const AlertSnackbar = ({ isType, isOpen, handleClick, message }) => {
  const icon =
    isType === "error" || isType === "connection-error" || isType === "warning" ? (
      <ErrorOutline key="error" className={isType + "-icon-snackbar"} />
    ) : (
      <CheckCircleOutline key="success" className={isType + "-icon-snackbar"} />
    );

  return (
    <Snackbar
      className={isType + "-snackbar-alert-wrap snackbar-main-wrapper"}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      open={isOpen}
      onClose={handleClick}
      autoHideDuration={3000}	
      ContentProps={{
        "aria-describedby": "alert-message-content"
      }}
      message={
        <span id="alert-message-content" className={isType + "-alert-message"}>
          {message}
        </span>
      }
      action={[icon]}
    />
  );
};

export default AlertSnackbar;
