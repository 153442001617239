import React from "react";
import firebase from "firebase/app";
import "firebase/database";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Badge from '@material-ui/core/Badge';

import CloseIcon from "@material-ui/icons/Close";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

import AlertSnackbar from "./AlertSnackbar";

import { ReactComponent as HazardLiveLogo } from "../images/HazardLiveLogo.svg";
import { ReactComponent as RealtimeIcon } from "../images/realtime-location-icon.svg";

import "../styles/hazardlive-realtime-map.scss";
import RealtimeMap from "./RealtimeMap";

class HazardLiveRealtimeMapBuilder extends React.PureComponent {
  state = {
    open: false,
    openBackgroundBar: false,
    hasError: false,
    isType: "error",
    errorMessage: "",
    map: null,
    markers: [],
    realtimeCoordinates: [],
    realtimePaths: [],
    roomRefs: [],
    fallbackLat: 35.558988,
    fallbackLng: 139.942829,
    bounds: new window.google.maps.LatLngBounds()
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.open && this.state.map && !prevState.map) {
      // this.props.buildTestWebRTCLiveList();
      this.getFirebaseCoords();
    }
    
    if (this.state.open && prevProps.webRTCLiveList.length !== this.props.webRTCLiveList.length) {
      this.removeExpiredLiveStreams(prevProps.webRTCLiveList);
      this.getFirebaseCoords();
    }

    if (this.state.open && prevProps.hoveredLivePlace !== this.props.hoveredLivePlace) {
      this.toggleMarkerBounce();
    }

    if (this.state.open && prevState.realtimeCoordinates.length === 0 && this.state.realtimeCoordinates.length) {
      console.log("realtimeCoords", this.state.realtimeCoordinates);

      this.state.realtimeCoordinates.forEach((coord, coordIndex) => {
        this.state.bounds.extend({ lat: coord.lat, lng: coord.lng })

        if (coordIndex === this.state.realtimeCoordinates.length - 1) {
          this.state.map.fitBounds(this.state.bounds);
        }
      });
    }
  };

  getFirebaseCoords = async () => {
    const { webRTCLiveList } = this.props;

    if (webRTCLiveList && webRTCLiveList.length > 0) {
      const roomRefs = [];
      
      webRTCLiveList.forEach((liveRoom, liveListIndex) => {
        const realtimeRef = firebase.database().ref(`realtime/${liveRoom.producer}/${liveRoom.roomId}`);

        realtimeRef.on("value", (snapshot) => {
          if (snapshot.val()) {
            // Stream data available
            this.updateRealtimeMapLive(liveRoom, snapshot.val());
          } else {
            // User possibly has location off
          }
  
        }, (errorObject) => {
          console.log("The read failed: " + errorObject.code);
        });

        roomRefs.push(realtimeRef);
      });

      this.setState({
        roomRefs
      });
    }
  };

  updateRealtimeMapLive = (liveRoom, realtimeData) => {    
    const roomId = liveRoom.roomId;

    const updatedCoords = Object.values(realtimeData).sort((coordA, coordB) => coordA.duration - coordB.duration);
    const updatedPaths = [];
    
    updatedCoords.forEach((updatedCoord, coordIndex) => {
      const currentCoord = updatedCoords[coordIndex];
      const prevCoord = updatedCoords[coordIndex - 1];
      const secondsLimit = 120;

      if (this.state.realtimeCoordinates.some(realtimeCoord => realtimeCoord.id === updatedCoord.id)) {
        // already added
        console.log("Already Added");
      } else {
        // needs added
        if (prevCoord) {
          console.log("Needs Added");
          const secondsDiff = currentCoord.duration - prevCoord.duration;
    
          if (secondsDiff > secondsLimit) {
            const dottedLineSymbol = {
              path: "M 0,1 0,1",
              strokeOpacity: 1,
              scale: 4,
            };
    
            const dottedPath = new window.google.maps.Polyline({
              path: [
                {
                  lat: prevCoord.lat,
                  lng: prevCoord.lng
                },
                { 
                  lat: currentCoord.lat,
                  lng: currentCoord.lng
                },
              ],
              strokeOpacity: 0,
              strokeColor: "#43dcd7",
              icons: [
                {
                  icon: dottedLineSymbol,
                  offset: "0",
                  repeat: "20px",
                },
              ],
              map: this.state.map,
              roomId
            });
    
            updatedPaths.push(dottedPath);
          } else {
            // Last coord in test data
            const realtimePath = new window.google.maps.Polyline({
              path: [
                {
                  lat: prevCoord.lat,
                  lng: prevCoord.lng
                },
                { 
                  lat: currentCoord.lat,
                  lng: currentCoord.lng
                },
              ],
              geodesic: true,
              strokeColor: "#43dcd7",
              strokeOpacity: 1.0,
              strokeWeight: 5,
              map: this.state.map,
              roomId
            });
    
            updatedPaths.push(realtimePath);
          }
        }
      }

      if (coordIndex === updatedCoords.length - 1) {
        
        if (this.state.markers.some(marker => marker.roomId === roomId)) {
          this.setState(prevState => ({
            realtimeCoordinates: updatedCoords,
            realtimePaths: [
              ...prevState.realtimePaths,
              ...updatedPaths,
            ]
          }));

          this.updateMarkerBounds(currentCoord.lat, currentCoord.lng, roomId);
        } else {
          // Initial marker and map position has not been set yet
          const RealtimeIcon = {
            anchor: new window.google.maps.Point(16, 24),
            url: require("../images/realtime-location-icon.svg"),
            scaledSize: new window.google.maps.Size(32, 32)
          };
    
          // most recent from firebase will be the first coord in the array
          const lat = currentCoord.lat;
          const lng = currentCoord.lng;
    
          const updatedMarker = new window.google.maps.Marker({
            position: {
              lat,
              lng 
            },
            icon: RealtimeIcon,
            map: this.state.map,
            roomId,
            visible: true
          });
          
          const content = `
            <div id="iw-${roomId}" class="place-list-marker-infowindow animated">
              <div>
                <div>
                  <h4>${liveRoom.username}</h4>
                </div>
              </div>
            </div>
          `;
  
          const infoWindow = new window.google.maps.InfoWindow({
            content,
            disableAutoPan: true
          });
    
          infoWindow.open(this.state.map, updatedMarker);
          
          this.setState(prevState => ({
            realtimeCoordinates: updatedCoords,
            realtimePaths: [
              ...prevState.realtimePaths,
              ...updatedPaths,
            ],
            markers: [
              ...prevState.markers,
              updatedMarker
            ]
          }), () => {
            this.updateMarkerBounds(lat, lng, roomId);
          });
        }
      }
    }); 
  };

  updateMarkerBounds = (lat, lng, roomId) => {
    const { map, markers, fallbackLat, fallbackLng } = this.state;
    const updatedMarker = markers.find(marker => marker.roomId === roomId);

    if (map) {
      if (lat === fallbackLat && lng === fallbackLng) {
        // User has denied location tracking
        updatedMarker.setMap(null);
      } else {
        updatedMarker.setPosition({ lat, lng });
        updatedMarker.setMap(map);
      }
    }
  };

  removeExpiredLiveStreams = prevWebRTCLiveList => {
    if (prevWebRTCLiveList.length) {
      prevWebRTCLiveList.forEach(prevRoomData => {
        const prevRoomId = prevRoomData.roomId;
  
        if (this.props.webRTCLiveList.some(roomData => roomData.roomId === prevRoomId)) {
          // Not expired
          console.log("Current Room ID: ", prevRoomId);
        } else {
          // Expired, needs removed
          console.log("Removed Room ID: ", prevRoomId);

          this.state.realtimePaths.forEach(realtimePath => {
            if (realtimePath.roomId === prevRoomId) {
              realtimePath.setMap(null);
            }
          });

          const prevMarker = this.state.markers.find(marker => marker.roomId === prevRoomId);

          if (prevMarker) {
            prevMarker.setMap(null);
          }
        }
      });
    }
  };

  handleClick = (e) => {
    e.preventDefault();
    clearTimeout(this.timer);
    this.props.handleRealtimeMapBuilderOpen();
    this.props.handleOpenRealtimeMapSidebar();
    this.setState((prevState) => ({
      open: !prevState.open,
      openBackgroundBar: !prevState.openBackgroundBar,
      hasError: false,
      isType: "error",
      errorMessage: "",
      map: null,
      markers: [],
      realtimeCoordinates: [],
      realtimePaths: [],
      roomRefs: []
    }));

    if (this.state.roomRefs.length > 0) {
      this.state.roomRefs.forEach(roomRef => {
        roomRef.off();
      });
    }
  };

  handleErrorAlertClick = () => {
    //hack!!!
    setTimeout(() => {
      this.setState({ hasError: false });
    }, 3000);
  };

  toggleMarkerBounce = () => {
    const { hoveredLivePlace } = this.props;

    if (hoveredLivePlace) {
      const hoveredRoomId = hoveredLivePlace.place.roomId;
      const shouldBounce = hoveredLivePlace.shouldBounce;
  
      const hoveredMarker = this.state.markers.find(marker => marker.roomId === hoveredRoomId);

      if (hoveredMarker) {
        hoveredMarker.setAnimation(shouldBounce? window.google.maps.Animation.BOUNCE: null);
        
        const infoWindowDiv = document.getElementById(`iw-${hoveredMarker.roomId}`);
        
        if (infoWindowDiv) {
          const infoWindowContainer = this.getInfoWindowContainer(infoWindowDiv, "gm-style-iw-a");
    
          if (shouldBounce) {
            infoWindowContainer.classList.add("animated-bubble", "bounce-bubble");
          } else {
            infoWindowContainer.classList.remove("animated-bubble", "bounce-bubble");
          }
        }
      }
    }
  };

  getInfoWindowContainer = (infoWindow, className) => {
    while (infoWindow && infoWindow.parentNode) {
      infoWindow = infoWindow.parentNode;
      if (infoWindow.className.includes(className)) {
        return infoWindow;
      }
    }

    return null;
  };

  render() {
    const {
      user,
      openRealtimeMapSidebar,
      handleToggleRealtimeMapSidebar,
      screenBuilderOpen,
    } = this.props;
    const { open, isType, hasError, errorMessage, openBackgroundBar } = this.state;

    return (
      <div
        className={
          open && !openRealtimeMapSidebar? "realtime-map-builder realtime-map opened-builder expand": 
          open? "realtime-map-builder realtime-map opened-builder": 
          "realtime-map-builder realtime-map"
        }
      >
        <div className="portal-wrap">
          <AlertSnackbar
            isType={isType}
            isOpen={hasError}
            handleClick={this.handleErrorAlertClick}
            message={errorMessage}
          />
        </div>
        <div className="map-mode-navbar">
          <div className="logo">
            <HazardLiveLogo />
          </div>
          <Button
            className="close-map"
            onClick={(e) => {
              this.handleClick(e);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
        {open ? (
          <div className="realtime-map-container" ref={this.mapRef}>
            <RealtimeMap
              id="realtime-map"
              options={{
                center: {
                  lat:
                    user && user.customData && user.customData.initialLat
                      ? parseFloat(user.customData.initialLat)
                      : 35.558988,
                  lng:
                    user && user.customData && user.customData.initialLng
                      ? parseFloat(user.customData.initialLng)
                      : 139.942829,
                },
                zoom:
                  user && user.customData && user.customData.initialZoom
                    ? parseInt(user.customData.initialZoom)
                    : 6,
                maxZoom: 30,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
              }}
              onMapLoad={(map) => {
                this.setState({ map });
              }}
            />
          </div>
        ) : null}
        <div className={`${openBackgroundBar ? "livebar opened" : "livebar"}`}>
          <div className="livebar-hook">
            <Typography variant="h5">
              右のリストからリアルタイムの場所を見ることができます。
            </Typography>
          </div>
          <div className="live-screen-side-bar-actions">
            <Button
              onClick={() => handleToggleRealtimeMapSidebar()}
              className={`${
                openRealtimeMapSidebar
                  ? "sidebar-toggle-btn opened"
                  : "sidebar-toggle-btn"
              }`}
            >
              <ChevronLeft />
            </Button>
          </div>
        </div>
        {this.props.activeRealtimeLocationStreamCount > 0 || this.state.open?
        <Button
          className={`map-dropdown-btn ${openBackgroundBar ? "opened" : ""} ${
            screenBuilderOpen ? "hidden" : "visible"
          }`}
          onClick={(e) => this.handleClick(e)}
        >
          <Badge color="secondary" style={{ marginRight: "16px" }} badgeContent={this.props.activeRealtimeLocationStreamCount}></Badge>
          リアルタイム
        </Button>: null}
      </div>
    );
  }
}

export default HazardLiveRealtimeMapBuilder;
