import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';

import CloseIcon from "@material-ui/icons/Close";
const isLiveApp = process.env.REACT_APP_TYPE === "LIVE" && process.env.REACT_APP_TYPE !== "VIEW"? true: false;

const ModalFacilityDetails = props => {
  const { facility, open, handleClose} = props;
  const {t} = useTranslation();
  return (
    <Dialog 
      open={facility? open : false} 
      onClose={handleClose} 
      className={isLiveApp? "last-location-modal dark-mode": "last-location-modal"}
    >
      <Button onClick={handleClose} className="close-modal-btn">
        <CloseIcon />
      </Button>
      <Typography 
        variant="h4" 
        style={{ color: "#0E6AAD", marginBottom: "10px" }}
      >
        {facility ? facility.groupName : null}
      </Typography>
      <Divider />
      <Divider style={{ marginBottom: "15px" }}/>
      <Typography style={{ fontWeight: "bold", marginBottom: "5px" }}>
        {t("Facility Name")}
      </Typography>
      <Typography style={{marginBottom: "5px"}}>
        {facility ? facility.name : null}
      </Typography>     
      <Typography style={{ fontWeight: "bold", marginBottom: "5px" }}>
        {t("Description")}
      </Typography>
      <Typography style={{ marginBottom: "5px" }}>
        {facility ? facility.description : null}
      </Typography>
      {facility && facility.images && facility.images.length > 0?
        <div>
          <img src={facility.images[0]} style={{ maxHeight: "410px", maxWidth: "410px" }} alt="Facility" />
        </div> : null
      }
    </Dialog>
  );
};

export default ModalFacilityDetails;
