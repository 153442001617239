import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";

import CircularProgress from "@material-ui/core/CircularProgress"
import { useTranslation } from 'react-i18next';

const HazardLiveSnapshot = ({ open, close, download, createPlace, createPlaceLoading }) => {
  const { t } = useTranslation();

  return ( 
    <Dialog
      open={open}
      onClose={close}
      className="live-snapshot-modal"
    >
      <Typography variant="h6" className="live-snapshot-header">
        {t("Do you want to download a snapshot and upload a snapshot of the new location?")}
      </Typography>
      <div className="live-snapshot-buttons">
        <Button
          variant="contained"
          color="primary"
          className="close-button"
          onClick={close}
        >
          {t("Back")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="confirm-button"
          onClick={download}
        >
          {t("Download")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="confirm-button"
          onClick={createPlace}
        >
          {t("Upload")}
          {createPlaceLoading?
            <CircularProgress 
              size={20} 
              thickness={6} 
              className='snapshot-upload-spinner' 
              color="secondary"
            />: null
          }
        </Button>
      </div>
    </Dialog>
  );
};

export default HazardLiveSnapshot;
