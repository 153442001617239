import React from "react";

import Dialog from "@material-ui/core/Dialog";
import Loader from "react-loader-spinner";

// eslint-disable-next-line
var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
var safariOnIos = isSafari && iOS;

export default class HazardCamDialog extends React.PureComponent {

  state = {
    loading: true,
    isVideo: true,
    isMJPEG: false,
    primaryPeerConnection: null,
    isVideoAttached: false,
    stateId: 0,
    config: {
      iceServers: [{
        'urls': 'stun:stun.l.google.com:19302'
        },
        {
        'urls': 'turn:52.199.180.183:3478',
        'credential': 'geocore',
        'username': 'mapmotion'
      }]
    }
  };

  componentDidMount() {
    if (this.state.primaryPeerConnection !== null) {
      this.state.primaryPeerConnection.close();
    }
    if(this.props.camera) {
      this.setCamera(this.hazardCamVideoElm, this.props.camera);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.camera && this.props.camera !== prevProps.camera) {
      this.setCamera(this.hazardCamVideoElm, this.props.camera);
    }
  }
  
  componentWillUnmount() {
    if (this.state.primaryPeerConnection !== null) {
      this.state.primaryPeerConnection.close();
    }
  }


  setCamera = (videoElm, camera) => {
    if(camera === "LivelyButterfly") {
      if (window.navigator.userAgent.indexOf("Edge") > -1  || safariOnIos) {
        //state 3 means the client is a Microsoft Edge or Safari on iOS
        this.setState({
          stateId: 3,
          cameraId: "4aec087d84652b5bc880a66acf349a56",
        });
        this.startMJPEG();
      } else {
        this.setState({
          cameraId: "4aec087d84652b5bc880a66acf349a56",
          primaryPeerConnection: this.createNewPeerConnection()
        });
      }
    } else if(camera === "PolishedShape"){
      if (window.navigator.userAgent.indexOf("Edge") > -1  || safariOnIos) {
        //state 3 means the client is a Microsoft Edge or Safari on iOS
        this.setState({
          stateId: 3,
          cameraId: "a54846c2d4910dcbc70cedafe638991e",
        });
        this.startMJPEG();
      } else {
        this.setState({
          cameraId: "a54846c2d4910dcbc70cedafe638991e",
          primaryPeerConnection: this.createNewPeerConnection()
        });
      }
    } else if(camera === "WispyRain"){
      if (window.navigator.userAgent.indexOf("Edge") > -1  || safariOnIos) {
        //state 3 means the client is a Microsoft Edge or Safari on iOS
        this.setState({
          stateId: 3,
          cameraId: "a7ff5b84ee8e35c9651f7f8eaef233f6",
        });
        this.startMJPEG();
      } else {
        this.setState({
          cameraId: "a7ff5b84ee8e35c9651f7f8eaef233f6",
          primaryPeerConnection: this.createNewPeerConnection()
        });
      }

    } else if(camera === "PatientFrost") {
      if (window.navigator.userAgent.indexOf("Edge") > -1  || safariOnIos) {
        //state 3 means the client is a Microsoft Edge or Safari on iOS
        this.setState({
          stateId: 3,
          cameraId: "49c7a29290bc5bc2cdeb29926a49c87a",
        });
        this.startMJPEG();
      } else {
        this.setState({
          cameraId: "49c7a29290bc5bc2cdeb29926a49c87a",
          primaryPeerConnection: this.createNewPeerConnection()
        });
      }
   
    } else if(camera === "YoungShadow"){
       if (window.navigator.userAgent.indexOf("Edge") > -1  || safariOnIos) {
        //state 3 means the client is a Microsoft Edge or Safari on iOS
        this.setState({
          stateId: 3,
          cameraId: "4d9156279ee5d830316c0a75ab591eeb",
        });
        this.startMJPEG();
      } else {
        this.setState({
          cameraId: "4d9156279ee5d830316c0a75ab591eeb",
          primaryPeerConnection: this.createNewPeerConnection()
        });
      }
    } else {
      // error camera not found
    }
  };

  createNewPeerConnection = () => {
    this.pc = new RTCPeerConnection(this.state.config);
    this.setState({isVideoAttached: false})
    new Promise((resolve, reject) => {
      const mainIceListener = () => {
        console.warn(this.pc.iceConnectionState);
        if(this.peerConnectionBad(this.pc)){
          if(this.state.stateId === 0) {
            //this means webrtc connection is not possible
            this.startMJPEG();
          }
          if(this.state.stateId !== 2) {
            this.showContainer('fail');
          }
        }
        if(this.peerConnectionGood(this.pc)) {
          this.setState({
            isVideo: true,
          });
          if (!this.state.isVideoAttached) {
            if (this.state.stateId === 0) {
              this.setState({
                stateId: 1
              })
            }
            this.setState({isVideoAttached: true, loading: false})
            this.attachStreamToVideoElement(this.pc, this.hazardCamVideoElm);
            //this.cleanup();
            //this.startVideoFreezeDetection(this.pc);
          }
          this.showContainer('video');
        }
      }
      this.pc.addEventListener('iceconnectionstatechange', mainIceListener);
      resolve();
    }).then(() => {
      this.pc.addTransceiver('video', {direction: 'recvonly'});
      return this.pc.createOffer()
    }).then((offer) => {
      return this.pc.setLocalDescription(offer);
    }).then(() => {
      // wait for ICE gathering to complete
      return new Promise((resolve) => {
        if (this.pc.iceGatheringState === 'complete') {
          resolve();
        } else {
          const checkState = () => {
            if (this.pc.iceGatheringState === 'complete') {
              this.pc.removeEventListener('icegatheringstatechange', checkState);
              resolve();
            }
          }
          this.pc.addEventListener('icegatheringstatechange', checkState);
        }
      });
    }).then(() => {
      var offer = this.pc.localDescription;
      console.log('Offer SDP');
      console.log(offer.sdp);
      return fetch(`https://${this.state.cameraId}.balena-devices.com/offer`, {
        body: JSON.stringify({
          sdp: offer.sdp,
          type: offer.type,
        }),
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST'
      });
    }).then((response) => {
      return response.json();
    }).then((answer) => {
      console.log('Answer SDP');
      console.log(answer.sdp);
      return this.pc.setRemoteDescription(answer);
    }).catch((e) => {
      console.error(e);
      console.log('Unexpected Error: Starting MJPEG stream.')
      this.startMJPEG();
    });
    return this.pc
  };


  attachStreamToVideoElement = (pc, videoElem) =>{
    console.log('Attaching stream...');
    var srcStream = new MediaStream();
    srcStream.addTrack(pc.getReceivers()[0].track);
    videoElem.srcObject = srcStream;
  };

  peerConnectionGood = (pc) => {
    return ((this.pc.iceConnectionState === 'connected' || this.pc.iceConnectionState === 'completed'));
  };

  peerConnectionBad = (pc) => {
    return ((this.pc.iceConnectionState === 'disconnected' || this.pc.iceConnectionState === 'failed' || this.pc.iceConnectionState === 'closed'));
  };

  showContainer = (kind) => {
    if (kind === 'video') {
      this.setState({
        isVideo: true,
        isMJPEG: false
      });
    } else if (kind === 'fail') {
      this.setState({
        isVideo: false,
        isMJPEG: false
      });
    } else if (kind === 'mjpeg') {
      this.setState({
        isVideo: false,
        isMJPEG: true
      });
    } else {
      console.error('No container that is kind of: ' + kind);
      this.setState({
        isVideo: false,
        isMJPEG: false
      });
    }
  };

  
  // Use on firefox
  getCurrentFrame = () => {
    var canvas = document.createElement("canvas");
    let canvasContext;

    if(this.state.isVideo) {
      canvas.width = this.hazardCamVideoElm.videoWidth;
      canvas.height = this.hazardCamVideoElm.videoHeight;
      canvasContext = canvas.getContext("2d");
      canvasContext.drawImage(this.hazardCamVideoElm, 0, 0);
      return canvas.toDataURL('image/png');
    } else if (this.state.isMJPEG) {
      canvas.width = this.hazardCamMjpeg.videoWidth;
      canvas.height = this.hazardCamMjpeg.videoHeight;
      canvasContext = canvas.getContext("2d");
      canvasContext.drawImage(this.hazardCamMjpeg, 0, 0);
      return canvas.toDataURL('image/png');
    } else {
      return false
    }
  };

  isVideoFrozen = (pc) => {
    var previousFrame;
    var ignoreFirst = true;
    this.vfdIntervalId = setInterval(() => {
      if (this.peerConnectionGood(pc) && this.hazardCamVideoElm.currentTime > 0 && this.getCurrentFrame() === previousFrame) {
        if (ignoreFirst) {
          ignoreFirst = false;
          return
        }
        console.warn("Video freeze detected using frames!!!");
        this.reconnect();
      } else {
        previousFrame = this.getCurrentFrame();
      }
    }, 3000);
  };

  // Use on Chrome
  checkVideoFreeze = (pc) => {
    var previousPlaybackTime;
    this.vfdIntervalId = setInterval(() => {
      if (this.peerConnectionGood(pc) && previousPlaybackTime === this.hazardCamVideoElm.currentTime && this.hazardCamVideoElm.currentTime !== 0) {
        console.warn("Video freeze detected!!!");
        this.reconnect();
      } else {
        previousPlaybackTime = this.hazardCamVideoElm.currentTime;
      }
    }, 3000);
  };

  startVideoFreezeDetection = (pc) => {
    this.stopVideoFreezeDetection();
    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      this.isVideoFrozen(pc);
    } else {
      this.checkVideoFreeze(pc);
    }
  };

  stopVideoFreezeDetection = () => {
    if (!this.vfdIntervalId || this.vfdIntervalId !== null) {
      console.log('Stopping Current Video Freeze Detector');
      clearInterval(this.vfdIntervalId);
    }
  };

  cleanup = () => {
    if (this.backupPeerConnection || this.backupPeerConnection !== null) {
      console.log('Cleaning Up...')
      var tmp = this.state.primaryPeerConnection;
      this.setState({
        primaryPeerConnection: this.backupPeerConnection
      })
      this.backupPeerConnection = tmp;
      this.backupPeerConnection.close();
      this.backupPeerConnection = null;
      this.cleanUpthisInterval = setInterval(() => {
        if (this.peerConnectionGood(this.state.primaryPeerConnection) && this.backupPeerConnection === null) {
          this.showContainer('video');
          clearInterval(this.cleanUpthisInterval);
        }
      }, 100);
    }
  };

  reconnect = () => {
    console.log('Reconnecting');
    this.backupPeerConnection = this.createNewPeerConnection();
  };

  startMJPEG = () => {
    if (this.state.stateId !== 3) {
      this.state.primaryPeerConnection.close();
      this.setState({
        primaryPeerConnection: null,
      })
    }
    console.warn('WebRTC does not work! Starting MJPEG streaming.');
    this.setState({
      stateId: 2,
    })


    var ctx = this.hazardCamMjpeg.getContext('2d');

    var mjpeg = new Image();
    mjpeg.src = `https://${this.state.cameraId}.balena-devices.com/mjpeg`;
    this.hazardCamMjpeg.appendChild(mjpeg);

    mjpeg.onload = () => {
      this.hazardCamMjpeg.style.width = mjpeg.width;
      this.hazardCamMjpeg.style.height = mjpeg.height;
      this.hazardCamMjpeg.width = mjpeg.width;
      this.hazardCamMjpeg.height = mjpeg.height;
      var draw = setInterval(() => {
        try {
          ctx.drawImage(mjpeg, 0, 0);
        } catch (error) {
          console.error(error);
          console.warn('Stopping canvas draw.');
          clearInterval(draw);
          this.showContainer('fail');
        }
      }, 50);
    }

    this.showContainer('mjpeg');
  };


  render() {
    
    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.props.closeHazardCamStream(this.props.camera)}
        className="place-modal hazardcam-dialog"
      > 
        <div className={"hazard-cam-stream-wrap"}>
          {this.state.loading?
            <Loader 
              type="TailSpin" 
              color="#0E6AAD" 
              height={15}
              width={15}
            />:null
          }
          <video
            muted
            className={`hazardcam-livestream viewable-${this.state.isVideo}`}
            autoPlay
            ref={hcam => this.hazardCamVideoElm = hcam}
          ></video>

          <canvas
            id="mjpeg"
            className={`hazardcam-livestream viewable-${this.state.isMJPEG}`}
            ref={mjpeg => (this.hazardCamMjpeg = mjpeg)}
          ></canvas>

        </div>
      </Dialog>
    );
  }
}
