import GeoJSON from "geojson";
import merge from "lodash/merge";
import tokml from "geojson-to-kml";

export const exportToKml = dataToExport => {
  const realtimeData = typeof dataToExport === "string"? JSON.parse(dataToExport): dataToExport;
  const flattenedRealtimeStorageData = realtimeData.map((location) => {
      if (location.position && location.position.coords) {
        // merge nested data with top level and delete left over nested data
        const parsedLocation = merge(location, location.position.coords);
        delete location.position;
        return parsedLocation;
      } else {
        return location;
      }
    }
  );

  const geoJsonData = GeoJSON.parse(flattenedRealtimeStorageData, { Point: ["lat", "lng"] });
  const kmlData = tokml(geoJsonData);

  return kmlData;
};

export const downloadKml = (realtimeStorageData) => {
  // get KML data
  const kmlData = exportToKml(realtimeStorageData);

  // create a url from a blob to download the KML file
  const blob = new Blob([kmlData]);
  const url = URL.createObjectURL(blob);

  // dom element to programatically download KML file
  const downloadLink = document.createElement("a");

  downloadLink.href = url;
  downloadLink.download = "hazardlive.kml";

  const clickHandler = () => {
    // timeout to remove the url and element from the dom after user has clicked
    setTimeout(() => {
      URL.revokeObjectURL(url);
      downloadLink.removeEventListener("click", clickHandler);
    }, 150);
  };

  downloadLink.addEventListener("click", clickHandler, false);
  downloadLink.click();
};
