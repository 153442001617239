import React from "react";
import axios from 'axios';

import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import DeleteIcon from "@material-ui/icons/Delete";
import SettingsIcon from "@material-ui/icons/Settings";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/Check";

import AddIcon from "@material-ui/icons/Add";

import Dialog from "@material-ui/core/Dialog";
import { withTranslation } from 'react-i18next';

import AddGroupMemberTable from "../components/AddGroupMemberTable";
const isLiveApp = process.env.REACT_APP_TYPE === "LIVE" && process.env.REACT_APP_TYPE !== "VIEW"? true: false;

const geocore = require("@mekasmith/geocore");

const styles = {
  root: {
    width: "100%",
    marginTop: "10px",
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  head: {
    backgroundColor: "#fff",
    position: "sticky",
    top: 0,
    zIndex: 1
  },
  headTitle: {
    paddingLeft: "20px",
    fontSize: "14px"
  },
};

class GroupMemberTable extends React.Component {
  state = {
    users: null,
    addMemberDialog: false,
    updateUserName: false,
    selectedUser: null,
    removeUser: false,
  };

  componentDidMount() {
    this.getSelectedGroupUsers();
  }

  handleAddMemberDialog = reload => {
    this.setState({ loading: true });
    if (reload) this.getSelectedGroupUsers();

    this.setState(prevState => ({
      addMemberDialog: !prevState.addMemberDialog
    }));
  };

  getSelectedGroupUsers = () => {
    const users = [];

    geocore.groups.users(this.props.selectedGroup.id).then(userObjList => {
      userObjList.forEach(userObj => {
        users.push(userObj.pk.user);
      });
      this.props.getGroups(true);
      this.setState({ users });
    });
  };

  removeGroupMember = () => {
    geocore.groups.manage(this.props.selectedGroup.id, null, [this.state.selectedUser.id])
      .then(res => {
        this.getSelectedGroupUsers();
        this.handleClose();
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.updateSelectedUsersName();
  }
  
  handleChange = e => this.setState({ [e.target.id]: e.target.value });
  
  handleOpen = selectedUser => {
    this.setState({
      updateUserName: true,
      selectedUser,
    });
  };

  handleOpenRemove = selectedUser => {
    this.setState({
      removeUser: true,
      selectedUser,
    });
  };

  handleClose = () => {
    this.setState({
      updateUserName: false,
      removeUser: false,
    });
  };

  updateSelectedUsersName = () => {
    var newUserUpdate = {};
    newUserUpdate.userIdToUpdate = this.state.selectedUser.id;
    newUserUpdate.newUserName = this.state.newUserName;
    newUserUpdate.userIdRequestingTheEdit = this.props.user.id;
    axios({
      method: 'POST',
      url: 'https://thm5r2aanh.execute-api.ap-northeast-1.amazonaws.com/default/telenetEditUser',
      headers: {
        "x-api-key": "Q2XmeN05Ol9Pwjb0dFgmD62pdqgOJzAi52kBZcRJ"
      },
      data: newUserUpdate
      }).then(response => {
        this.getSelectedGroupUsers();
        this.handleClose();
      });
  };

  render() {
    const { classes, t } = this.props;
    return (
      <div className={isLiveApp? "live-table": "view-table"}>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell
                padding="none"
                className={`${classes.head} ${classes.headTitle}`}
              >
                {t("Group Members")}
              </TableCell>
              <TableCell padding="none" className={classes.head} />
              <TableCell padding="none" className={classes.head} align="left">
                <Button onClick={this.handleAddMemberDialog}>
                  <AddIcon />
                  {t("Add Member")}
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.users
              ? this.state.users.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {user.name}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ paddingRight: 0, width: "45%" }}
                    >
                      <Button 
                        variant="contained" 
                        color="secondary"
                        onClick={() => this.handleOpenRemove(user)}
                      >
                        <DeleteIcon />
                        {t("Delete")}
                      </Button>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "#0D6AAD", color: "white" }}
                        onClick={() => this.handleOpen(user)}
                      >
                        <SettingsIcon />
                        {t("Edit")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
        <Dialog
          open={this.state.addMemberDialog}
          onClose={this.handleAddMemberDialog}
          className={isLiveApp? "add-group-member-modal dark-mode": "add-group-member-modal"}
        >
          <div>
            <AddGroupMemberTable
              closeDialog={this.handleAddMemberDialog}
              parentGroup={this.props.parentGroup}
              selectedGroup={this.props.selectedGroup}
            />
          </div>
        </Dialog>
        <Dialog
          open={this.state.updateUserName}
          className={isLiveApp? "edit-group-member-modal dark-mode": "edit-group-member-modal"}
          onClose={this.handleClose}
        >
          <div className={isLiveApp? "edit-username-form-container dark-mode": "edit-username-form-container"}>
            <Typography>{`${t("User")}「${this.state.selectedUser? this.state.selectedUser.name : null}」${t("to Edit")}`}</Typography>
            <form onSubmit={this.handleSubmit}>
              <FormControl margin="normal" fullWidth>
                <TextField
                  required
                  defaultValue={this.state.selectedUser? this.state.selectedUser.name : null}
                  id="newUserName"
                  name="new-username"
                  label={t("Group User")}
                  autoComplete="off"
                  onChange={this.handleChange}
                  autoFocus
                  variant="outlined"
                />
              </FormControl>
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.handleClose}
                >
                  <CancelIcon />
                  {t("Cancel")}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#0D6AAD", color: "white" }}
                >
                  <CheckIcon />
                    {t("Edit Name")}
                </Button>
              </div>
            </form>
          </div>
        </Dialog>
        <Dialog
          open={this.state.removeUser}
          onClose={this.handleClose}
          className={isLiveApp? "remove-user-modal dark-mode": "remove-user-modal"}
        >
          <div className="header">
            <Typography
              variant="h6"
            >
              {`「${this.state.selectedUser? this.state.selectedUser.name : null}」${t("Delete this user?")}`}
            </Typography>
          </div>
          <Divider />
          <div className="body">
            <Typography>
              {t("Remove this user from this group?")}
            </Typography>
          </div>
          <Divider />
          <div className="footer">
            <div style={{ marginRight: "10px" }}>
              <Button
                variant="contained"
                style={{ backgroundColor: "#0D6AAD", color: "white" }}
                onClick={this.handleClose}
              >
                <CancelIcon />
                {t("Cancel")}
              </Button>
            </div>
            <div>
              <Button 
                variant="contained" 
                color="secondary"
                onClick={() => this.removeGroupMember()}
              >
                <DeleteIcon />
                {t("Delete")}
              </Button>
            </div>
          </div>
        </Dialog>
      </Paper>
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(GroupMemberTable));
