import React, { useState, useEffect } from 'react';
import { withStyles } from "@material-ui/core/styles";

import Checkbox from "@material-ui/core/Checkbox";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FolderIcon from "@material-ui/icons/Folder";
import FilterDates from "./FilterDates";

var _ = require("lodash");

const MuiExpansionPanel = withStyles({
  root: {
    boxShadow: "none",
    margin: "0px",
    "&$expanded": {
      margin: "0px"
    }
  },
  expanded: {}
})(ExpansionPanel);

const MuiExpansionPanelSummary = withStyles({
  root: {
    boxShadow: "none",
    padding: "0px",
    margin: "0px",
    minHeight: "40px !important",
    height: "40px"
  },
  content: {
    margin: "0px",
    "&$expanded": {
      margin: "0px",
      minHeight: "50px",
      height: "50px"
    }
  },
  expanded: {}
})(ExpansionPanelSummary);

const CustomCheckbox = withStyles({
  root: {
    color: "#57697d",
    "&$checked": {
      color: "#57697d"
    }
  },
  checked: {}
})(Checkbox);

const hasColorLevel = (levelName, k) => {
  switch (levelName) {
    case "緊急度1":
      return <div className="level-1" key={`level-1-key-${k}`} />;
    case "緊急度2":
      return <div className="level-2" key={`level-2-key-${k}`} />;
    case "緊急度3":
      return <div className="level-3" key={`level-3-key-${k}`} />;
    case "緊急度4":
      return <div className="level-4" key={`level-4-key-${k}`} />;
    default:
      break;
  }
};

const FilterDropdownItem = ({
  tag,
  filterList,
  registerFilterItems,
  toggleTaisakuFilters,
  filterByTaisaku,
  filterRange,
  colorList,
  setDates,
  filterByLists,
  isLiveApp,
  storageLimit,
}) => {
  const [itemList, setItemList] = useState([]);
  const [groupUsersList, setGroupUsersList] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const {t} = useTranslation();

  const disabledPanel =
    (tag === t("Urgency Level") || tag === t("Devices") || tag === t("Date Range")) &&
    filterByLists &&
    filterByLists[t("Incident")] &&
    filterByLists[t("Incident")].length > 0;
  
    const resetItemList = () => {
      setItemList([]);
      setGroupUsersList([]);
    };
  
  useEffect(() => {
    if (tag === t("Urgency Level") || tag === t("Devices")) {
      setShowAll(true)
    }
  }, [tag, t]);

  useEffect(() => {
    if (disabledPanel) {
      setExpanded(false);
    }
  }, [disabledPanel]);

  useEffect(() => {
    if (
      filterByLists &&
      filterByLists[t('Incident')] &&
      filterByLists[t('Incident')].length === 0
    ) {

      if (disabledPanel) {
        resetItemList();
      }
    }

    if (itemList.length > 0 && showAll) {
      if (tag === t("Urgency Level") || tag === t("Devices")) {
        setShowAll(false);
      }

      if (
        tag === t('Incident') ||
        tag === t("Device Last Location") ||
        tag === t("Facility Information")
      ) {
        if (filterList.length > itemList.length) {
          setShowAll(false);
        }
      }
    }
  }, [filterByLists, t, tag, disabledPanel, filterList, itemList, showAll]);

  const handleClick = (e, selectedItem) => {
    e.stopPropagation();
    
    const hasItem = itemList.includes(selectedItem.id);
    
    if (tag === t("Devices")) {
      const newGroupUsersList = _.cloneDeep(groupUsersList);
      const selectedGroup = itemList.find(group => group.id === selectedItem.id);
      
      const index = newGroupUsersList.findIndex(group => group[selectedItem.id]);
      
      if (selectedGroup) {
        newGroupUsersList[index][selectedItem.id] = [];

        const updatedItemList = itemList.filter(group => group.id !== selectedItem.id);

        setItemList(updatedItemList);
        setGroupUsersList(newGroupUsersList);
        
        registerFilterItems(tag, updatedItemList, filterByTaisaku);
        registerFilterItems(t("Person"), newGroupUsersList, filterByTaisaku);
      } else {
        if (index !== -1) {
          newGroupUsersList[index][selectedItem.id] = selectedItem.users;
        } else {
          newGroupUsersList.push({ [selectedItem.id]: selectedItem.users });
        }

        const updatedItemList = [...itemList, selectedItem];

        setItemList(updatedItemList);
        setGroupUsersList(newGroupUsersList);

        registerFilterItems(tag, updatedItemList, filterByTaisaku);
        registerFilterItems(t("Person"), newGroupUsersList, filterByTaisaku);
      }
    } else if (hasItem) {
      const updatedItemList = itemList.filter(item => item !== selectedItem.id);

      setItemList(updatedItemList);

      registerFilterItems(tag, updatedItemList, filterByTaisaku);
    } else {
      const updatedItemList = [...itemList, selectedItem.id];

      setItemList(updatedItemList);

      registerFilterItems(tag, updatedItemList, filterByTaisaku);
    }
  };

  const handleUserClick = (selectedUser, userGroup) => {
    const newGroupUsersList = _.cloneDeep(groupUsersList);

    const hasGroup = itemList.find(group => group.id === userGroup.id);
    const hasUserGroup = newGroupUsersList.find(usersGroup => Object.keys(usersGroup)[0] === userGroup.id);

    const hasGroupUsers = hasUserGroup && hasUserGroup[userGroup.id].length > 0;
    const hasUser = hasGroupUsers && hasUserGroup[userGroup.id].find(user => user.id === selectedUser.id);

    if (hasGroup) {
      const index = newGroupUsersList.findIndex(group => group[userGroup.id]);
      const filteredUsers = newGroupUsersList[index][userGroup.id].filter(user => user.id !== selectedUser.id);
      newGroupUsersList[index][userGroup.id] = filteredUsers;

      const updatedItemList = itemList.filter(item => item.id !== userGroup.id);

      setItemList(updatedItemList);
      setGroupUsersList(newGroupUsersList);

      registerFilterItems(tag, updatedItemList, filterByTaisaku);
      registerFilterItems(t("Person"), newGroupUsersList, filterByTaisaku);

    } else {
      if (hasUser) {
        const filteredUsers = hasUserGroup[userGroup.id].filter(user => user.id !== selectedUser.id);
        const index = newGroupUsersList.findIndex(group => group[userGroup.id]);
        newGroupUsersList[index][userGroup.id] = filteredUsers;

        setGroupUsersList(newGroupUsersList);

        registerFilterItems(t("Person"), newGroupUsersList, filterByTaisaku);

      } else {
        if (hasUserGroup) {
          const index = newGroupUsersList.findIndex(group => group[userGroup.id]);
          newGroupUsersList[index][userGroup.id] = [...hasUserGroup[userGroup.id], selectedUser];

          if (newGroupUsersList[index][userGroup.id].length === userGroup.users.length) {
            const updatedItemList = [...itemList, userGroup];
            setItemList(updatedItemList);
          }

        } else {
          newGroupUsersList.push({ [userGroup.id]: [selectedUser] });
        }

        setGroupUsersList(newGroupUsersList);

        registerFilterItems(t("Person"), newGroupUsersList, filterByTaisaku);
      }
    }
  };

  const renderListUsers = userGroup => {
    if (userGroup) {
      return userGroup.users.map(user => (
        <ListItem 
          className="filter-user-list-item" 
          key={`userListItemKey-${user.id}`}
          dense
          onClick={() => handleUserClick(user, userGroup)}
        >
          <CustomCheckbox
            onClick={() => handleUserClick(user, userGroup)}
            checked={
              !itemList.some(group => group.id === userGroup.id) &&
              !hasUser(userGroup.id, user.id)
            }
            style={{ padding: 0 }}
          />
          <ListItemText className="filter-user-text" primary={user.name} />
        </ListItem>
      ));
    }
  };

  const hasUser = (userGroupId, selectedUserId) => {
    const userGroup = groupUsersList.find(
      usersGroup => Object.keys(usersGroup)[0] === userGroupId
    );
    const hasUsers = userGroup && userGroup[userGroupId].length > 0;

    if (hasUsers) {
      return userGroup[userGroupId].some(user => user.id === selectedUserId);
    }
  };

  const showFilteredHazardTags = () => {
    const filteredHazardList = filterList.filter(hazardLevel => {
      return !filterByLists[t("Urgency Level")].includes(hazardLevel.id);
    });

    return filteredHazardList.map(hazardLevel => 
      hasColorLevel(hazardLevel.levelName)
    );
  };

  const handleTopLevelTags = () => {
    if (filterByLists && filterByLists[t("Incident")].length > 0) {
      return <div></div>;
    } else if (tag === t("Urgency Level")) {
      return (
        <div className="top-level-hazard-tags-wrapper">
          <div className="top-level-hazard-tags">
            {showFilteredHazardTags()} {/* Assuming showFilteredHazardTags is another function you have */}
          </div>
        </div>
      );
    } else if (tag === t("Devices")) {
      return (
        <Typography className="top-level-tag">
          {filterByLists[t("Devices")].length === filterList.length
            ? t("All Groups")
            : `${filterList.length - filterByLists[t("Devices")].length} ${t("Group")}`}
        </Typography>
      );
    } else if (tag === t("Date Range")) {
      return (
        <Typography className="top-level-tag">
          {filterRange.range}
        </Typography>
      );
    }
  };

  const resetFilter = () => {
    if (showAll) {
      // hide all
      if (tag === t("Devices")) {
        let newGroupUsersList = _.cloneDeep(groupUsersList);

        filterList.forEach(group => {
          newGroupUsersList.push({ [group.id]: group.users });
        });

        setItemList([...filterList]);
        setGroupUsersList(newGroupUsersList);
        setShowAll(false);

        registerFilterItems(tag, itemList, filterByTaisaku);
        registerFilterItems(t("Person"), groupUsersList, filterByTaisaku);

      } else if (tag === t("Urgency Level")) {
        const itemIds = filterList.map(item => item.id);

        setItemList(itemIds);
        setShowAll(false);

        registerFilterItems(tag, itemList, filterByTaisaku);

      } else if (tag === t("Incident") || tag === t("Device Last Location") || tag === t("Facility Information")) {
        setItemList([]);
        setShowAll(false);

        registerFilterItems(tag, itemList, filterByTaisaku);
      }

    } else {
      // show all
      if (tag === t("Devices")) {
        setItemList([]);
        setGroupUsersList([]);
        setShowAll(true);

        registerFilterItems(tag, itemList, filterByTaisaku);
        registerFilterItems(t("Person"), groupUsersList, filterByTaisaku);

      } else if (tag === t("Urgency Level")) {
        setItemList([]);
        setShowAll(true);

        registerFilterItems(tag, itemList, filterByTaisaku);

      } else if (tag === t("Incident") || tag === t("Device Last Location") || tag === t("Facility Information")) {
        const itemIds = filterList.map(item => item.id);

        setItemList(itemIds);
        setShowAll(true);

        registerFilterItems(tag, itemList, filterByTaisaku);
      }
    }
  };



  return (
    <div className="filter-area">
      <ExpansionPanel disabled={disabledPanel} expanded={expanded}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{
            onClick: () => setExpanded(!expanded)
          }}
        >
          <div className="expansion-panel-top">
            <Typography className="filter-title">
              {tag === t("Urgency Level") ? t("Tag") : tag}
            </Typography>
            {handleTopLevelTags()}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={{
            display: "block",
            float: "left",
            width: "100%",
            padding: "0",
            paddingBottom: "20px"
          }}
        >
          {filterList ? (
            <div>
              {tag === t("Incident") ||
              tag === t("Urgency Level") ||
              tag === t("Devices") ||
              tag === t("Device Last Location") ||
              tag === t("Facility Information") ? (
                <div className={`check-all-filter ${tag === t("Devices") ? "groups" : ""}`}>
                  <ListItem
                    className="filter-checkbox-list-item"
                    dense
                    onClick={() => resetFilter()}
                  >
                    <CustomCheckbox
                      onClick={() => resetFilter()}
                      checked={showAll}
                    />
                    <ListItemText
                      className="filter-checkbox-text"
                      primary={t("View All")}
                    />
                  </ListItem>
                </div>
              ) : null}
  
              {filterList.map((item, index) => (
                <div key={`${item.name}-${index}`}>
                  {tag === t("Devices") ? (
                    <MuiExpansionPanel>
                      <MuiExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <CustomCheckbox
                          onClick={(e) => handleClick(e, item)}
                          checked={!itemList.some(group => group.id === item.id)}
                        />
                        <p
                          style={{
                            fontSize: "12px",
                            fontFamily: "Roboto",
                            lineHeight: "26px"
                          }}
                        >
                          {item.name}
                        </p>
                      </MuiExpansionPanelSummary>
                      <ExpansionPanelDetails
                        style={{
                          display: "block",
                          float: "left",
                          width: "100%",
                          padding: "0px",
                          margin: "0px",
                          paddingBottom: "20px"
                        }}
                      >
                        <div>{renderListUsers(item)}</div>
                      </ExpansionPanelDetails>
                    </MuiExpansionPanel>
                  ) : (
                    <ListItem
                      className="filter-checkbox-list-item"
                      key={index}
                      dense
                      onClick={(e) => handleClick(e, item)}
                    >
                      <CustomCheckbox
                        onClick={(e) => handleClick(e, item)}
                        checked={tag === t("Urgency Level") ? !itemList.includes(item.id) : itemList.includes(item.id)}
                      />
                      {colorList ? (
                        <div className={`color-category-label ${item.color}`} />
                      ) : (
                        <div>{hasColorLevel(item.levelName)}</div>
                      )}
                      <ListItemText
                        className="filter-checkbox-text"
                        primary={item.name}
                      />
                    </ListItem>
                  )}
                </div>
              ))}
  
              {tag === t("Urgency Level") ? (
                <div className="folder-checkbox-filter">
                  <ListItem
                    className="filter-checkbox-list-item"
                    dense
                  >
                    <CustomCheckbox
                      onClick={toggleTaisakuFilters}
                      checked={filterByTaisaku}
                    />
                    <FolderIcon style={{ marginRight: "10px" }} />
                    <Typography onClick={toggleTaisakuFilters}>{t("Display Resolved")}</Typography>
                  </ListItem>
                </div>
              ) : null}
            </div>
          ) : (
            <FilterDates setDates={setDates} storageLimit={storageLimit} disabled={isLiveApp ? false : disabledPanel} />
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {tag === t("Date Range") || tag === t("Incident") ? (
        <div className="place-filter-section">
          <div className="place-filter-divider"></div>
          <Typography className="place-filter-section-title">
            {tag === t("Incident") ? t("Filters") : t("Management Services")}
          </Typography>
        </div>
      ) : null}
    </div>
  );
}

export default FilterDropdownItem;