import React from "react";
import Loader from "react-loader-spinner";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import CancelIcon from "@material-ui/icons/Cancel";

import OffIcon from '@material-ui/icons/NotInterested';
import OnIcon from '@material-ui/icons/DoneRounded';

import { useTranslation } from 'react-i18next';

const isLiveApp = process.env.REACT_APP_TYPE === "LIVE" && process.env.REACT_APP_TYPE !== "VIEW"? true: false;

const ToggleNotificationSoundDialog = ({
  open,
  close,
  handleToggleNotificationSound,
  notificationSound,
  loading,
}) => {
  const {t} = useTranslation();
  return (
    <Dialog open={open} onClose={close} className={isLiveApp? "delete-place-modal dark-mode": "delete-place-modal"}>
      <div className="header">
        <Typography variant="h6">{t("New notification sound settings")}</Typography>
      </div>
      <Divider />
      <div className="body">
        <Typography>{`${notificationSound? t("The new notification sound is currently ON, would you like to change it to OFF?"): t("The new notification sound is OFF; would you like to change it to ON?")}`}</Typography>
      </div>
      <Divider />
      <div className="footer">
        <div style={{ marginRight: "10px" }}>
          <Button
            variant="contained"
            style={{ backgroundColor: "#0D6AAD", color: "white" }}
            onClick={close}
          >
            <CancelIcon />
            {t("Cancel")}
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleToggleNotificationSound}
          >
            {notificationSound? 
              
              <>
                <OffIcon />{t("Sound OFF")}
              </>:
              <>
                <OnIcon />{t("Sound ON")}
              </>
            }
            
            {loading ? (
              <div>
                <Loader type="TailSpin" color="white" height={15} width={15} />
              </div>
            ) : null}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ToggleNotificationSoundDialog;
