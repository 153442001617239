import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";

import PrintViewModeSelect from "./PrintViewModeSelect";
import BuildPrintProgress from "./BuildPrintProgress";
import { useTranslation } from 'react-i18next';

const PlaceSelectConfirm = ({
  open,
  close,
  handleMakeTaisaku,
  handleMarkSelectedAsDeleted,
  handlePrintSelected,
  handleExportSelected,
  selectedAction,
  loadingAction,
  handlePrintViewMode,
  actionProgress
}) => {
  const { t } = useTranslation();

  const actionSwitch = selectedAction => {
    switch (selectedAction) {
      case "taisaku":
        handleMakeTaisaku();
        break;
      case "delete":
        handleMarkSelectedAsDeleted();
        break;
      case "print":
        handlePrintSelected();
        break;
      case "export":
        handleExportSelected();
        break;
      default:
        break;
    }
  };

  return (
    <Dialog open={open} onClose={close} className="place-select-confirm-modal">
      <Typography variant="h6" className="live-snapshot-header">
        {selectedAction === 'none'? t("The target is not selected. Please select the target and try again.") : t("Are you sure you want to continue?") }
      </Typography>
      <div>
        {selectedAction === "print"? <PrintViewModeSelect handlePrintViewMode={handlePrintViewMode} />: null}
      </div>
      <div className="live-snapshot-buttons">
        {selectedAction === 'none'? 
          <Button
            variant="contained"
            color="primary"
            className="close-button"
            onClick={close}
          >
            OK
          </Button>:
          <div>
            <Button
              variant="contained"
              color="primary"
              className="close-button"
              onClick={close}
            >
              {t("Back")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="confirm-button"
              onClick={() => actionSwitch(selectedAction)}
              disabled={loadingAction}
            >
              {t("Confirm")}
            </Button>
            {loadingAction ? (
                <BuildPrintProgress actionProgress={actionProgress} />
              ) : null}
          </div>
        }
      </div>
    </Dialog>
  );
};

export default PlaceSelectConfirm;
