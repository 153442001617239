import React from "react";

const Hls = require('hls.js');

export default class LiveStreamVideoPlayer extends React.Component {
  state = {
    playing: false,
  }

  componentDidMount() {
    this.checkLiveStream();
  }

  checkLiveStream = () => {
    if(this.props.videoURL) {
      var hls = new Hls();
      hls.loadSource(this.props.videoURL);
      hls.attachMedia(this.player);
      hls.on(Hls.Events.MANIFEST_PARSED,() => {
        this.player.play();
        this.setState({
          playing: true,
        });
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="is-live-tag">LIVE</div>
        <video class={this.state.playing? "livestream-video is-playing": "livestream-video"} ref={player => (this.player = player)} muted="muted" autoplay=""></video>
      </React.Fragment>
    );
  }
}
