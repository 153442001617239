import React, { useState } from "react";

import {
  Button,
  FormControl,
  Paper,
  TextField,
  Typography,
  Dialog,
  Slide
} from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { useTranslation } from 'react-i18next';

import Loader from "react-loader-spinner";

const Transition = props => {
  return <Slide direction="left" {...props} />;
};

const handleChange = (e, levelName, hazardNames, setHazardNames) => {
  e.persist();
  setHazardNames({
    ...hazardNames,
    [levelName]: e.target.value
  });
};

const handleSubmit = (e, hazardNames, updateHazardLevelName) => {
  e.preventDefault();
  const swappedObj = swapObjectKeys(hazardNames);
  updateHazardLevelName(swappedObj); 
};

const isLiveApp = process.env.REACT_APP_TYPE === "LIVE" && process.env.REACT_APP_TYPE !== "VIEW"? true: false;

const hasLevelName = (levelName) => {
  switch (levelName) {
    case "緊急度1":
      return "大至急!";
    case "緊急度2":
      return "至急";
    case "緊急度3":
      return "通常";
    case "緊急度4":
      return "参考";
    default:
      return "blue";
  }
};

const swapObjectKeys = (obj) => {
  let newObj = {};
  for (let key in obj) {
    const newKey = reverseLevelKeys(key);
    newObj[newKey] = obj[key];
  }
  return newObj;
};

const reverseLevelKeys = (levelName) => {
  switch (levelName) {
    case "大至急!":
      return "緊急度1"
    case "至急":
      return "緊急度2"
    case "通常":
      return "緊急度3"
    case "参考":
      return "緊急度4"
    default:
      return "blue";
  }
};

const EditHazardLevelForm = ({ 
  parentGroup,
  levels, 
  loading, 
  updateHazardLevelName,
  open,
  handleClose
}) => {
  const [hazardNames, setHazardNames] = useState({});
  const { t } = useTranslation();

  const { customData } = parentGroup;
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      className={isLiveApp? "dark-mode-menu": "light-mode-menu"}
      TransitionComponent={Transition}
      style={{ margin: "0 0 0 50%" }}    
    >
      <div className={"filter-edit-dialog-container"}>
        <div className="dialog-nav-container">
          <Button
            variant="contained"
            style={{ color: "white", backgroundColor: "#0D6AAD" }}
            onClick={handleClose}
          >
            <ArrowBack />
            {t("Back")}
          </Button>
        </div>
      </div>
      <div className={"hazard-level-form-wrapper"}>
        <Paper elevation={3}>
          <div className="hazard-level-form-container">
            <div className="hazard-level-form-title">
              <Typography align="center" variant="h5">
                {t("Tag")}
              </Typography>
            </div>
            <div className="hazard-level-form-container">
              <form id="hazardLevelForm" className="hazard-level-form" onSubmit={e => handleSubmit(e, hazardNames, updateHazardLevelName)}>
              {levels.map((level, index) => {
                let prevHazardName = level.customData.name;
                let levelName = hasLevelName(level.pk.tag.name, t);
                let levelNameTag = level.pk.tag.name;
                let customName = customData && customData[level.pk.tag.name]? customData[level.pk.tag.name] : null;
                return (
                    <div className="hazard-level" key={`${levelNameTag}-${index}`}>
                      <p                         
                        className={`hazard-name-${levelNameTag.split("")[levelNameTag.length - 1]}`}
                      >
                        {customName? customName: t(levelName)}
                      </p>
                      <FormControl margin="normal" fullWidth>
                        <TextField
                          id={levelName}
                          name={levelName}
                          autoComplete="off"
                          variant="outlined"
                          onChange={e => handleChange(e, levelName, hazardNames, setHazardNames)}
                          inputProps={{
                            maxLength: 12,
                          }}
                          defaultValue={customName? customName: t(levelName)}
                        />
                      </FormControl>                  
                    </div>
                );
              })}
              </form>
              <div className="save-hazardlevel-name-btn">
                <Button
                  form="hazardLevelForm"
                  type="submit"
                  variant="contained"
                  className="save-button"
                  style={{
                    backgroundColor: "#0E6AAD",
                    color: "#fff"
                  }}
                >
                  {t("Save")}
                  {loading? (
                    <div>
                      <Loader
                        type="TailSpin"
                        color="white"
                        height={15}
                        width={15}
                      />
                    </div>
                  ) : null}
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </Dialog>
  );
};

export default EditHazardLevelForm;
