import React from "react";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";

import EditPlaceListForm from "./EditPlaceListForm";

import "../styles/filter-edit-dialog.scss";

const Transition = props => {
  return <Slide direction="up" {...props} />;
};

const EditPlaceListDialog = ({
  open,
  isPublicOpen,
  handlePublicOpen,
  handleClose,
  selectedPlace,
  updatePlace,
  loadingDelete,
  updatePhoto,
  handlePublicPlace,
  loadingPublicPlace,
  handleError,
  handleMarkAsDeleted,
  handleEditPlaceDeleteOpen,
  editPlaceDeleteOpen,
  user,
  levels,
  parentGroup,
  incidentTags
}) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <div className="edit-place-dialog-container">
        <EditPlaceListForm
          isPublicOpen={isPublicOpen}
          handlePublicOpen={handlePublicOpen}
          handleClose={handleClose}
          selectedPlace={selectedPlace}
          updatePlace={updatePlace}
          loadingDelete={loadingDelete}
          updatePhoto={updatePhoto}
          handlePublicPlace={handlePublicPlace}
          loadingPublicPlace={loadingPublicPlace}
          handleError={handleError}
          handleMarkAsDeleted={handleMarkAsDeleted}
          handleEditPlaceDeleteOpen={handleEditPlaceDeleteOpen}
          editPlaceDeleteOpen={editPlaceDeleteOpen}
          user={user}
          levels={levels}
          parentGroup={parentGroup}
          incidentTags={incidentTags}
        />
      </div>
    </Dialog>
  );
}

export default EditPlaceListDialog;
