const geocore = require('@mekasmith/geocore');

geocore.SETTINGS = {
  PROJECT_ID: 'PRO-TNHP-1',
  BASE_URL: 'https://dev.geocore.jp/api'
};

/*
old Production (redirects to new):
https://telenet-prod.geocore.jp/api

current Dev only server
https://dev.geocore.jp/api

Test Production:
https://testprod.geocore.jp/api

No HTTPS Legacy Geocore
http://telenet.geocore.jp/api
*/

geocore.setup(geocore.SETTINGS.BASE_URL, geocore.SETTINGS.PROJECT_ID);


 
