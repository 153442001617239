// eslint-disable-next-line
import React, { useState } from 'react';

import { withStyles } from "@material-ui/core/styles";

import AddIncidentTagForm from "../../components/AddIncidentTagForm";
import PlaceSelectConfirm from "../../components/PlaceSelectConfirm";

import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import { useTranslation } from 'react-i18next';

import PersonIcon from "@material-ui/icons/Person";
import Draggable from 'react-draggable';
import i18n from '../../i18n';
import { ReactComponent as RefreshIcon } from "../../images/refresh-places.svg";
import { ReactComponent as OptionsIcon } from "../../images/options.svg";
import moment from "moment";
import "moment/locale/ja";
import "../../styles/place-list.scss";

moment.locale(i18n.language === "en"? "en": "ja");

i18n.on('languageChanged', function(lng) {
  moment.locale(lng === "en"? "en": "ja");
});

const geocore = require("@mekasmith/geocore");

const styles = {
  taisakuTag: {
    backgroundColor: "#0D6AAD",
    color: "white",
    marginTop: "5px",
    borderRadius: "5px",
    padding: "4px"
  },
  loadMoreBtn: {
    margin: "10px"
  }
};

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left"
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    "&:focus": {
      backgroundColor: "#EBEBEB",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

const CustomCheckbox = withStyles({
  root: {
    color: "#fc3",
    '&$checked': {
      color: "#fc3",
    },
  },
  checked: {},
})(Checkbox);

const LiveStreamList = ({
  places,
  placeAmount,
  handleLiveStreamListClick,
  filterByTaisaku,
  isScreenBuilderOpen,
  handleDragStart,
  handleDragStop,
  handleIsWebRTC,
  webRTCLiveList,
  hazardCamLiveStreamList,
  handleIsHazardCam,
  loadMoreButton,
  canQueryMore,
  loadingTabSwitch,
  getPlacesByArea,
  handleSelectedOpen,
  handleCheckAll,
  allChecked,
  handleCheckbox,
  checkboxData,
  incidentTagFormOpen,
  handleSelectedClose,
  handleCreateIncidentTag,
  loadingAction,
  incidentTags,
  selectedOpen,
  selectedAction,
  realtimeMapBuilderOpen,
  handleLiveListHover,
  openViduStreamPlaces
}) => {
  const [pos, setPos] = React.useState({x: 0, y: 0});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {t} = useTranslation();
  const [placePhotos, setPlacePhotos] = React.useState({});
  const [hasPolled, setHasPolled] = React.useState([]);

  const handleOptions = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getIncidentTagNames = (incidentTags) => {
    if (incidentTags.length > 2) {
      return incidentTags.map((incidentTag) => (
        <div className="place-list-incident-tag" key={incidentTag.name}>
          <div
            className={`place-list-incident-tag-color ${
              incidentTag.customData && incidentTag.customData.color
                ? incidentTag.customData.color
                : ""
            }`}
          ></div>
        </div>
      ));
    } else {
      return incidentTags.map((incidentTag) => (
        <div className="place-list-incident-tag" key={incidentTag.name}>
          <div
            className={`place-list-incident-tag-color ${
              incidentTag.customData && incidentTag.customData.color
                ? incidentTag.customData.color
                : ""
            }`}
          ></div>
          <Typography variant="h5" className="place-list-incident-tag-name hazard-live">
            {truncate(incidentTag.name, 8)}
          </Typography>
        </div>
      ));
    }
  };

  const truncate = (input, length) => {
    if (input.length > length) return input.substring(0, length) + "...";
    else return input;
  };

  const hasLevelName = (levelName, customName) => {
    let hazardName;
    switch (levelName) {
      case "緊急度1":
        hazardName = customName? customName : t("Alert 4");
        return hazardName;
      case "緊急度2":
        hazardName = customName? customName : t("Alert 3");
        return hazardName;
      case "緊急度3":
        hazardName = customName? customName : t("Alert 2");
        return hazardName;
      case "緊急度4":
        hazardName = customName? customName : t("Alert 1");
        return hazardName;
      default:
        break;
    }
  };

  const hasLevelNumber = (levelName) => {
    switch (levelName) {
      case "大至急！":
        return "1";
      case "至急":
        return "2";
      case "通常":
        return 3;
      case "参考":
        return 4;
      default:
        break;
    }
  };

  const hasLevelIntString = (levelInt) => {
    switch (levelInt) {
      case "1":
        return t("Alert 4");
      case "2":
        return t("Alert 3");
      case "3":
        return t("Alert 2");
      case "4":
        return t("Alert 1");
      default:
        break;
    }
  };

  const getPhotoData = async (placeId, binaries) => {
    if (binaries.length > 0) {
      let photoData = [];
      binaries.forEach(binary => {
        if (binary.includes("photo")) {          
          const urlPromise = geocore.objects.bins.url(placeId, binary)
          photoData.push(urlPromise);
        }
      });
      return Promise.all(photoData);
    } 
  };

  const getPhotoURLs = async placeId => {
    const binaries = await geocore.objects.bins.list(placeId);
    const photoData = await getPhotoData(placeId, binaries);
    const photoURLs = [];
    if(photoData) {
      photoData.forEach(photo => {
        photoURLs.push(photo.url);
      });

      const polling = hasPolled;
      polling.push(placeId);
      setHasPolled(polling);

    }

    setPlacePhotos((prevState) => ({
      ...prevState,
      [placeId]: photoURLs[0],
    }));
  };

  const pollForImage = (placeId) => {
    if(hasPolled.includes(placeId)) {
      return
    }
    getPhotoURLs(placeId);
  }


  const handleEndDrag = (e, place) => {
    setPos({x: 0, y: 0});
    handleDragStop({x: e.clientX, y: e.clientY}, place);
  }

  const handleDrag = (e) => {
    // draging
  }

  const formatDuration = (duration) => {
    // Ensure duration is an integer
    duration = Math.floor(duration);
  
    // Hours, minutes, and seconds
    var hrs = Math.floor(duration / 3600);
    var mins = Math.floor((duration % 3600) / 60);
    var secs = duration % 60;
  
    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";
  
    if (hrs > 0) {
      ret += hrs + ":";
    }
  
    ret += (mins < 10 ? "0" : "") + mins + ":";
    ret += (secs < 10 ? "0" : "") + secs;
  
    return ret;
  }

  const runningWebRTCStreamCount = webRTCLiveList? webRTCLiveList.length: 0;
  const hazardCamStreamCount = hazardCamLiveStreamList ? hazardCamLiveStreamList.length: 0;

  const runningStreamCount = runningWebRTCStreamCount + hazardCamStreamCount;

  return (
    <div>
      <div className="amount-per-page-action-container">
        <div className="place-select-menu-container">
          <PlaceSelectConfirm
            open={selectedOpen}
            close={handleSelectedClose}
            selectedAction={selectedAction}
          />
          <AddIncidentTagForm 
            open={incidentTagFormOpen}
            close={handleSelectedClose}
            handleCreateIncidentTag={handleCreateIncidentTag}
            loadingAction={loadingAction}
            incidentTags={incidentTags}
          />
          <Button
            variant="contained"
            onClick={() => getPlacesByArea(true)}
            style={{
              backgroundColor: "#fc3",
              marginRight: "5px",
            }}
          >
            <RefreshIcon />
            <Typography
              variant="h4"
              style={{ paddingLeft: "5px", fontSize: "15px", color: "#7D650E" }}
            >
              {t("Update Listings")}
            </Typography>
          </Button>
          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            onClick={handleOptions}
            style={{
              backgroundColor: "#1471f2",
            }}
          >
            <OptionsIcon />
            <Typography variant="h4" style={{ paddingLeft: "5px", fontSize: "15px", color: "#fff" }}>
              {t("Options")}          
            </Typography>
          </Button>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className="hazard-live-options-dropdown"
          >
            <StyledMenuItem>
              <CustomCheckbox
                checked={allChecked}
                onClick={e => { handleCheckAll(e); handleClose() }}
                style={{ padding: 0, marginRight: "13px" }}
              />
              <ListItemText primary={t("Select All")} />
            </StyledMenuItem>
            <StyledMenuItem>
              <ListItemIcon>
                <CreateNewFolderIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={t("Select and add to incidents")}
                onClick={() => { handleSelectedOpen("incident"); handleClose() }}
              />
            </StyledMenuItem>
          </StyledMenu>
        </div>
      </div>


      
      <div className="live-places-wrapper">    
        <List className="place-list-container">
          {runningStreamCount < 1?
            <div className="no-active-streams-currently">
              <Typography variant="h3">{t("There is no live broadcast at this time.")}</Typography>
            </div>
          : null}

          

          {webRTCLiveList && webRTCLiveList.map(place => (
          <>
          {openViduStreamPlaces[place]?<>
          <Draggable
              position={pos}
              key={place}
              onDrag={handleDrag}
              onStart={handleDragStart}
              disabled={!isScreenBuilderOpen}
              onStop={(e) => handleEndDrag(e, place)}
            >
              <ListItem
                key={`${place}`}
                onClick={isScreenBuilderOpen || realtimeMapBuilderOpen? null: () => handleLiveStreamListClick(place)}
                className={isScreenBuilderOpen? "list-item-container live-stream-list-container can-drag": "list-item-container live-stream-list-container"}
              >

                <div className={openViduStreamPlaces[place].shortDescription? 'place-thumb-image-container isLiveStream has-title': 'place-thumb-image-container isLiveStream no-title'} ></div>
                  {pollForImage(place)}
                  {placePhotos[place]?
                    <div className="live-stream-thumb" style={{backgroundImage: `url('${placePhotos[place]}')`}}></div>:
                    <div></div>}
                  <div className={`place-list-item-content isLiveStream`}>
                    <div className="is-live-tag-list">LIVE</div>
                    <div className="hazard-level-label list-label-hazard ">
                      <span
                        className={`hazard-level-${openViduStreamPlaces[place].tags[0].id.split("-")[openViduStreamPlaces[place].tags[0].id.split("-").length - 1]}`}
                      >
                        {hasLevelIntString(openViduStreamPlaces[place].tags[0].id.split("-")[openViduStreamPlaces[place].tags[0].id.split("-").length - 1])}
                      </span>

                    </div>
                  <div className="place-list-item-date">
                    <Typography  variant="h3">{moment.utc(openViduStreamPlaces[place].createTime).local().format("YYYY/MM/DD (ddd) HH:mm")}</Typography>
                  </div>
                    {openViduStreamPlaces[place].shortDescription?
                    <Typography variant="h4" className="place-list-item-live-title">
                      {openViduStreamPlaces[place].shortDescription}
                    </Typography> : <div></div>}


                  <Typography variant="h4" className="place-list-item-group-name">
                    {openViduStreamPlaces[place].customData["group.name"]}
                  </Typography>
                  <div className="place-list-item-username-container">
                    <PersonIcon />
                    <Typography variant="h5" className="username">
                      {openViduStreamPlaces[place].customData["user.name"]}
                    </Typography>
                  </div>
                </div>
              </ListItem>
            </Draggable>
            </>:null}
            </>
          ))}

          {places &&
            places.map(place =>
              (place.isViewable && !filterByTaisaku && place.taisaku !== "true" && place.isLiveStream && place.streaming && !place.is_webrtc && !realtimeMapBuilderOpen) ||
              (place.isViewable && filterByTaisaku && place.taisaku === "true" && place.isLiveStream && place.streaming && !place.is_webrtc && !realtimeMapBuilderOpen) ? (
                <Draggable
                  position={pos}
                  key={place.id}
                  onDrag={handleDrag}
                  onStart={handleDragStart}
                  disabled={!isScreenBuilderOpen}
                  onStop={(e) => handleEndDrag(e, place)}
                >
                <ListItem
                  key={`live-list-${place.placeId}`}
                  onClick={!isScreenBuilderOpen? () => handleLiveStreamListClick(place): null}
                  className={isScreenBuilderOpen? "list-item-container live-stream-list-container can-drag": "list-item-container live-stream-list-container"}
                >
                                        <h3 className="testing-id" style={{color: "#fff", position: "absolute", zIndex: 999, width: 100, height: 100}}>
          {place.placeId}
        </h3>

                  <div
                    className={`${place.streaming? "place-thumb-image-container isLiveStream": "place-thumb-image-container hasStorage"} ${place.title? 'has-title': 'no-title'}`}
                  />

                  <div className="place-list-checkbox-container">
                    <CustomCheckbox
                      className="place-list-checkbox"
                      checked={!!checkboxData[place.placeId]}
                      onClick={handleCheckbox(place.placeId)}
                      style={{ padding: 0 }}
                      value={place.placeId}
                    />
                  </div>

                  {place.images?
                    <div className="live-stream-thumb" style={{backgroundImage: `url('${place.images[0]}')`}}></div>:
                    <div></div>
                  }
                  <div className="place-list-item-content isLiveStream">
                    {place.duration?
                      <div className="duration">{place.duration}</div>:null
                    }
                    {place.streaming?
                      <div className="is-live-tag-list">LIVE</div>:
                      <div className="is-storage-tag-list">{t("Storage")}</div>
                    }
                    <div className="hazard-level-label list-label-hazard ">
                      <span
                        className={`hazard-level-${
                          place.hazardLevel.name.split("")[place.hazardLevel.name.length - 1]
                        }`}
                      >
                        {hasLevelName(place.hazardLevel.name, place.hazardLevel.customName)}
                      </span>
                    </div>
                    <div className="place-list-item-date">
                      <Typography variant="h3">{moment(place.createTime).local().format("YYYY/MM/DD (ddd) HH:mm")}</Typography>
                    </div>
                    {place.title?
                    <Typography variant="h4" className="place-list-item-live-title">
                      {place.title}
                    </Typography> : <div></div>}
                    <Typography variant="h4" className="place-list-item-group-name">
                      {place.groupName}
                    </Typography>
                    <div className="place-list-item-username-container">
                      <PersonIcon />
                      <Typography variant="h5" className="username">
                        {place.user}
                      </Typography>
                    </div>
                    {place.incidentTags.length > 0 ? (
                      <div className="place-list-incident-tags-container">
                        {getIncidentTagNames(place.incidentTags)}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </ListItem>
                </Draggable>
              ) : null
            )}

            {places &&
            places.map((place, index) =>
              (place.isViewable && !filterByTaisaku && place.taisaku !== "true" && place.isLiveStream && !place.streaming && !realtimeMapBuilderOpen) ||
              (place.isViewable && filterByTaisaku && place.taisaku === "true" && place.isLiveStream && !place.streaming && !realtimeMapBuilderOpen)? (
                <React.Fragment key={`drag-${place.placeId}`}>

                <Draggable
                  position={pos}
                  onDrag={handleDrag}
                  onStart={handleDragStart}
                  disabled={!isScreenBuilderOpen}
                  onStop={(e) => handleEndDrag(e, place)}
                >
                <ListItem
                  key={`live-list-${place.placeId}`}
                  onClick={!isScreenBuilderOpen? () => handleLiveStreamListClick(place): null}
                  className={isScreenBuilderOpen? "list-item-container live-stream-list-container can-drag": "list-item-container live-stream-list-container"}
                >
                  <div
                    className={place.streaming? "place-thumb-image-container isLiveStream": "place-thumb-image-container hasStorage"}
                  />

                  <div className="place-list-checkbox-container">
                    <CustomCheckbox
                      className="place-list-checkbox"
                      checked={!!checkboxData[place.placeId]}
                      onClick={handleCheckbox(place.placeId)}
                      style={{ padding: 0 }}
                      value={place.placeId}
                    />
                  </div>

                  {place.images?
                    <div className="live-stream-thumb" style={{backgroundImage: `url('${place.images[0]}')`}}>
                      {place.duration?
                      <div className="duration-stream">{formatDuration(place.duration)}</div>:null
                      }
                    </div>:
                    <div className="live-stream-thumb processing-thumb">
                      {place.duration?
                      <div className="duration-stream">{formatDuration(place.duration)}</div>:null
                      }
                    </div>
                  }
                  <div className={`place-list-item-content isLiveStream ${place.title? 'has-title': 'no-title'}`}>
                    {place.streaming?
                      <div className="is-live-tag-list">LIVE</div>:
                      <div className="is-storage-tag-list">{t("Storage")}</div>
                    }
                    <div className="hazard-level-label list-label-hazard ">
                      <span
                        className={`hazard-level-${
                          place.hazardLevel.name.split("")[place.hazardLevel.name.length - 1]
                        }`}
                      >
                        {hasLevelName(place.hazardLevel.name, place.hazardLevel.customName)}
                      </span>
                    </div>
                    <div className="place-list-item-date">
                      <Typography variant="h3">{moment(place.createTime).local().format("YYYY/MM/DD (ddd) HH:mm")}</Typography>
                    </div>
                    {place.title?
                    <Typography variant="h4" className="place-list-item-live-title">
                      {place.title}
                    </Typography> : <div></div>}
                    <Typography variant="h4" className="place-list-item-group-name">
                      {place.groupName}
                    </Typography>
                    <div className="place-list-item-username-container">
                      <PersonIcon />
                      <Typography variant="h5" className="username">
                        {place.user}
                      </Typography>
                    </div>
                    {place.incidentTags.length > 0 ? (
                      <div className="place-list-incident-tags-container">
                        {getIncidentTagNames(place.incidentTags)}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </ListItem>
                </Draggable>
                </React.Fragment>
              ) : null
            )}
          {canQueryMore || loadingTabSwitch? loadMoreButton(): null}
        </List>
      </div>
    </div>
  );
};

export default withStyles(styles)(LiveStreamList);
