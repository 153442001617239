import React, { useState } from "react";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";

import HazardUserSettingsNavbar from "./HazardUserSettingsNavbar";
import EditHazardLevelForm from "./EditHazardLevelForm";

import "../styles/filter-edit-dialog.scss";
import "../styles/settings.scss";
import { Button, Typography, Paper } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const Transition = props => {
  return <Slide direction="left" {...props} />;
};

const isLiveApp = process.env.REACT_APP_TYPE === "LIVE" && process.env.REACT_APP_TYPE !== "VIEW"? true: false;


const HazardUserSettings = ({
  open,
  handleClose,
  parentGroup,
  levels,
  updateHazardLevelName,
  loading,
  handleOpenFilterDialog,
  handleOpenTogglePublic,
  handleOpenNotificationSound,
}) => {
  const [openHazardLevelForm, setOpenHazardLevelForm] = useState(false);

  const { t, i18n } = useTranslation();
  const i18nLanguage = i18n.language;
  
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
    window.location.reload();
  };

  const settingTags = isLiveApp? [
    t("Incident"),
    t("Devices"),
    t("Admin Comment"),
  ] : [
    t("Incident"),
    t("Devices"),
    t("Facility Information"),
    t("Admin Comment"),
  ];

  return (
    <Dialog
      fullScreen
      open={open}
      className={isLiveApp? "dark-mode-menu": "light-mode-menu"}
      onClose={handleClose}
      TransitionComponent={Transition}
      style={{ margin: "0 0 0 50%" }}
    >
      <div className="filter-edit-dialog-container">
        <HazardUserSettingsNavbar handleClose={handleClose} />
      </div>
      <div className={isLiveApp? "hazard-settings-wrapper is-live": "hazard-settings-wrapper"}>
        <EditHazardLevelForm
          parentGroup={parentGroup}
          levels={levels}
          updateHazardLevelName={updateHazardLevelName}
          loading={loading}
          open={openHazardLevelForm}
          handleClose={() => setOpenHazardLevelForm(false)}
        />
        <Paper elevation={3}>
          <div className="setting-tag-container">
            <div className="setting-tag-header">
              <Typography 
                align="center"
                variant="h5"
              >
                {t("Settings")}
              </Typography>
            </div>
            <div className="setting-tag-btn-list-container">
              <div className={isLiveApp? "btn-grid-wrap dark-mode": "btn-grid-wrap"}>
                <Button
                  className="setting-tag-btn"
                  variant="contained"
                  style={{
                    backgroundColor: "#0E6AAD",
                    color: "#fff"
                  }}
                  onClick={() => setOpenHazardLevelForm(true)}              
                >
                  {t("Tag")}
                </Button>
                {settingTags.map((settingTag, index) => (
                  <Button 
                    key={`setting-tag-btn-${index}`}
                    className="setting-tag-btn"
                    variant="contained"
                    style={{
                      backgroundColor: "#0E6AAD",
                      color: "#fff"
                    }}
                    onClick={() => handleOpenFilterDialog(settingTag)}
                  >
                    {settingTag}
                  </Button>
                ))}
                {isLiveApp? null:
                <Button
                  className="setting-tag-btn"
                  variant="contained"
                  style={{
                    backgroundColor: "#0E6AAD",
                    color: "#fff"
                  }}
                  onClick={handleOpenTogglePublic}              
                >
                  {t("Public Settings")}
                </Button>
                }
                <Button
                  className="setting-tag-btn"
                  variant="contained"
                  style={{
                    backgroundColor: "#0E6AAD",
                    color: "#fff"
                  }}
                  onClick={handleOpenNotificationSound}              
                >
                  {t("New Notification Sound")}
                </Button>
                <div className="language-setting">
                <Typography 
                align="center"
                style={{fontSize: 13}}
                variant="h5"
              >
                {t("Language Settings")}
              </Typography>
              <div onClick={() => changeLanguage("en")} className={`language-setting-btn ${i18nLanguage === "en"? "selected": "notselected"}`}>
                <strong>ENGLISH</strong>
              </div>
              <div onClick={() => changeLanguage("jp")} className={`language-setting-btn ${i18nLanguage === "jp"? "selected": "notselected"}`}>
                <strong>日本語</strong>
              </div>
              </div>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </Dialog>
  );
};

export default HazardUserSettings;
