import React from "react";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import CircularProgress from '@material-ui/core/CircularProgress';
import LockIcon from '@material-ui/icons/LockOutlined';
import { ReactComponent as HazardViewLogo } from "../../images/hazardview-logo.svg";
import { ReactComponent as HazardLiveLogo } from "../../images/HazardLiveLogo.svg";
import { withTranslation } from 'react-i18next';

import "../../styles/login.scss";
const isLiveApp = process.env.REACT_APP_TYPE === "LIVE" && process.env.REACT_APP_TYPE !== "VIEW"? true: false;

class LoginPage extends React.Component {
  state = {
    loading: this.props.loading
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.handleAuthUser(this.state.username,this.state.password);
  }

  handleChange = e => this.setState({ [e.target.id]: e.target.value });

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({loading: this.props.loading});
    }
  }

  render() {
    const { t } = this.props;

    return (
      <div className="login-page-container">
        <Paper className="login-signup-form">
          <div className="login-form-logo-container">
            {isLiveApp?
              <HazardLiveLogo />:
              <HazardViewLogo />
            }
          </div>
          <form onSubmit={this.handleSubmit}>
            <FormControl margin="normal"  required fullWidth>
              <InputLabel htmlFor="username" className="dark-theme-label">{t("Username")}</InputLabel>
              <Input id="username" name="username" className="dark-theme-input" autoComplete="username" onChange={this.handleChange} autoFocus />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel className="dark-theme-label"  htmlFor="password">{t("Password")}</InputLabel>
              <Input
                name="password"
                type="password"
                className="dark-theme-input"
                id="password"
                autoComplete="current-password"
                onChange={this.handleChange}
              />
            </FormControl>
              <div className="login-btn-wrap">

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    className="btn-primary login-btn"
                    disabled={this.state.loading}
                    style={{marginTop: "5px"}}
                  >
                    {this.state.loading?
                      <CircularProgress size={20} thickness={6} className='loader-login-spinner' /> : <div><LockIcon className='icon-lock' /><span>{t("Login")}</span></div>}
                  </Button>
              </div>
          </form>
        </Paper>
      </div>
    )
  }
}
export default withTranslation()(LoginPage);

