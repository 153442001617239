import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import { useTranslation } from 'react-i18next';

import Loader from "react-loader-spinner";

const CustomCheckbox = withStyles({
  root: {
    color: "#57697d",
    '&$checked': {
      color: "#57697d",
    },
  },
  checked: {},
})(Checkbox);

const AddIncidentTagForm = ({
  open,
  close,
  handleCreateIncidentTag,
  loadingAction,
  incidentTags
}) => {
  const [checkboxData, setCheckboxData] = useState({});
  const { t } = useTranslation();

  const handleSubmit = () => {
    const selectedIncidents = [];

    Object.entries(checkboxData).forEach(checkbox => {
      let selectedIncidentId = checkbox[0];
      let selectedIncidentName = checkbox[1].name;
      let selectedIncidentCustomData = checkbox[1].customData;
      let isSelected = checkbox[1].isChecked;

      if(isSelected) {
        selectedIncidents.push({ 
          id: selectedIncidentId,
          name: selectedIncidentName,
          customData: selectedIncidentCustomData
        });
      }
    });

    handleCreateIncidentTag(selectedIncidents);
  };

  const handleClick = incidentTag => e => {
    e.stopPropagation();

    setCheckboxData({
      ...checkboxData, 
      [incidentTag.id]: { 
        name: incidentTag.name,
        customData: incidentTag.customData,
        isChecked: e.target.checked 
      }
    });
  };

  const handleIncidentTags = () => {
    return incidentTags.map((incidentTag, index) => (
      <FormControlLabel
        value={`${incidentTag.name}`}
        key={`incidentTagKey-${incidentTag.name}-${index}`}
        control={
          <CustomCheckbox
            onClick={handleClick(incidentTag)}
            checked={checkboxData[incidentTag] && checkboxData[incidentTag].isChecked}
          />
        }
        label={incidentTag.name}
        labelPlacement="end"
      />
    ));
  };
  
  return (
    <Dialog open={open} onClose={close} className="place-select-incident-modal">
      <div className="incident-tag-form-container">
        <FormControl component="fieldset">
          <FormLabel component="legend">{t("Select and add to incidents")}</FormLabel>
          <FormGroup aria-label="position" column="true">
            {open && incidentTags? handleIncidentTags() : null}
          </FormGroup>
        </FormControl>
      </div>
      <div className="live-snapshot-buttons">
        <Button
          variant="contained"
          color="primary"
          className="close-button"
          onClick={()=> { setCheckboxData({}); close() }}
        >
          {t("Back")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="confirm-button"
          onClick={()=> { setCheckboxData({}); handleSubmit() }}
        >
          {t("Confirm")}
          {loadingAction ? (
            <div>
              <Loader type="TailSpin" color="white" height={15} width={15} />
            </div>
          ) : null}
        </Button>
      </div>
    </Dialog>
  );
};

export default AddIncidentTagForm;
