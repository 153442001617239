import React from "react";

export default class LiveStreamStoragePlayer extends React.Component {
  state = {
    storageFound: false,
    storageURL: null,
  }

  componentDidMount() {
    this.checkLiveStreamStorage();
  }

  checkLiveStreamStorage = () => {
    console.log('checking for storage');
    this.setState({
      storageFound: false,
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.storageFound?
          <video ref={player => (this.player = player)} muted="muted" autoplay=""></video>:
          <div className="pending-stream-storage"></div>
        }
      </React.Fragment>
    );
  }
}
