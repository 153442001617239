import React from "react";

import Typography from "@material-ui/core/Typography";

import PersonIcon from "@material-ui/icons/Person";

import "../styles/print.scss";
import { useTranslation } from 'react-i18next';

const PrintView = ({ printingPlaceList, printViewMode, handleSelectedClose, loadingAction }) => {
  const places = printingPlaceList;
  const currentImage = React.createRef();
  const [printSwitch, setPrintSwitch] = React.useState(false);

  React.useEffect(() => {
    if (printSwitch) {
      handleSelectedClose();
      setPrintSwitch(false);
      window.print();
    }
  }, [printSwitch]);

  const hasLevelName = (levelName, customName, t) => {
    let hazardName;
    switch (levelName) {
      case "緊急度1":
        hazardName = customName ? customName : t("Alert 4");
        return hazardName;
      case "緊急度2":
        hazardName = customName ? customName : t("Alert 3");
        return hazardName;
      case "緊急度3":
        hazardName = customName ? customName : t("Alert 2");
        return hazardName;
      case "緊急度4":
        hazardName = customName ? customName : t("Alert 1");
        return hazardName;
      default:
        break;
    }
  };

  const hazardLevel = tagId => {
    if (tagId) {
      return tagId[tagId.length - 1];
    } else {
      return "no-id";
    }
  };

  const handleImageLoad = (ref, imgIndex, placeIndex, imgAmount, placeAmount) => {
    if (imgIndex + 1 === imgAmount && placeIndex + 1 === placeAmount) {

      if (loadingAction) {
        let img = ref.current;
        
        const loaded = () => {
          setTimeout(() => {
            setPrintSwitch(true);
          }, 3000);
        };
  
        if (img.complete) {
          loaded();
        } else {
          img.addEventListener('load', loaded);
          img.addEventListener('error', () => {
              //handle error here
          });
        }
      }
      
    }
  };
  const {t} = useTranslation();

  return (
    <div className={`print-view-wrap ${printViewMode === "single" ? "single-place disable-scrollbars" : "multi"}`}>
      {printViewMode === "single" ? (
        <div>
          {places &&
            places.map((place, placeIndex) => (
              <React.Fragment key={`image-container-${place.placeId}`}>
              {place.images && place.images.filter((im) => !im.includes("low")).length > 0 && place.images.filter((im) => !im.includes("low")).map((imgSrc, imgIndex) => (
                <div key={`${place.placeId}-${imgIndex}`} className="print-single-place">
                  <div>
                    <div className={`place-print-single-photos ${place.comment? "": "no-comment"}`}>
                      <img 
                        src={imgSrc} alt="print hazard"
                        ref={currentImage}
                        onLoad={() => handleImageLoad(currentImage, imgIndex, placeIndex, place.images.filter((im) => !im.includes("low")).length, places.filter((im) => !im.includes("low")).length)} 
                      />
                    </div>
                    <div className="place-modal-right-col print-modal-place-single">
                      <div className="place-modal-info">
                        <div className="place-date-and-tag-container">
                        <div className="hazard-level-label print-hazard-level">
                          <span
                            className={`hazard-level-${hazardLevel(
                              place.hazardLevel.id.split("-")
                            )}`}
                          >
                          <p>
                            {hasLevelName(
                              place.hazardLevel.name,
                              place.hazardLevel.customName,
                              t
                            )}
                          </p>
                          </span>
                        </div>
                        <div className="place-list-item-date">
                          <Typography variant="h3">{place.createTime}</Typography>
                        </div>
                        </div>
                        <Typography
                          variant="h4"
                          className="place-list-item-group-name"
                        >
                          {place.groupName}
                        </Typography>
                        <div className="place-list-item-username-container">
                          <PersonIcon />
                          <Typography variant="h5" className="username">
                            {place.user}
                          </Typography>
                        </div>
                        {place.geo && place.geo.latitude && place.geo.longitude? (
                          <div className="place-list-item-coordinates large-coords">
                            <div>
                              <Typography>{`Lat: ${place.geo.latitude}`}</Typography>
                            </div>
                            <div>
                              <Typography>{`Lng: ${place.geo.longitude}`}</Typography>
                            </div>
                          </div>
                        ) : null}
                        {place.comment ? (
                          <Typography
                            variant="h5"
                            className="place-list-item-notes large-notes"
                          >
                            {place.comment}
                          </Typography>
                        ) : null}
                        {place.images && place.images.filter((im) => !im.includes("low")).length > 1?
                          <div className="photo-amount large">{`(${imgIndex + 1}/${place.images.filter((im) => !im.includes("low")).length})`}</div>
                        : null}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              </React.Fragment>
            ))}
        </div>
      ) : (
        <div className="print-multi-place">
          {places &&
            places.map((place, placeIndex) => (
              <React.Fragment key={`image-container-${place.placeId}`}>
                {place.images && place.images.length > 0 && place.images.filter((im) => !im.includes("low")).map((imgSrc, imgIndex) => (
                  <div key={`${place.placeId}-${imgIndex}`} className="place-grid-two-by-two">
                    <div className="place-photo-grid">
                      <img 
                        src={imgSrc} alt="print hazard"
                        ref={currentImage}
                        onLoad={() => handleImageLoad(currentImage, imgIndex, placeIndex, place.images.filter((im) => !im.includes("low")).length, places.length)} 
                      />
                    </div>
                    <div className="place-modal-right-col print-modal-place-multi">
                      <div className="place-modal-info">
                        <div className="hazard-level-label">
                          <span
                            className={`hazard-level-${hazardLevel(
                              place.hazardLevel.id.split("-")
                            )} large-label`}
                          >
                            {hasLevelName(
                              place.hazardLevel.name,
                              place.hazardLevel.customName,
                              t
                            )}
                          </span>
                        </div>
                        <div className="place-list-item-date">
                          <Typography variant="h3" className="large-username">
                            {place.createTime}
                          </Typography>
                        </div>
                        <Typography
                          variant="h4"
                          className="place-list-item-group-name large-name"
                        >
                          {place.groupName}
                        </Typography>
                        <div className="place-list-item-username-container">
                          <PersonIcon />
                          <Typography variant="h5" className="large-username">
                            {place.user}
                          </Typography>
                        </div>
                        {place.geo && place.geo.latitude && place.geo.longitude? (
                          <div className="place-list-item-coordinates">
                            <div>
                              <Typography>{`Lat: ${place.geo.latitude}, Lng: ${place.geo.longitude}`}</Typography>
                            </div>
                          </div>
                        ) : null}
                        {place.comment ? (
                          <Typography
                            variant="h5"
                            className="place-list-item-notes large-notes"
                          >
                            {place.comment}
                          </Typography>
                        ) : (
                          <div className="place-list-no-comment"></div>
                        )}
                        {place.images && place.images.filter((im) => !im.includes("low")).length > 1?
                          <div className="photo-amount">{`(${imgIndex + 1}/${place.images.filter((im) => !im.includes("low")).length})`}</div>
                        : null
                        }
                      </div>
                    </div>
                  </div>
                ))}
              </React.Fragment>
            ))}
        </div>
      )}
    </div>
  );
};

export default PrintView;
