import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";

import Dashboard from "./Dashboard";
import LoginPage from "./LoginPage";
import { withTranslation } from 'react-i18next';

import firebase from "firebase/app";
import 'firebase/database';

import AlertSnackbar from "../components/AlertSnackbar";
import CircularProgress from '@material-ui/core/CircularProgress';
import "../styles/brand-styles.scss";

import "../geocore-settings";
import "../i18n";

var firebaseConfig = {
  apiKey: "AIzaSyClCXXq-g-BV4pHbYpvbQUZCMumIX4_k9k",
  authDomain: "telenet-214204.firebaseapp.com",
  databaseURL: "https://telenet-214204.firebaseio.com",
  projectId: "telenet-214204",
  storageBucket: "telenet-214204.appspot.com",
  messagingSenderId: "447956384218",
  appId: "1:447956384218:web:fca0a0626e4ca8c88fe8e3",
  measurementId: "G-C8M7T3527D"
};
firebase.initializeApp(firebaseConfig);

const geocore = require("@mekasmith/geocore");
var _ = require("lodash");

const isLiveApp = process.env.REACT_APP_TYPE === "LIVE" && process.env.REACT_APP_TYPE !== "VIEW"? true: false;
class App extends React.Component {
  state = {
    authed: false,
    hasError: false,
    errorMessage: null,
    loadingForm: false,
    token: null,
    project: null,
    user: null,
    filteredUserGroups: null,
    groups: null,
    parentGroup: null,
    tags: null,
    taggedFacilities: null,
    levels: null,
    comments: null,
    cachedLogin: null,
    cachedUser: null,
    userCount: 0,
    storageLimit: 0
  };

  componentDidMount() {
    const savedLanguage = localStorage.getItem('language');
    if(savedLanguage) {
      const { i18n } = this.props;

      i18n.changeLanguage(savedLanguage); 
    }
    this.setState(prevState => ({
      cachedLogin: sessionStorage.getItem('hazardview_token') || null,
      cachedUser: sessionStorage.getItem('hazardview_user') || null,
    }), () => {
      if(this.state.cachedLogin && this.state.cachedUser) {
        // we have previous login info
        this.loginWithPrevToken();
      } else {
        // no login, user is required to login again
      }
    });
  }

  // basically the other login, this needs to be cleaned up!
  loginWithPrevToken = () => {
    var token = this.state.cachedLogin;
    this.setState({ loadingForm: true, previousLoginLoading: true });
    geocore.ACCESS_TOKEN = token;
    var project = null;
    var user = null;
    var parentGroup = null;
    const { t } = this.props;

    //reset logToken

    var logToken =  Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2);
    this.setState({logToken: logToken});
    
    //set connection time
    var connectionRef = firebase.database().ref('connections/' + this.state.cachedUser);
    connectionRef.update({logToken: logToken});

    geocore.objects.get(geocore.PROJECT_ID).then(obj => {
      project = obj;
      return geocore.users.get(this.state.cachedUser);
    }).then(obj => {
        user = obj;
        return geocore.users.groups(this.state.cachedUser);
    }).then(objs => {
      var obj = _.find(objs, obj => {
        // city group
        return obj.pk.group.id.startsWith("GRO-TNHP-1-C-");
      });
      if (obj && obj.type === "CREATOR") {
        parentGroup = obj.pk.group;
        this.setState(
          prevState => ({
            authed: true,
            hasError: false,
            loadingForm: false,
            token: token,
            project: project,
            user: user,
            parentGroup: parentGroup,
            previousLoginLoading: false,
            storageLimit: parentGroup.customData && parentGroup.customData.livego_plan? parseInt(parentGroup.customData.livego_plan): 0
          }),
          () => {
            this.getGroupsPromise(true);
            this.getCategoriesPromise(true);
            this.getLevelsPromise(true);
            this.getTaggedFacilities(true);
            this.getCommentsPromise(true);
            this.getIncidentsPromise(true);
          }
        );
      } else {
        // error user isn't part of city level group
        
        this.setState({
          authed: false,
          hasError: true,
          loadingForm: false,
          token: null,
          project: null,
          user: null,
          parentGroup: null,
          previousLoginLoading: false,
          errorMessage:
            t("Cannot find this user on the group. Please contact support.")
        });
      }
    })
    .catch(error => {
      this.setState({
        authed: false,
        hasError: true,
        loadingForm: false,
        previousLoginLoading: false,
        errorMessage: t("Please log in again.")
      });
    });
  }

  handleAuthUser = (username, password) => {
    this.setState({ loadingForm: true });

    var token = null;
    var project = null;
    var user = null;
    var parentGroup = null;


    // let user log in with the suffix of their username
    if (!username.includes("USE-TNHP-1-")) {
      username = "USE-TNHP-1-A-" + username;
    }
    const { t } = this.props;

    geocore
      .login(username, password)
      .then(receivedToken => {
        token = receivedToken;
        // need to set the local storage of the token and user id so we can get assoicated posts
        sessionStorage.setItem('hazardview_token', token);
        sessionStorage.setItem('hazardview_user', username);

        var logToken =  Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2);
        this.setState({logToken: logToken});

        //set connection time
        var connectionRef = firebase.database().ref('connections/' + username);
        connectionRef.update({logToken: logToken});

        return geocore.objects.get(geocore.PROJECT_ID);
      })
      .then(obj => {
        project = obj;
        return geocore.users.get(username);
      })
      .then(obj => {
        user = obj;
        return geocore.users.groups(username);
      })
      .then(objs => {
        var obj = _.find(objs, obj => {
          // city group
          return obj.pk.group.id.startsWith("GRO-TNHP-1-C-");
        });
        if (obj && obj.type === "CREATOR") {
          parentGroup = obj.pk.group;
          this.setState(
            prevState => ({
              authed: true,
              hasError: false,
              loadingForm: false,
              token: token,
              project: project,
              user: user,
              parentGroup: parentGroup,
              storageLimit: parentGroup.customData && parentGroup.customData.livego_plan? parseInt(parentGroup.customData.livego_plan): 0
            }),
            () => {
              this.getGroupsPromise(true);
              this.getCategoriesPromise(true);
              this.getLevelsPromise(true);
              this.getTaggedFacilities(true);
              this.getCommentsPromise(true);
              this.getIncidentsPromise(true);
            }
          );
        } else {
          // error user isn't part of city level group
          this.setState({
            authed: false,
            hasError: true,
            loadingForm: false,
            token: null,
            project: null,
            user: null,
            parentGroup: null,
            errorMessage:
              t('Cannot find this user on the group. Please contact support.')
          });
        }
      })
      .catch(error => {
        this.setState({
          authed: false,
          hasError: true,
          loadingForm: false,
          errorMessage: t("Password or username is incorrect.")
        });
      });
  };

  getGroupsPromise = (reload) => {
    if (reload || !this.state.groupsPromise) {
      //console.log("Loading - Groups");
      var query = new geocore.groups.query();
      query
        .withParent(this.state.parentGroup.id)
        .all()
        .then(groups => {
          //console.log("Finished - Groups");
          this.getUsersLoop(groups);
        })
    }
  };

  getUsersLoop = (groups) => {
    //console.log("Loading - UserGroups");
    const userGroups = [];
    const { t } = this.props;

    groups.forEach(group => {
      const usersPromise = geocore.groups
        .users(group.id)
        .then(usersGroups => {
          const userObjs = usersGroups.map(userGroup => {
            return userGroup.pk.user;
          });
          group.users = userObjs;
          return group;
        });
      userGroups.push(usersPromise);        
    });
    Promise.all(userGroups).then(groups => {
      //console.log("Finished - UserGroups");
      this.countUsers(groups);
      this.setState({
        groups: groups,
      });
    }).catch((error) => {
      console.log(error);
      this.createErrorAlert(t("A search error has occurred. Please refresh the page."))
    });
  };

  countUsers = groups => {
    var total = 0;
    groups.forEach((group) => {
      total += group.users.length;
    });
    this.setState({userCount: total});
  };

  getCategoriesPromise = reload => {
    if (reload || !this.state.categoriesPromise) {
      //console.log("Loading - Categories");
      var query = new geocore.tags.query();
      query
        .withIdPrefix("TAG-TNHP-1-CT-" + this.state.parentGroup.id)
        .all()
        .then(tags => {
          //console.log("Finished - Categories");
          this.setState({
            tags: tags
          });
        });
    }
  };

  getIncidentsPromise = reload => {
    if (reload || !this.state.incidentsPromise) {
     //console.log("Loading - Incidents");
      var query = new geocore.tags.query();
      query
        .withIdPrefix("TAG-TNHP-1-INC-" + this.state.parentGroup.id)
        .all()
        .then(incidentTags => {
          //console.log("Finished - Incidents");
          this.setState({
            incidentTags: incidentTags
          });
        });
    }
  };

  getCommentsPromise = reload => {
    if (reload || !this.state.commentsPromise) {
     //console.log("Loading - Comments");
      var query = new geocore.places.query();
      query
        .withTagIds('TAG-TNHP-1-CMT-1-' + this.state.parentGroup.id)
        .all()
        .then(comments => {
          //console.log("Finished - Comments");
          this.setState({
            comments: comments
          });
        });
    }
  };

  getLevelsPromise = reload => {
    if (reload || !this.state.levelsPromise) {
      var query = new geocore.groups.query();
      query
        .withId(this.state.parentGroup.id)
        .tags()
        .then(levels => {
          this.setState({
            levels: levels
          });
        });
    }
  };

  getTaggedFacilities = reload => {
    if (reload || !this.state.categoriesPromise) {
      //console.log("Loading - Facilities");
      const filteredFacilityGroups = {};
      const { t } = this.props;

      var query = new geocore.tags.query();
      query
        .withIdPrefix("TAG-TNHP-1-CT-" + this.state.parentGroup.id)
        .all()
        .then(tags => {
          const taggedFacilities = [];

          tags.forEach(tag => {
            const facilitiesPromise = new geocore.places.query()
              .withTagIds(tag.id)
              .all()
              .then(facilities => {
                return facilities
              });
            taggedFacilities.push(facilitiesPromise);
          });
          
          Promise.all(taggedFacilities).then(taggedFacilities => {

            taggedFacilities.forEach((facilityArray, index) => {
              
              facilityArray.forEach(facility => {
                facility.groupId = tags[index].id;
                facility.groupName = tags[index].name;
                facility.color = tags[index].customData && tags[index].customData.color? tags[index].customData.color : "blue";
              });
              
              filteredFacilityGroups[tags[index].id] = facilityArray;
            });

            if(Object.keys(filteredFacilityGroups).length === taggedFacilities.length) {
              //console.log("Finished - Facilities");
              this.setState({ facilityGroups: filteredFacilityGroups });
            }

          }).catch((error) => {
            console.log(error);
            this.createErrorAlert(t("A search error has occurred. Please refresh the page."));
          });
        }).catch((error) => {
          console.log(error);
          this.createErrorAlert(t("A search error has occurred. Please refresh the page."))
        });
    }
  };

  handleUserLogout = () => {
    geocore.logout();
    sessionStorage.removeItem('hazardview_token');
    sessionStorage.removeItem('hazardview_user');
    this.setState({
      authed: false,
      hasError: false,
      errorMessage: null,
      loadingForm: false,
      token: null,
      project: null,
      user: null,
      groups: null,
      userCount: 0,
      tags: null,
      levels: null,
      logToken: null
    });
    //not sure why but login errors out unless the page is refreshed when forced logged out
    window.location.reload();
  };

  handleErrorAlertClick = () => {
    this.setState({ hasError: false });
  };

  updateUserInfo = updatedUser => {
    this.setState({ user: updatedUser });
  };

  updateParentGroupInfo = updatedParentGroup => {
    this.setState({ parentGroup: updatedParentGroup });
  };

  updateIncidentTag = updatedIncidentTag => {

    const groupIndex = this.state.incidentTags.findIndex(incidentTag => {
      return incidentTag.id === updatedIncidentTag.id
    });

    if (groupIndex === 0 && this.state.incidentTags.length === 1) {
      this.setState(prevState => ({
        incidentTags: [
          updatedIncidentTag,
        ]
      }));
    } else if(groupIndex >= 0) {
      this.setState(prevState => ({
        incidentTags: [
          ...prevState.incidentTags.slice(0,groupIndex),
        updatedIncidentTag,
        ...prevState.incidentTags.slice(groupIndex + 1),
        ]
      }));
    }

  };

  updateFacilityGroup = updatedFacilityGroup => {

    const groupIndex = this.state.tags.findIndex(facilityGroup => {
      return facilityGroup.id === updatedFacilityGroup.id
    });

    if (groupIndex === 0 && this.state.tags.length === 1) {
      this.setState(prevState => ({
        tags: [
          updatedFacilityGroup, 
        ]
      }));
    } else if(groupIndex >= 0) {
      this.setState(prevState => ({
        tags: [
          ...prevState.tags.slice(0,groupIndex),
        updatedFacilityGroup,
        ...prevState.tags.slice(groupIndex + 1),
        ]
      }));
    }

  };
  
  removeFacilityGroup = facilityGroupId => {

    const groupIndex = this.state.tags.findIndex(facilityGroup => {
      return facilityGroup.id === facilityGroupId
    });

    if (groupIndex > -1) {
      this.setState(prevState => ({
        tags: [
          ...prevState.tags.slice(0,groupIndex),
          ...prevState.tags.slice(groupIndex + 1),
        ]
      }));
    }
  };

  removeIncidentTag = groupId => {
 
    const groupIndex = this.state.incidentTags.findIndex(incidentTag => {
      return incidentTag.id === groupId
    });
    this.setState(prevState => ({
      incidentTags: [
      ...prevState.incidentTags.slice(0, groupIndex),
      ...prevState.incidentTags.slice(groupIndex + 1)
      ]
    }));

  };

  removeGroup = groupId => {
    const groupIndex = this.state.groups.findIndex(group => {
      return group.id === groupId
    });
    
    this.setState(prevState => ({
      groups: [
        ...prevState.groups.slice(0, groupIndex),
        ...prevState.groups.slice(groupIndex + 1)
      ]
    }));
  };

  createErrorAlert = (message) => {
    this.setState({
      hasError: true,
      errorMessage: message
    });
  }

  render() {
    const { t } = this.props;
    return (
      <BrowserRouter>
        <div className={isLiveApp? "HAZARDLIVE_APP": "HAZARDVIEW_APP"}>
          {this.state.previousLoginLoading?
            <div className="resignin-user-loading">
              <div className="loader-container">
                <CircularProgress />
                <div className="loader-logo"></div>
              </div>
            </div>:
            <React.Fragment>
              <AlertSnackbar
                isType="error"
                isOpen={this.state.hasError}
                handleClick={this.handleErrorAlertClick}
                message={this.state.errorMessage}
              />
              {this.state.authed ? (
                <Switch>
                  <Route
                    path="/"
                    render={props => (
                      <Dashboard
                        {...props}
                        isLiveApp={isLiveApp}
                        handleUserLogout={this.handleUserLogout}
                        updateUserInfo={this.updateUserInfo}
                        updateParentGroupInfo={this.updateParentGroupInfo}
                        user={this.state.user}
                        tags={this.state.tags}
                        logToken={this.state.logToken}
                        incidentTags={this.state.incidentTags}
                        groups={this.state.groups}
                        userCount={this.state.userCount}
                        levels={this.state.levels}
                        comments={this.state.comments}
                        parentGroup={this.state.parentGroup}
                        facilityGroups={this.state.facilityGroups}
                        getGroups={this.getGroupsPromise}
                        getTags={this.getCategoriesPromise}
                        getIncidentTags={this.getIncidentsPromise}
                        getComments={this.getCommentsPromise}
                        getTaggedFacilities={this.getTaggedFacilities}
                        token={this.state.token}
                        updateIncidentTag={this.updateIncidentTag}
                        removeIncidentTag={this.removeIncidentTag}
                        removeGroup={this.removeGroup}
                        removeFacilityGroup={this.removeFacilityGroup}
                        updateFacilityGroup={this.updateFacilityGroup}
                        storageLimit={this.state.storageLimit}
                      />
                    )}
                  />
                </Switch>
              ) : (
                <Switch>
                  <Route
                    path="/"
                    render={props => (
                      <LoginPage
                        {...props}
                        loading={this.state.loadingForm}
                        handleAuthUser={this.handleAuthUser}
                      />
                    )}
                  />
                </Switch>
              )}
          </React.Fragment>
        }
        </div>
      </BrowserRouter>
    );
  }
}
export default withTranslation()(App);