import React from "react";

import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';

const EditPlaceListDialogNavbar = ({ handleClose, selectedPlace }) => {
  const splitDateTime = date => {
    let replaceDate = date.replace(/\)/gi, "),");
    let splitDate = replaceDate.split(",");

    return splitDate.map(date => (
      <Typography>{date}</Typography>
    ));
  };

  const { t } = useTranslation();
  
  return (
    <>
    <div className="place-id-info"><span>{selectedPlace.placeId}</span></div>
    <div className="edit-place-nav-container">
      <div className="separate-nav-wrap">
        <Button variant="contained" onClick={handleClose} className="back-btn">
          <ArrowBack />
          {t("Back")}
        </Button>
        <div className="edit-place-info-container">
          <div className="edit-place-info">
            <div className="info-text-wrap user">
              <Typography>{t("Username")}</Typography>
              <Typography>{selectedPlace.user}</Typography>              
            </div>
          </div>
          <div className="edit-place-info">
            <div className="info-text-wrap date">
              <Typography>{t("Created At")}</Typography>
              {/* <Typography>{selectedPlace.createTime}</Typography> */}
              {splitDateTime(selectedPlace.createTime)}
            </div>
          </div>
          <div className="edit-place-info">
            <div className="info-text-wrap date">
              <Typography>{t("Updated At")}</Typography>
              {/* <Typography>{selectedPlace.updateTime}</Typography> */}
              {splitDateTime(selectedPlace.updateTime)}            
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default EditPlaceListDialogNavbar;
