import React from "react";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

import "../styles/screen-builder.scss";
import MonitorIcon from "../images/monitor-icon.png";
import PlayIcon from "../images/play.svg";
import PersonIcon from "@material-ui/icons/Person";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import AlertSnackbar from "./AlertSnackbar";
import OpenViduVideoComponent from './OpenViduVideoComponent';
import StorageVideoComponent from './StorageVideoComponent'
import { withTranslation } from 'react-i18next';
import broadcastIcon from "../images/connecting.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuteIcon from '@material-ui/icons/VolumeOff';
import UnMuteIcon from '@material-ui/icons/VolumeUp';
import { ReactComponent as HazardLiveLogo } from '../images/HazardLiveLogo.svg';
import Typography from "@material-ui/core/Typography";
import Loader from "react-loader-spinner";
import moment from "moment";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";

import axios from 'axios';
import { OpenVidu } from 'openvidu-browser';

import "moment/locale/ja";


const hasLevelName = (levelName, customName, t) => {
  let hazardName;
  switch (levelName) {
    case "緊急度1":
      hazardName = customName? customName : t("Alert 4");
      return hazardName;
    case "緊急度2":
      hazardName = customName? customName : t("Alert 3");
      return hazardName;
    case "緊急度3":
      hazardName = customName? customName : t("Alert 2");
      return hazardName;
    case "緊急度4":
      hazardName = customName? customName : t("Alert 1");
      return hazardName;
    default:
      break;
  }
};


class MonitorModeScreen extends React.PureComponent {
   constructor(props) {
    super(props);
    this.state = {
      open: false,
      openBackgroundBar: false,
      isMounting: false,
      dragStopped: false,
      hasError: false,
      isType: "error",
      errorMessage: "",
      mainStreamManager: undefined,
      publisher: undefined,
      joinedSession: false,
      activeStreams: [
      {
        monitor: 0,
        place: null,
        socket: null,
        subscribers: [],
        mute: true,
        rotateVideo: 0,
        session: undefined
      },
      {
        monitor: 1,
        place: null,
        socket: null,
        mute: true,
        rotateVideo: 0,
        subscribers: [],
        session: undefined
      },
      {
        monitor: 2,
        place: null,
        socket: null,
        mute: true,
        rotateVideo: 0,
        subscribers: [],
        session: undefined
      },
      {
        monitor: 3,
        place: null,
        socket: null,
        mute: true,
        rotateVideo: 0,
        subscribers: [],
        session: undefined
      },
      {
        monitor: 4,
        place: null,
        socket: null,
        mute: true,
        rotateVideo: 0,
        subscribers: [],
        session: undefined
      },
      {
        monitor: 5,
        place: null,
        socket: null,
        mute: true,
        rotateVideo: 0,
        subscribers: [],
        session: undefined
      }
      ],
    };
    this.joinSession = this.joinSession.bind(this);
    this.leaveSession = this.leaveSession.bind(this);
  }

  joinSession = (sessionId, userId, monitorIndex) => {

    if(sessionId && userId && monitorIndex >= 0 && this.props.parentGroup) {
        // --- 1) Get an OpenVidu object ---
      this.OV = new OpenVidu();
      this.OV.enableProdMode();
      this.setState(prevState => {
        console.log(monitorIndex);
        if(monitorIndex === 0) {
          var newState = {
          ...prevState,
          activeStreams: [
            {
              ...prevState.activeStreams[monitorIndex],
              session: this.OV.initSession(),
              sessionIsLoading: true,
              subscribers: [],
            },
            ...prevState.activeStreams.slice(monitorIndex + 1),
          ]
          }
        } else {
          var newState = {
          ...prevState,
          activeStreams: [
            ...prevState.activeStreams.slice(0, monitorIndex),
            {
              ...prevState.activeStreams[monitorIndex],
              session: this.OV.initSession(),
              sessionIsLoading: true,
              subscribers: [],
            },
            ...prevState.activeStreams.slice(monitorIndex + 1),
          ]
          }
        }
        return (newState)
      }
      , async() => {
          var mySession = this.state.activeStreams[monitorIndex].session;
          // --- 3) Specify the actions when events take place in the session ---
          // On every new Stream received...
          mySession.on('streamCreated', (event) => {
            // Subscribe to the Stream to receive it. Second parameter is undefined
            // so OpenVidu doesn't create an HTML video by its own
            var subscriber = mySession.subscribe(event.stream, undefined);
            var subscribers = this.state.activeStreams[monitorIndex].subscribers;
            subscribers.push(subscriber);
            // Update the state with the new subscribers
            this.setState(prevState => ({
              ...prevState,
              activeStreams: [
                ...prevState.activeStreams.slice(0, monitorIndex),
                {
                  ...prevState.activeStreams[monitorIndex],
                  subscribers: subscribers,
                  mute: true,
                  rotateVideo: 0,
                  sessionIsLoading: false,
                },
                ...prevState.activeStreams.slice(monitorIndex + 1),
              ]})
            );
          });//end streamCreated

          // On every Stream destroyed...
          mySession.on('streamDestroyed', (event) => {
            event.preventDefault(); //Call event.preventDefault() upon event sessionDisconnected to avoid this behavior and take care of disposing and cleaning all the Subscriber objects yourself. 
            // Remove the stream from 'subscribers' array
            if(this.state.activeStreams[monitorIndex].session && event.stream.videoActive) {
              this.state.activeStreams[monitorIndex].session.disconnect();
              this.deleteSubscriber(event.stream.streamManager, monitorIndex);
            }
          });

          // On every asynchronous exception...
          mySession.on('exception', (exception) => {
            console.warn(exception);
          });

          // --- 4) Connect to the session with a valid user token ---
          try {
            console.log("attempting to create session");

            var data = JSON.stringify({sessionId: sessionId, userCompanyGroup: this.props.parentGroup.id});
            var resToken = await axios.post('https://livego-api.mmdev.jp/openvidu-view', data,{
              headers: {
                'Content-Type': 'application/json',
              },
            });

            if(resToken && resToken.data && resToken.data.token) {
              const token = resToken.data.token;
              mySession.connect(token,{ clientData: userId }).then(async () => {
                var devices = await this.OV.getDevices();
                var videoDevices = devices.filter(device => device.kind === 'videoinput');
                // --- 5) Get your own camera stream ---
                // Init a publisher passing undefined as targetElement (we don't want OpenVidu to insert a video
                // element: we will manage it on our own) and with the desired properties
                let publisher = await this.OV.initPublisherAsync(undefined, {
                    audioSource: undefined, // The source of audio. If undefined default microphone
                    videoSource: undefined, // The source of video. If undefined default webcam
                    publishAudio: false, // Whether you want to start publishing with your audio unmuted or not
                    publishVideo: false, // Whether you want to start publishing with your video enabled or not
                    resolution: '640x480', // The resolution of your video
                    frameRate: 30, // The frame rate of your video
                    insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
                    mirror: false, // Whether to mirror your local video or not
                });
                // --- 6) Publish your stream ---
                mySession.publish(publisher);
                // Set the main video in the page to display our webcam and store our Publisher
                this.setState({
                  currentVideoDevice: videoDevices[0],
                  mainStreamManager: publisher,
                  publisher: publisher,
                });
              }).catch((error) => {
                console.log("connect error via mySession = error below")
                console.log(error)
                console.log('There was an error connecting to the session:', error.code, error.message);
              });
            } else {
              console.log("Error ResToken");
            }
          } catch (e) {
            console.log("app side")
            console.log(e);
          }
        });
      } else {
        // one of these was missing sessionId && userName && monitorIndex && this.props.parentGroup
        console.log("missing param sessionId or userName or monitorIndex or this.props.parentGroup");
      }
    }
  

    deleteSubscriber = (streamManager, monitorIndex) => {
      let monitor = this.state.activeStreams[monitorIndex];
      this.removeFromMonitor(monitorIndex, monitor.place, false, monitor);
    }

    toggleMuteLiveStream = (monitorIndex) => {
      this.setState(prevState => {
          const newActiveStreams = [...prevState.activeStreams];
          newActiveStreams[monitorIndex] = {
              ...newActiveStreams[monitorIndex],
              mute: !newActiveStreams[monitorIndex].mute
          };
          return { ...prevState, activeStreams: newActiveStreams };
      });
    };

    handleRotate = (direction, currentRotate, monitorIndex) => {
      var newRotate = null;

      if (direction === "left") {
        newRotate = currentRotate - 90;
      } else {
        newRotate = currentRotate + 90;
      }
  
      if (newRotate > 360) {
        newRotate = -90;
      } else if (newRotate < -360) {
        newRotate = 90;
      }

      this.setState(prevState => {
          const newActiveStreams = [...prevState.activeStreams];
          newActiveStreams[monitorIndex] = {
              ...newActiveStreams[monitorIndex],
              rotateVideo: newRotate
          };
          return { ...prevState, activeStreams: newActiveStreams };
      });

    };

    leaveSession(sessionId, monitorIndex) {

        // --- 7) Leave the session by calling 'disconnect' method over the Session object ---

        const mySession = this.state.activeStreams[monitorIndex].session;

        if (mySession) {
          mySession.disconnect();
        }

        // Empty all properties...
        this.OV = null;

        this.setState(prevState => ({
        ...prevState,
        activeStreams: [
          ...prevState.activeStreams.slice(0, monitorIndex),
          {
            ...prevState.activeStreams[monitorIndex],
            place: null,
            socket: null,
            is_live_stream: null,
            session: undefined,
            subscribers: [],
          },
          ...prevState.activeStreams.slice(monitorIndex + 1),
        ]}), () => {
             //need to check if im still streaming as a producer or not before leaving publisher info
          const stillStreamingLive = this.state.activeStreams.some((s) => s.is_live_stream === true);
          if(stillStreamingLive) {
            //don't do anything we are still streaming
          } else {
            this.setState({
              mainStreamManager: undefined,
              publisher: undefined,
              joinedSession: false
            });
          }
        });

        console.log("left the session");
    }

  setupWebRTCStream = (place, monitorIndex) => {
    this.joinSession(place, this.props.user.id, monitorIndex)
  };


  handleClick = (e) => {
    e.preventDefault();
    clearTimeout(this.timer);
    this.props.handleScreenBuilderOpen();
    this.props.handlePropOpen();
    this.setState(prevState => ({
      open: !prevState.open,
      openBackgroundBar: !prevState.openBackgroundBar,
    }), () => {
      this.timer = setTimeout(() => {
        this.calculateMonitorBounds();
      }, 1000);
    });
  };

  componentDidMount() {
    this.calculateMonitorBounds();
    window.addEventListener("resize", this.calculateMonitorBounds);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    window.removeEventListener("resize", this.calculateMonitorBounds);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.dragEnd !== prevProps.dragEnd) {
      this.checkDragLocation();
    }
  }

  calculateMonitorBounds = () => {
    clearTimeout(this.timer);
    // looking for rage for x and y to land in
    // x start, x end, y start, y end
    var monitors = [];
    this.state.activeStreams.forEach((monitor, index) => {
      var bounds = this[`monitorScreen_${index}`].getBoundingClientRect();
      monitors[index] = {
        monitor: monitor.monitor,
        place: monitor.place,
        socket: monitor.socket,
        is_live_stream: monitor.is_live_stream,
        subscribers: monitor.subscribers,
        mute: monitor.mute,
        rotateVideo: monitor.rotateVideo,
        sessionIsLoading: monitor.sessionIsLoading,
        session: monitor.session,
        xs: bounds.left,
        xe: bounds.right,
        ys: bounds.top,
        ye: bounds.bottom,
      }
    });
    this.setState({
      activeStreams: monitors,
    });
  };

  checkDragLocation = () => {
    var dragToPoint = this.props.dragEnd;
    var place = this.props.draggedPlace;
    const {t} = this.props;
    // check if the point is within range of any of the monitors?
    //THROW ERROR IF MONITOR IS ALREADY THERE!
    var hasMonitorAlready;
    var isLiveStream = (typeof place === 'string' || place instanceof String) && place.includes("-LIVE-");
    if(isLiveStream) {
      //before we checked if it had a room id, but in this new case we only have a string of the place id
      hasMonitorAlready = this.state.activeStreams.some((mon) => mon.is_live_stream && mon.place === place);
    } else {
      hasMonitorAlready = this.state.activeStreams.some((mon) => mon.place && mon.place.placeId === place.placeId);
    }

    if(hasMonitorAlready) {
      console.log("alert");
      this.setState({ 
        hasError: true, 
        isType: "error",
        errorMessage: t("This monitor is already mounted"),
      });
    } else {
      this.state.activeStreams.forEach((monitor, index) => {
        var withinRange = monitor.xs <= dragToPoint.x && dragToPoint.x <= monitor.xe && monitor.ys <= dragToPoint.y && dragToPoint.y <= monitor.ye;
        if(withinRange) {
          this.setMonitorPlace(monitor, place, isLiveStream);
        }
      });
    }
  };

  setMonitorPlace = (monitor, place, isLiveStream) => {
    const {t} = this.props;

    if(isLiveStream) {
      const hasMonitorAlready = this.state.activeStreams.some((mon) => mon.is_live_stream && mon.place === place);
      if(hasMonitorAlready) {
        this.setState({ 
          hasError: true, 
          isType: "error",
          errorMessage: t("This monitor is already mounted"),
        });
        return;
      }
    }
    
    this.setState(prevState => (
      {
      ...prevState,
      activeStreams: [
        ...prevState.activeStreams.slice(0, monitor.monitor),
        {
          monitor: prevState.activeStreams[monitor.monitor].monitor,
          place: place,
          is_live_stream: isLiveStream,
          socket: prevState.activeStreams[monitor.monitor].socket,
          session: null,
          mute: true,
          rotateVideo: 0,
          subscribers: [],
          sessionIsLoading: prevState.activeStreams[monitor.monitor].sessionIsLoading,
          xs: prevState.activeStreams[monitor.monitor].xs,
          xe: prevState.activeStreams[monitor.monitor].xe,
          ys: prevState.activeStreams[monitor.monitor].ys,
          ye: prevState.activeStreams[monitor.monitor].ye,
        },
        ...prevState.activeStreams.slice(monitor.monitor + 1),
      ]
    }), () => {
      // this is where we check if we need a websocket monitor join or not
      // [TODO] we have a timeout here as a patch because we couldn't get the timing right on the mounting of the place vs here where we call to connect to webrtc streaming 
      if(isLiveStream) {
        //need index of place before setting up webrtc

        const indexOfLiveStream = this.state.activeStreams.findIndex((s) => s.is_live_stream && (s.place === place));
        this.setupWebRTCStream(place, indexOfLiveStream);
      }

    });
  };

  removeFromMonitor = (monitor, place, isHazardCam, stream) => {
    if(stream.is_live_stream) {
      this.leaveSession(stream.place, monitor);
    } else {
       this.setState(prevState => ({
        ...prevState,
        activeStreams: [
          ...prevState.activeStreams.slice(0, monitor),
          {
            monitor: prevState.activeStreams[monitor].monitor,
            place: null,
            socket: prevState.activeStreams[monitor].socket,
            subscribers: [],
            mute: true,
            rotateVideo: 0,
            xs: prevState.activeStreams[monitor].xs,
            xe: prevState.activeStreams[monitor].xe,
            ys: prevState.activeStreams[monitor].ys,
            ye: prevState.activeStreams[monitor].ye,
          },
          ...prevState.activeStreams.slice(monitor + 1),
        ]
      }));


      if(isHazardCam) {
        //distory player in memory
        this[place.place_id].player.destroy();
        this[place.place_id] = null;

        //leave room
        console.log("leave room");

        this.props.leaveRoomHazardCam(place.place_id);
        this.props.hcSocket.off('videoStreamData');
      }
    }
  };

  removeAllFromMonitor = (e) => {
    e.preventDefault();
    const resetActiveStreams = [];

    this.state.activeStreams.forEach(stream => {

      if (stream.is_live_stream) {
        let currentSession = stream.session;
        if (currentSession) {
          currentSession.disconnect();
        }
      }

      if(stream.place && this[stream.place.place_id] && this[stream.place.place_id].player) {
        // hazardcam is running, run shutdown
        this[stream.place.place_id].player.destroy();
        this[stream.place.place_id] = null;
        this.props.leaveRoomHazardCam(stream.place.place_id);
        this.props.hcSocket.off('videoStreamData');
      }

      const resetStream = {
        monitor: stream.monitor,
        place: null,
        socket: stream.socket,
        subscribers: [],
        mute: true,
        rotateVideo: 0,
        session: undefined,
        xs: stream.xs,
        xe: stream.xe,
        ys: stream.ys,
        ye: stream.ye,
      };

      resetActiveStreams.push(resetStream);
    });
    this.OV = null;
    this.setState({
      activeStreams: resetActiveStreams,
      mainStreamManager: undefined,
      publisher: undefined,
      joinedSession: false
    });
  };

  setupStream = (ref, roomId) => {
    //only setup if its not already setup!
    if(this[roomId]) {
      console.log("Stream has already begun");
    } else {
      this[roomId] = ref;
      if(ref) {
        this.props.joinRoomHazardCam(roomId);
        this[roomId].player =  new window.JMuxer({
            node: ref,
            mode: 'video',
            flushingTime: 1000,
            fps: 15,
            debug: false,
            onError: function(data) {
              console.log('Buffer error encountered', data);
            }
        })
        this.props.hcSocket.on('videoStreamData', (data) => {
          if(this[roomId] && this[roomId].player) {
            this[roomId].player.feed({
               video: new Uint8Array(data.buffer)
            });
          }
        });
      }
    }

  };


  dispose = (place) => {
    if(this[place.producer]) {
      this.props.leaveRoom(place.roomId);
      this[place.producer].dispose();
      this[place.producer] = null;
      // we also need to remove the listerners so they stop bubbling events and clear icecanidates.
      // This might be from the signal server
      // this.props.socket.on('viewerResponse'
      // this.props.socket.on('joinSuccessViewer'
      // this.props.socket.on('iceCandidate'
      // this.props.socket.on('stopCommunication'
    }
  };

  onErrorRTC = error => {
    console.log(error);
  };

  handleErrorAlertClick = () => {
    //hack!!!
    setTimeout(() => {
      this.setState({ hasError: false });
    }, 3000)
  };

  hasLevelNumber = (levelName) => {
    switch (levelName) {
      case "大至急！":
        return "1";
      case "至急":
        return "2";
      case "通常":
        return 3;
      case "参考":
        return 4;
      default:
        break;
    }
  };

  displayHazardCamMonitor = (stream) => {
    const {t} = this.props;
    return(
      <>
      <Button className="remove-from-monitor" onClick={() => this.removeFromMonitor(stream.monitor, stream.place, true, stream)}>
      <CloseIcon />
      </Button>
      <div className="hazard-level-label list-label-hazard ">
      <span
        className={`hazard-level-${stream.is_live_stream? 1:this.hasLevelNumber(stream.place.hazardLevel)}`}
      >
      {stream.is_live_stream? t("Alert 4"):stream.place.hazardLevel}
      </span>
      </div>
      <div className="stream-video-wrap canvas-stream-wrap">
        <video
          autoPlay
          controls
          className="hazardcam-monitor-livestream"
          ref={hcbox => this.setupStream(hcbox, stream.place.place_id)}
        ></video>
        <div className="stream-loading-monitor">
          <Loader 
            type="TailSpin" 
            color="#f70d4e" 
            height={35}
            width={35}
          />
        </div>
      </div>
      <div className="livestream-monitor-place">
        <div className="is-live-tag-list monitor-tag">LIVE</div>
        <Typography variant="h4" className="place-list-item-group-name">
          {stream.place.groupName}
        </Typography>
        <div className="place-list-item-username-container">
          <PersonIcon />
          <Typography variant="h5" className="username">
            {stream.place.user? stream.place.user:
            stream.place.username? stream.place.username:null
            }
          </Typography>
        </div>
      <div className="place-list-item-date">
        <Typography variant="h3">
        {moment(stream.place.create_time).format("YYYY/MM/DD (ddd) HH:mm")}
        </Typography>
      </div>
      </div>
      </>
    )
  }

  setupSubVideo = (streamManager, stream) => {
    if(streamManager) {
      streamManager.addVideoElement(this[stream.place]);
    }
  }

  hasLevelIntString = (levelInt, t) => {
    switch (levelInt) {
      case "1":
        return t("Alert 4");
      case "2":
        return t("Alert 3");
      case "3":
        return t("Alert 2");
      case "4":
        return t("Alert 1");
      default:
        break;
    }
  };


  render() {
    const {t} = this.props;
    return (
      <div className={this.state.open && !this.props.openSidebar? "hazard-live-screen-builder opened-builder expand" : this.state.open? "hazard-live-screen-builder opened-builder ":"hazard-live-screen-builder" }>
        
        <div className="portal-wrap">
          <AlertSnackbar
            isType={this.state.isType}
            isOpen={this.state.hasError}
            handleClick={this.handleErrorAlertClick}
            message={this.state.errorMessage}
          />
        </div>

        <div className="monitor-mode-navbar">
          <div className="logo">
            <HazardLiveLogo />
          </div>
          <Button className="close-monitor" onClick={(e) => { this.handleClick(e); this.removeAllFromMonitor(e); }}>
            <CloseIcon />
          </Button>
        </div>

        <div className="monitor-grid-container">
          <React.Fragment>
            {this.state.activeStreams.map((stream, index) =>
              <div key={`monitor_screen_${index}`} className={stream.place? "monitor has-place": "monitor"} ref={ (monitorScreen) => this[`monitorScreen_${stream.monitor}`] = monitorScreen} >

                {stream && stream.place && stream.place.hazardCam?
                  <>
                    {this.displayHazardCamMonitor(stream)}
                  </>
                  :<>
                
                  {stream.place?
                  <React.Fragment>

                    <Button className="remove-from-monitor" onClick={() => this.removeFromMonitor(stream.monitor, stream.place, false, stream)}>
                      <CloseIcon />
                    </Button>

                    <div className="hazard-level-label list-label-hazard ">

                    {stream.is_live_stream && this.props.openViduStreamPlaces && stream.place && this.props.openViduStreamPlaces[stream.place]?
                      <>

                        <span className={`hazard-level-${this.props.openViduStreamPlaces[stream.place].tags[0].id.split("-")[this.props.openViduStreamPlaces[stream.place].tags[0].id.split("-").length - 1]}`}>
                          {this.hasLevelIntString(this.props.openViduStreamPlaces[stream.place].tags[0].id.split("-")[this.props.openViduStreamPlaces[stream.place].tags[0].id.split("-").length - 1], t)}
                        </span>


                      </>
                      :(<>{stream.place.producer?
                        <span
                          className={`hazard-level-${this.hasLevelNumber(stream.place.hazardLevel)}`}
                        >
                        {stream.place.hazardLevel}
                        </span>:
                        <span
                          className={`hazard-level-${
                            stream.place.hazardLevel.name.split("")[stream.place.hazardLevel.name.length - 1]
                          }`}
                        >
                        {hasLevelName(stream.place.hazardLevel.name, stream.place.hazardLevel.customName, t)}
                        </span>}</>)}


                    </div>

                    {stream.place.isHazardCam?
                      <div className="stream-video-wrap canvas-stream-wrap">
                        <canvas
                          className="hazardcam-monitor-livestream"
                          ref={hcbox => (this[stream.place.placeId] = hcbox)}
                        ></canvas>
                        <div className="stream-loading-monitor">
                          <Loader 
                            type="TailSpin" 
                            color="#f70d4e" 
                            height={35}
                            width={35}
                          />
                        </div>
                        {this.setupStream(stream.place.placeId)}
                      </div>: stream.is_live_stream ?
                      <>

                      <div className="stream-video-wrap webrtc-stream-wrap">
                        
                        {this.state.activeStreams[stream.monitor].sessionIsLoading?
                          <div className="connecting-to-live-stream">
                            <CircularProgress style={{color: "red"}}/>
                            <span><img src={broadcastIcon} />{t("Connecting to live stream...")}</span>
                            {stream.place?
                            <div onClick={() => this.removeFromMonitor(stream.monitor, stream.place, false, stream)} className="cancel-livestream-connection-btn">
                              {t("Cancel")}
                            </div>:null}
                          </div>
                        :null}

                        {this.state.activeStreams[stream.monitor].session !== undefined ? (

                            <div className="video-container-monitor-livego">
                                {this.state.activeStreams[stream.monitor] && 
                                this.state.activeStreams[stream.monitor].subscribers && 
                                this.state.activeStreams[stream.monitor].subscribers.length > 0 ?
                                    (() => {
                                        // Filter the subscribers to find those with video active
                                        const activeSubscribers = this.state.activeStreams[stream.monitor].subscribers.filter(subscriber => subscriber.stream.videoActive);
                                        
                                        // Check if there are any active subscribers
                                        if (activeSubscribers.length > 0) {
                                            // Get the first active subscriber
                                            const activeSubscriber = activeSubscribers[0];
                                            return (
                                                <div key={`${stream.place}`} className="stream-container" >
                                                    <OpenViduVideoComponent
                                                      rotateVideo={this.state.activeStreams[stream.monitor].rotateVideo}
                                                      isMuted={this.state.activeStreams[stream.monitor].mute}
                                                      subscriber={activeSubscriber} />
                                                    <div className="live-stream-monitor-controls">
                                                    <Button
                                                      variant="contained"
                                                      color="secondary"
                                                      onClick={() =>
                                                        this.handleRotate("left", this.state.activeStreams[stream.monitor].rotateVideo, stream.monitor)
                                                      }
                                                      className="edit-button rotate rotate-left"
                                                    >
                                                      <RotateLeftIcon />
                                                    </Button>

                                                    <Button
                                                      variant="contained"
                                                      color="secondary"
                                                      onClick={() =>
                                                        this.handleRotate("right", this.state.activeStreams[stream.monitor].rotateVideo, stream.monitor)
                                                      }
                                                      className="edit-button rotate rotate-right"
                                                    >
                                                      <RotateRightIcon />
                                                    </Button>

                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() =>
                                                          this.toggleMuteLiveStream(stream.monitor)
                                                        }
                                                        className={`${this.state.activeStreams[stream.monitor].mute? 'sound-off': 'sound-on'}`}
                                                      >
                                                        {this.state.activeStreams[stream.monitor].mute?
                                                        <MuteIcon />: <UnMuteIcon />}
                                                      </Button>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null; // No active subscribers
                                        }
                                    })()
                                : null}
                            </div>


                          ) : null}


                        <div className="stream-loading-monitor">
                          <Loader 
                            type="TailSpin" 
                            color="#f70d4e" 
                            height={35}
                            width={35}
                          />
                        </div>
                      </div>

                      </>
                      :
                      <>
                        {stream.place.video?
                         <div className="video-container-monitor-livego is-storage">
                            <StorageVideoComponent
                              controls={true}
                              videoSrc={stream.place.video}
                              isMuted={this.state.activeStreams[stream.monitor].mute}
                              rotateVideo={this.state.activeStreams[stream.monitor].rotateVideo}
                            />
                              
                          </div>:
                          <div className="storage-video-is-processing-monitor"></div>
                        }
                      </>
                  }

                  <div className="livestream-monitor-place">

                    {stream.place.video?
                     <div className="storage-stream-monitor-controls">
                              
                     <Button
                       variant="contained"
                       color="secondary"
                       onClick={() =>
                         this.handleRotate("left", this.state.activeStreams[stream.monitor].rotateVideo, stream.monitor)
                       }
                       className="edit-button rotate rotate-left"
                     >
                       <RotateLeftIcon />
                     </Button>

                     <Button
                       variant="contained"
                       color="secondary"
                       onClick={() =>
                         this.handleRotate("right", this.state.activeStreams[stream.monitor].rotateVideo, stream.monitor)
                       }
                       className="edit-button rotate rotate-right"
                     >
                       <RotateRightIcon />
                     </Button>
                     </div>
                    :null}

                    {stream.place.producer || stream.is_live_stream?
                      <div className="is-live-tag-list monitor-tag">LIVE</div>:
                      <div className="is-storage-tag-list monitor-tag">{t("Storage")}</div>
                    }

                    <Typography variant="h4" className="place-list-item-group-name">
                      {stream.is_live_stream && this.props.openViduStreamPlaces && stream.place && this.props.openViduStreamPlaces[stream.place]?
                      this.props.openViduStreamPlaces[stream.place].customData["group.name"] :
                      stream.place.groupName}
                    </Typography>
                    
                    <div className="place-list-item-username-container">
                      <PersonIcon />
                      <Typography variant="h5" className="username">
                        {stream.is_live_stream && this.props.openViduStreamPlaces && stream.place && this.props.openViduStreamPlaces[stream.place]?
                        this.props.openViduStreamPlaces[stream.place].customData["user.name"]: stream.place.user? stream.place.user:
                        stream.place.username? stream.place.username:null
                        }
                      </Typography>
                    </div>
                    <Typography variant="h5" className="monitor-title-livestream">
                      {stream.is_live_stream && this.props.openViduStreamPlaces && stream.place && this.props.openViduStreamPlaces[stream.place]? this.props.openViduStreamPlaces[stream.place].shortDescription:stream.place.comment}
                    </Typography>
                    <div className="place-list-item-date">
                      <Typography variant="h3">{stream.is_live_stream? "LIVE":stream.place.createTime}</Typography>
                    </div>
                  </div>

                  </React.Fragment>:
                  <img src={PlayIcon} alt={"HazardPhoto"} key={`streamer-none-${stream.monitor}`} className="play-icon" />
                }
                </>}
              </div>
            )}
          </React.Fragment>
        </div> 


        <div className={`${this.state.openBackgroundBar? 'livebar opened': 'livebar'}`}>
          <div className="livebar-hook">
            <Typography variant="h5">{t("Drag and drop videos from the list on the right to mount a monitor.")}</Typography>
          </div>
          <div className="live-screen-side-bar-actions">
            <Button onClick={()=> this.props.handleOpenSidebar()}className={`${this.props.openSidebar? 'sidebar-toggle-btn opened': 'sidebar-toggle-btn'}`}><ChevronLeft /></Button>
          </div>
        </div>

        <Button className={`${this.state.openBackgroundBar? 'monitor-dropdown-btn opened': 'monitor-dropdown-btn'}`} onClick={(e) => this.handleClick(e)}>
          <img src={MonitorIcon} alt={"Monitor Icon"} />
          {t("Monitor Mode")}
        </Button>

      </div>
    );
  }

}

export default withTranslation()(MonitorModeScreen);
