import React from "react";
import Loader from "react-loader-spinner";

import Dropzone from "react-dropzone";
import classNames from "classnames";

import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Typography from "@material-ui/core/Typography";

import ArrowIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import { withTranslation } from 'react-i18next';

import "../styles/uploader.scss";

class HazardPhotoUploader extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="state-of-upload-title">
          {this.props.uploading ? (
            <Typography>{t("Loading...")}</Typography>
          ) : (
            <Typography>{t("Add Photo")}</Typography>
          )}
        </div>
        {this.props.uploading ? (
          <div>
            {this.props.acceptedFile ? (
              <div>
                <div className="uploading-from-dropzone">
                  <div className="photo-uploading-button-icon">
                    <div className="uploading-started">
                      <div className="photo-upload-started">
                        <Loader type="TailSpin" color="#90ffc8" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
        ) : (
          <Dropzone onDrop={this.props.onDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div
                  {...getRootProps()}
                  className={classNames("dropzone", {
                    "dropzone--isActive": isDragActive
                  })}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <div>
                      <Button className="upload-start-button-icon uploading-drop-photo">
                        <div className="uploading-drop">
                          <CloudUploadIcon className="drop-upload-icon" />
                          {t("Add Photo")}
                        </div>
                      </Button>
                      <Typography
                        variant="caption"
                        className="upload-photo-info"
                      >
                        <ArrowIcon className="rotate-arrow" />
                       {t("Add Photo")}
                      </Typography>
                    </div>
                  ) : (
                    <div>
                      <Button className="upload-start-button-icon">
                        <CloudUploadIcon className="drop-upload-icon" />
                      </Button>
                      <Typography
                        variant="caption"
                        className="upload-photo-info"
                      >
                        <ArrowIcon className="rotate-arrow" />
                        {t("Drop or click to select an image")}
                      </Typography>
                    </div>
                  )}
                </div>
              );
            }}
          </Dropzone>
        )}
      </div>
    );
  }
}

export default withTranslation()(HazardPhotoUploader);
