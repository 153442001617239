import React, { useState } from 'react';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { useTranslation } from 'react-i18next';

const PrintViewModeSelect = ({ handlePrintViewMode }) => {
  const [value, setValue] = useState("single");
  const {t} = useTranslation();

  const handleChange = event => {
    let selectedMode = event.target.value;
    setValue(selectedMode);
    handlePrintViewMode(selectedMode);
  };

  return (
    <FormControl style={{ marginTop: "10px" }} component="fieldset">
      <FormLabel component="legend">{t("Select a print mode")}</FormLabel>
      <RadioGroup 
        row 
        aria-label="print-view-mode" 
        name="print-view-mode" 
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel
          value="single"
          control={<Radio color="primary" />}
          label={t("Large Print")}
          labelPlacement="end"
          style={{ marginLeft: "0px" }}
        />
        <FormControlLabel
          value="multi"
          control={<Radio color="primary" />}
          label={t("Grid Print")}
          labelPlacement="end"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default PrintViewModeSelect;