import React from "react";
import Loader from "react-loader-spinner";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';

const AddPublicPlaceDialog = ({ open, close, handlePublicPlace, selectedPlace, loadingPublicPlace }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={close}
      className={`make-public-modal ${selectedPlace.isPublic? "single-header": ""}`}
    >
      {selectedPlace.isPublic?
      <Typography variant="h5" className="private-public-info-header">
        {t("Keep this place information private?")}
      </Typography>:
      <div>
        <Typography variant="h5" className="private-public-info-header">
          {t("Make this place information public?")}
        </Typography>
        <Typography variant="h5" className="private-public-info-header">
          {t("Externally disclosed data will be made publicly available.")}
        </Typography>
      </div>}
      <div className="make-public-buttons">
        <Button
          variant="contained"
          color="primary"
          className="close-button"
          onClick={close}
        >
          {t("Back")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="confirm-button"
          onClick={handlePublicPlace}
        >
          {t("Confirm")}
          {loadingPublicPlace?
          <div>
            <Loader 
              type="TailSpin" 
              color="white" 
              height={15}
              width={15}
            />
          </div>:null
          }
        </Button>
      </div>
    </Dialog>
  );
};

export default AddPublicPlaceDialog;
