import React from "react";

import PersonIcon from "@material-ui/icons/Person";
import BusinessIcon from "@material-ui/icons/Business";
import Typography from "@material-ui/core/Typography";


const UserInfoBar = (props) => {

  return (
    <div className="user-info-bar">
      <div className="user-info-container">
        <BusinessIcon className="user-icon" />
        <Typography>{props.parentGroup.name}</Typography>
      </div>
      <div className="user-info-container">
        <PersonIcon className="user-icon" />
        <Typography>{props.user.name? props.user.name: props.user.id}</Typography>
      </div>
    </div>
  )
};

export default UserInfoBar;