import React from "react";

import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import ArrowBack from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from 'react-i18next';

const addButtonText = (tag, hasFacilities, t) => {

  switch (tag) {
    case t("Devices"):
      return t("New Group");
    case t("Facility Information"):
      if (hasFacilities) {
        return t("New Facility")
      } else {
        return t("New Category");
      }
    case t("Admin Comment"):
      return t("New Comment");
    case t("Incident"):
      return t("New Incident");
    default:
      break;
  }
};

const renderDialogPage = (
  hasFilterForm,
  toggleFilterForm,
  hasFacilities,
  toggleFacilityPlaces,
  handleClose
) => {
  if (hasFilterForm) {
    toggleFilterForm();
  } else if (hasFacilities) {
    toggleFacilityPlaces();
  } else {
    handleClose();
  }
};

const submitButtonText = (tag, editing, hasFacilities, t) => {
  if (editing) {
    switch (tag) {
      case t("Devices"):
        return t("Save Group Information");
      case t("Facility Information"):
        return t("Save Facility Information");
      case t("Admin Comment"):
        return t("Save Comment");
      case t("Incident"):
        return t("Save Incident");
      default:
        break;
    }
  } else {
    switch (tag) {
      case t("Devices"):
        return t("New Group");
      case t("Facility Information"):
        if (hasFacilities) {
          return t("New Facility")
        } else {
          return t("New Category");
        }
      case t("Admin Comment"):
        return t("New Comment");
      case t("Incident"):
        return t("New Incident");
      default:
        break;
    }
  }
  };

const FilterDialogNavbar = ({
  hasFilterForm,
  toggleFilterForm,
  hasFacilities,
  toggleFacilityPlaces,
  handleClose,
  openAddForm,
  handleOpenDelete,
  editing,
  tag
}) => {
  const { t } = useTranslation();

  return (
    <div className="dialog-nav-container">
      <Button
        variant="contained"
        style={{ color: "white", backgroundColor: "#0D6AAD" }}
        onClick={() =>
          renderDialogPage(
            hasFilterForm,
            toggleFilterForm,
            hasFacilities,
            toggleFacilityPlaces,
            handleClose
          )
        }
      >
        <ArrowBack />
        {t("Back")}
      </Button>
      <div>
        {editing?
          <Button 
            variant="contained" 
            color="secondary"
            onClick={() => handleOpenDelete()}
            style={{ marginRight: "10px" }}             
          >
            <DeleteIcon />
            {t("Delete")}
          </Button>
        : null}
        {hasFilterForm ? (
          <Button
            form="filter-dialog-form"
            type="submit"
            variant="contained"
            style={{ color: "white", backgroundColor: "green" }}
          >
            {submitButtonText(tag, editing, hasFacilities, t)}
          </Button>
        ) : (          
          <Button
            variant="contained"
            style={{ color: "white", backgroundColor: "#0D6AAD" }}
            onClick={() => openAddForm()}
          >
            <AddIcon />
            {addButtonText(tag, hasFacilities, t)}
          </Button>
        )}
      </div>
    </div>
  );
};

export default FilterDialogNavbar;
