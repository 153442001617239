import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { useTranslation } from 'react-i18next';

const isLiveApp = process.env.REACT_APP_TYPE === "LIVE" && process.env.REACT_APP_TYPE !== "VIEW"? true: false;

const ModalLastLocationDetails = props => {
  const { lastLocation, open, handleClose } = props;
  
  const utcTime = lastLocation? moment.utc(lastLocation.lastLocationTime).toDate(): false;
  const convertedTimeTokyo = utcTime? moment(utcTime).local().format('YYYY-MM-DD HH:mm'): false;
  const {t} = useTranslation();

  return (
    <Dialog 
      open={lastLocation? open : false } 
      onClose={handleClose} 
      className={isLiveApp? "last-location-modal dark-mode": "last-location-modal"}
    >
      <Button onClick={handleClose} className="close-modal-btn">
        <CloseIcon />
      </Button>
      <Typography 
        variant="h4" 
        style={{ color: "#0E6AAD", marginBottom: "10px" }}
      >
        {lastLocation ? lastLocation.name : null}
      </Typography>
      <Divider />
      <Divider style={{ marginBottom: "15px" }}/>
      <Typography style={{ fontWeight: "bold", marginBottom: "5px" }}>
        {t("Device Name")}
      </Typography>
      <Typography style={{marginBottom: "5px"}}>
        {lastLocation ? lastLocation.name : null}
      </Typography>
      <Typography style={{ fontWeight: "bold", marginBottom: "5px" }}>
        {t("Last Location Updated at")}
      </Typography>
      <Typography style={{ marginBottom: "5px" }}>
        {convertedTimeTokyo? convertedTimeTokyo: null}
      </Typography>        
    </Dialog>
  );
};

export default ModalLastLocationDetails;
