import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress"

import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/Check";
import CheckCircle from "@material-ui/icons/CheckCircle";

const geocore = require("@mekasmith/geocore");

const styles = {
  root: {
    width: "100%",
    overflowX: "auto",
    height: "360px"
  },
  table: {
    minWidth: 650
  },
  head: {
    backgroundColor: "#fff",
    position: "sticky",
    top: 0,
    zIndex: 1
  },
  footer: {
    backgroundColor: "#fff",
    position: "sticky",
    top: 0,
    zIndex: 1
  }
};

class AddGroupMemberTable extends React.Component {
  state = {
    freeUsers: [],
    loadingFreeUsers: true,
    activeRow: null,
    loading: false,
  };
  
  componentDidMount() {
    this.getFreeUsers();
  }
  
  getFreeUsers = () => {
    this.setState({ loading: true });
    geocore.groups.users(this.props.parentGroup.id).then(groupsUsers => {
      const filteredUsers = [];
      let usersProcessed = 0;
      groupsUsers.forEach((groupUser, index, array) => {
        const user = groupUser.pk.user;
        geocore.users.groups(user.id).then(userGroups => {
          const hasUserGroup = userGroups.filter(userGroup =>
            userGroup.pk.group.id.includes("GRO-TNHP-1-G-")
            );
            if (hasUserGroup.length === 0 && !user.id.includes("USE-TNHP-1-A")) {
            filteredUsers.push(user);
          }
          usersProcessed++;
          if (usersProcessed === array.length) {
            this.setState({
              freeUsers: filteredUsers,
              loading: false
            });
          }
        });
      });
    });
  };
  
  addMember = () => {
    geocore.groups
      .manage(this.props.selectedGroup.id, [this.state.selectedUserId], null)
      .then(res => {
        this.props.closeDialog(true);
      });
  };

  setActiveRow = (index, user) => {
    this.setState({
      activeRow: index,
      selectedUserId: user.id
    });
  };

  renderTableBody = () => {
    return this.state.freeUsers.map((user, index) => (
      <TableRow
        key={index}
        onClick={() => this.setActiveRow(index, user)}
        className={
          this.state.activeRow === index ? "active table-row" : "table-row"
        }
      >
        <TableCell component="th" scope="row">
          {this.state.activeRow === index ? <CheckCircle /> : null}
          {user.name}
        </TableCell>
        <TableCell component="th" scope="row">
          {user.id}
        </TableCell>
      </TableRow>
    ));
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell align="left">端末</TableCell>
              <TableCell align="left">ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.renderTableBody()}</TableBody>
          {this.state.loading?               
            <div className="loading-map no-map">
              <div className="loading-map-spinner-container">
                <CircularProgress
                  size={40}
                  thickness={6}
                  className="loading-map-spinner"
                  />
              </div>
            </div>:
            <TableFooter className={classes.footer}>
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.props.closeDialog}
                >
                  <CancelIcon />
                  キャンセル
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#0D6AAD", color: "white" }}
                  onClick={this.addMember}
                >
                  <CheckIcon />
                  登録
                </Button>
              </div>
            </TableFooter>
          }
        </Table>
      </Paper>
    );
  }
}

export default withStyles(styles)(AddGroupMemberTable);
