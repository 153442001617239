import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { useTranslation } from 'react-i18next';

import EditIcon from "@material-ui/icons/Edit";
import PersonPin from "@material-ui/icons/PersonPinCircle";

const styles = {
  root: {
    width: "100%",
    marginTop: "10px",
    overflowX: "auto"
  }
};

const tableTitleText = (tag, hasFacilities, t) => {
  switch (tag) {
    case t("Devices"):
      return t("Group Name");
    case t("Facility Information"):
      if (hasFacilities) {
        return t("Facility Name")
      } else {
        return t("Category Name");
      }
    case t("Admin Comment"):
      return t("Comment Name");
    case t("Incident"):
      return t("Incident Name");
    default:
      break;
  }
};

const FilterDialogTable = ({
  classes,
  filterListObjs,
  toggleFilterForm,
  hasFacilities,
  toggleFacilityPlaces,
  tag,
}) => {
  const { t } = useTranslation();

  return (
    <Paper className={classes.root}>
      <Table className="responsive-side-table" padding={'none'}>
        <TableHead>
          <TableRow>
            <TableCell>{tableTitleText(tag, hasFacilities, t)}</TableCell>
            <TableCell align="right" />
            {tag === t("Facility Information") && !hasFacilities? 
              <TableCell align="right" />
            : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {filterListObjs && filterListObjs.map((listObj, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {listObj.name}
              </TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#0D6AAD", color: "white" }}
                  onClick={() => toggleFilterForm(listObj)}
                >
                  <EditIcon
                    style={{
                      fontSize: "18px",
                      marginRight: "5px",
                      color: "white"
                    }}
                  />
                  {t("Edit")}
                </Button>
              </TableCell>
              {tag === t("Facility Information") && !hasFacilities? 
                <TableCell align="right">
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#0D6AAD", color: "white" }}
                    onClick={() => toggleFacilityPlaces(listObj)}
                  >
                    <PersonPin
                      style={{
                        fontSize: "18px",
                        marginRight: "5px",
                        color: "white"
                      }}
                      />
                    {t("Facility Management")}
                  </Button>
                </TableCell>
              : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default withStyles(styles)(FilterDialogTable);
