import React, { useState, useEffect, useCallback, useMemo  } from "react";
import FilterDropdownItem from "./FilterDropdownItem";
import { useTranslation } from 'react-i18next';

const FilterList = (props) => {
  const [parsedLevels, setParsedLevels] = useState([]);
  const [parsedTags, setParsedTags] = useState([]);
  const [parsedIncidents, setParsedIncidents] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setFilterData();
  }, [props.tags, props.incidentTags, props.levels, props.parentGroup]);
  
  const setFilterData = useCallback(() => {
    const { tags, incidentTags, levels, parentGroup } = props;
    let newParsedLevels = [];
    let newParsedTags = [];
    let newParsedIncidents = [];
    
  
    if (tags) {
      tags.forEach(item => {
        let color = item.customData ? item.customData.color : "blue";
        newParsedTags.push({ name: item.name, color: color, id: item.id });
      });
    }
  
    if (levels) {
      levels.forEach(level => {
        let levelName = level.pk.tag.name;
        let hazardName = level.customData && level.customData.name ? level.customData.name : levelName;
        let customName = parentGroup.customData && parentGroup.customData[levelName] ? parentGroup.customData[levelName] : hazardName;
        newParsedLevels.push({ 
          name: customName, 
          levelName,
          id: level.pk.tag.id
        });
      });
    }
  
    if (incidentTags) {
      incidentTags.forEach(item => {
        let color = item.customData ? item.customData.color : "blue";
        newParsedIncidents.push({ name: item.name, color: color, id: item.id });
      });
    }
  
    setParsedLevels(newParsedLevels);
    setParsedTags(newParsedTags);
    setParsedIncidents(newParsedIncidents);
  }, [props.tags, props.incidentTags, props.levels, props.parentGroup]);


  const filterItemBuilder = (title, index) => {
    switch (title) {
      case t("Incident"):
        return (
          <FilterDropdownItem
            tag={title}
            filterList={parsedIncidents}
            filterListObjs={props.incidentTags}
            key={index}
            {...props}
            colorList={true}
          />
        );
      case t("Urgency Level"):
        return (
          <FilterDropdownItem
            tag={title}
            filterList={parsedLevels}
            key={index}
            {...props}
          />
        );
      case t("Devices"):
        return (
          <FilterDropdownItem
            user={props.user}
            tag={title}
            filterList={props.groups}
            filterListObjs={props.groups}
            key={index}
            {...props}
          />
        );
      case t("Date Range"):
        return (
          <FilterDropdownItem
            tag={title}
            filterList={false}
            filterRange={props.filterRange}
            setDates={props.setDates}
            key={index}
            {...props}
          />
        );
      case t("Device Last Location"):
        return (
          <FilterDropdownItem
            tag={title}
            filterList={props.groups}
            key={index}
            {...props}
          />
        );
      case t("Facility Information"):
        return (
          <FilterDropdownItem
            tag={title}
            filterList={parsedTags}
            filterListObjs={props.tags}
            colorList={true}
            key={index}
            {...props}
          />
        );
      case t("Admin Comment"):
        return (
          <FilterDropdownItem
            tag={title}
            filterList={[{ name: t("Display Comments"), id: t("Display Comments") }]}
            filterListObjs={props.comments}
            key={index}
            {...props}
          />
        );
      default:
        break;
    }
  };

  const tagsArray = useMemo(() => {
    return [t("Incident"), t("Urgency Level"), t("Devices"), t("Date Range"), t("Device Last Location"), t("Facility Information"), t("Admin Comment")];
  }, [t]);

  const filterItemComponents = useMemo(() => {
    return tagsArray.map((tag, index) => filterItemBuilder(tag, index));
  }, [tagsArray, parsedIncidents, parsedLevels, parsedTags, props]);

  return (
    <div className="filters-main-container">
      <div className="scroll-hidden">
        {filterItemComponents}
      </div>
    </div>
  );
};

export default FilterList;
