import React from 'react';
import Button from "@material-ui/core/Button";

import { downloadKml } from '../helpers/export-to-kml';
import { useTranslation } from 'react-i18next';

const ExportKmlButton = ({ realtimeStorageData }) => {
  const { t, i18n  } = useTranslation();
  const currentLanguage = i18n.language;
  return (
    <Button
      variant="contained"
      color="secondary"
      className={`edit-button bottom-bar export ${currentLanguage === "en"?"en":"ja"}`}
      onClick={() => downloadKml(realtimeStorageData)}
    >
      {t("Export")}
    </Button>
  );
};

export default ExportKmlButton;
