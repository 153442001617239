import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';

const GreenRadio = withStyles({
  root: {
    color: '#00E13C',
    '&$checked': {
      color: '#00E13C',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const BlueRadio = withStyles({
  root: {
    color: '#5781FC',
    '&$checked': {
      color: '#5781FC',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const YellowRadio = withStyles({
  root: {
    color: '#FCF357',
    '&$checked': {
      color: '#FCF357',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const PurpleRadio = withStyles({
  root: {
    color: '#7E55FC',
    '&$checked': {
      color: '#7E55FC',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const PinkRadio = withStyles({
  root: {
    color: '#E14F9E',
    '&$checked': {
      color: '#E14F9E',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const OrangeRadio = withStyles({
  root: {
    color: '#FF9900',
    '&$checked': {
      color: '#FF9900',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const LightBlueRadio = withStyles({
  root: {
    color: '#55D7D7',
    '&$checked': {
      color: '#55D7D7',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

class RadioButtons extends React.Component {

  state = {
    selectedValue: '',
    setSelectedValue: '',
    color: this.props.color,
  };

  handleChange = e => {
    this.setState({ color: e.target.value }, () => {
      this.props.setSelectedColor(this.state.color);
    });
  };
  
  render() {
    return (
      <div>
        <BlueRadio
          checked={this.state.color === 'blue'}
          onChange={this.handleChange}
          value="blue"
          name="radio-button-demo"
          inputProps={{ 'aria-label': 'blue' }}
        />
        <YellowRadio
          checked={this.state.color === 'yellow'}
          onChange={this.handleChange}
          value="yellow"
          name="radio-button-demo"
          inputProps={{ 'aria-label': 'yellow' }}
        />
        <PurpleRadio
          checked={this.state.color === 'purple'}
          onChange={this.handleChange}
          value="purple"
          name="radio-button-demo"
          inputProps={{ 'aria-label': 'purple' }}
        />
        <PinkRadio
          checked={this.state.color === 'pink'}
          onChange={this.handleChange}
          value="pink"
          name="radio-button-demo"
          inputProps={{ 'aria-label': 'pink' }}
        />
        <OrangeRadio
          checked={this.state.color === 'orange'}
          onChange={this.handleChange}
          value="orange"
          name="radio-button-demo"
          inputProps={{ 'aria-label': 'orange' }}
        />
        <LightBlueRadio
          checked={this.state.color === 'lightblue'}
          onChange={this.handleChange}
          value="lightblue"
          name="radio-button-demo"
          inputProps={{ 'aria-label': 'lightblue' }}
        />
        <GreenRadio
          checked={this.state.color === 'green'}
          onChange={this.handleChange}
          value="green"
          name="radio-button-demo"
          inputProps={{ 'aria-label': 'green' }}
        />
      </div>
    );
  }
}

export default RadioButtons