import React from "react";
import {simple, hazard} from './MapStyles';
import "../../styles/base.scss";
import "../../styles/map.scss";
import { withTranslation } from 'react-i18next';

class HazardMap extends React.Component {

  state = {
    showDropdown: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps && prevProps.mapStyle !== this.props.mapStyle) {
      this.getMapStyle();
    }
  }

  getMapStyle = () => {
    const { mapStyle } = this.props;
    let mapType;
    
    switch (mapStyle) {
      case 'simple':
        mapType = simple;
        break;
      case 'hazard':
        mapType = hazard;
        break;
      default:
        mapType = mapStyle;
        break;
    }

    if (mapType) {
      if (mapType === 'satellite') {
        this.map.setOptions({
          controlSize: 30,
          mapTypeId: 'satellite',
          styles: 'simple'
        });
      } else if (mapType === 'terrain') {
        this.map.setOptions({
          controlSize: 30,
          mapTypeId: 'terrain',
          styles: 'simple'
        });
      } else {
        this.map.setOptions({
          controlSize: 30,
          mapTypeId: 'roadmap',
          styles: mapType
        });
      }
    }
  }

  onScriptLoad = () => {

    this.map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      this.props.options
    );

    this.getMapStyle();

    /* Custom UI Elements */
    const settingsBtn = document.createElement('div');
    const settingsDropdown = document.createElement('div');
    const searchBox = document.createElement('div');

    this.hazardMapSettings(settingsBtn, settingsDropdown, searchBox, this.map);

    settingsBtn.index = 1;
    settingsDropdown.index = 1;
    searchBox.index = 1;
    
    this.map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(settingsBtn);
    this.map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(settingsDropdown);
    this.map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(searchBox);

    this.props.onMapLoad(this.map);
  };

  hazardMapSettings = (settingsBtn, settingsDropdown, searchBox, map) => {
    const { t,i18n } = this.props;

    // Border
    const settingsBtnUI = document.createElement('div');
    settingsBtnUI.className = "settings-btn-border";

    // Interior
    const settingsBtnText = document.createElement('div');
    settingsBtnText.className = "settings-btn-text";
    settingsBtnText.innerHTML = t("Map Settings");

    /** Dropdown Menu CSS **/ 

    // Border
    const settingsDropdownUI = document.createElement('div');
    settingsDropdownUI.className = "settings-dropdown-border show-dropdown-false";

    // Interior
    const settingsDropdownOptionOne = document.createElement('div');
    const settingsDropdownOptionTwo = document.createElement('div');
    settingsDropdownOptionOne.className = "settings-dropdown-text";
    settingsDropdownOptionOne.innerHTML = t("Set Default");
    settingsDropdownOptionTwo.className = "settings-dropdown-text";
    settingsDropdownOptionTwo.innerHTML = t("Reset to Default");

    /** SearchBox **/

    const searchBoxInput = document.createElement('input');
    searchBoxInput.id = "pac-input"
    searchBoxInput.className = "controls"
    searchBoxInput.setAttribute("type", "text");
    const currentLanguage = i18n.language;

    var options = {
      componentRestrictions: {
        country: currentLanguage === "en"? "US":"JP"
      }
    };
    
    const search = new window.google.maps.places.Autocomplete(searchBoxInput, options);
    this.enableEnterKey(searchBoxInput);

    /* Append */

    // Settings Button 
    settingsBtn.appendChild(settingsBtnUI);
    settingsBtnUI.appendChild(settingsBtnText);

    // Dropdown Menu
    settingsDropdown.appendChild(settingsDropdownUI);
    settingsDropdownUI.appendChild(settingsDropdownOptionOne);
    settingsDropdownUI.appendChild(settingsDropdownOptionTwo);

    // SearchBox 
    searchBox.appendChild(searchBoxInput);

    /* Click Events */

    // Click Event, Toggle Dropdown Visibility and Map Style Buttons
    const mapStyleButtons = document.getElementsByClassName("map-styles-container")[0];

    settingsBtnUI.addEventListener('click', () => {
      this.setState(prevState => ({ 
        showDropdown: !prevState.showDropdown
      }));
      settingsDropdownUI.className = `settings-dropdown-border show-dropdown-${this.state.showDropdown}`;
      mapStyleButtons.className = `map-styles-container slide-${this.state.showDropdown}`
    });

    // Click Event, Zoom Level
    settingsDropdownOptionOne.addEventListener('click', () => {
      this.props.handleOpenSettings();
    });

    // Click Event, Reset to Default
    settingsDropdownOptionTwo.addEventListener('click', () => {
      this.props.restoreDefaults();
    });

    // SearchBox Map Event 
    search.addListener("place_changed", () => {
      const place = search.getPlace();
      
      if(window.searchMarkers) {
        window.searchMarkers.setMap(null);
      }

      if (!place.geometry) return;

      window.searchMarkers =
        new window.google.maps.Marker({
          map,
          animation: window.google.maps.Animation.DROP,
          title: place.name,
          position: place.geometry.location,
        });

      window.searchMarkers.addListener("click", () => {
        window.searchMarkers.setMap(null);
      });

      const customZoomLevel = this.props.userSettings && this.props.userSettings.initialZoom? parseInt(this.props.userSettings.initialZoom) : 10;

      /*

      Removed this because at deep zooms you end up in the ocean most the time,
      not sure if it was here for another reason though?
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
        map.setZoom(customZoomLevel);
        this.props.getPlacesByArea();
      } else {
      */
      map.setCenter(place.geometry.location);
      map.setZoom(customZoomLevel);
      this.props.getPlacesByArea();
    });
  };

  enableEnterKey = input => {
    const _addEventListener = input.addEventListener;

    const addEventListenerWrapper = (type, listener) => {
      if (type === "keydown") {
        const _listener = listener;
        listener = event => {
          const suggestionSelected = document.getElementsByClassName('pac-item-selected').length;

          if (event.key === 'Enter' && !suggestionSelected) {
            const e = new KeyboardEvent(
              "keydown", 
              { 
                key: "ArrowDown", 
                code: "ArrowDown", 
                keyCode: 40 
              }
            );
            
            _listener.apply(input, [e]);
          }

          _listener.apply(input, [event]);
        };
      }
      _addEventListener.apply(input, [type, listener]);
    };
    input.addEventListener = addEventListenerWrapper;
  };

  componentDidMount() {
    if (!window.google) {
      let script = document.createElement("script");

      script.type = "text/javascript";
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD7WC_mBt8r9GXRw7-ZYYGWweMB02D9y5w`;

      let topLevelScript = document.getElementsByTagName("script")[0];
      topLevelScript.parentNode.insertBefore(script, topLevelScript);
      script.addEventListener("load", e => {
        this.onScriptLoad();
      });
    } else {
      this.onScriptLoad();
    }
  }

  render() {
    return <div style={{ width: "100%", height: "100%" }} id={this.props.id} />;
  }
}

export default withTranslation()(HazardMap)