import React from "react";

import { withStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import { withTranslation } from 'react-i18next';

import { ReactComponent as HazardViewLogo } from "../images/hazardview-logo.svg";
import { ReactComponent as HazardLiveLogo } from "../images/HazardLiveLogo-pb.svg";

const isLiveApp = process.env.REACT_APP_TYPE === "LIVE" && process.env.REACT_APP_TYPE !== "VIEW"? true: false;

const styles = theme => ({
  navbar: {
    margin: "0px",
  },
  icon: {
    color: isLiveApp? "#fff": "#0E6AAD",
  },
});

class NavigationBar extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = e => this.setState({ anchorEl: e.currentTarget });

  handleClose = () => this.setState({ anchorEl: false });

  handleLogout = () => {
    this.setState({ anchorEl: false });
    this.props.handleUserLogout();
  };

  render() {
    const { classes } = this.props;
    const anchorEl = this.state.anchorEl;
    const { t } = this.props;

    return (
      <div className={classes.navbar}>
        <div className="navbar-container">
          <div className="hazard-logo">
            {isLiveApp?
              <HazardLiveLogo />:
              <HazardViewLogo />
            }
          </div>
          <div className="navbar-menu">
            <IconButton
              aria-owns={this.state.anchorEl ? "simple-menu" : null}
              aria-haspopup="true"
              onClick={this.handleClick}
            >
              <MenuIcon className={classes.icon} />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl ? anchorEl : null}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              <MenuItem
                onClick={() => {
                  this.props.handleUserSettings();
                  this.handleClose();
                }}
              >
                {t("Settings")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.handleLogout();
                }}
              >
                {t("Logout")}
              </MenuItem>
              <MenuItem
                onClick={()=> window.open("https://telenet.co.jp/ht_dl/view/hazard_view_manual.pdf", "_blank")}
              >
                {t("Help")}
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(NavigationBar));

