import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { useTranslation } from 'react-i18next';

const isLiveApp = process.env.REACT_APP_TYPE === "LIVE" && process.env.REACT_APP_TYPE !== "VIEW"? true: false;

const ModalCommentDetails = props => {
  const { comment, open, handleClose } = props;
  const {t} = useTranslation();
  return (
    <Dialog 
      open={comment? open : false } 
      onClose={handleClose} 
      className={isLiveApp? "last-location-modal dark-mode": "last-location-modal"}
    >
      <Button onClick={handleClose} className="close-modal-btn">
        <CloseIcon />
      </Button>
      <Typography 
        variant="h4" 
        style={{ color: "#0E6AAD", marginBottom: "10px" }}
      >
        {comment ? comment.name : null}
      </Typography>
      <Divider />
      <Divider style={{ marginBottom: "15px" }}/>
      <Typography style={{ fontWeight: "bold", marginBottom: "5px" }}>
        {t("Admin Comment")}
      </Typography>
      <Typography style={{marginBottom: "5px"}}>
        {comment ? comment.userName: null}
      </Typography>
      <Typography style={{ fontWeight: "bold", marginBottom: "5px" }}>
        {t("Created At")}
      </Typography>
      <Typography style={{ marginBottom: "5px" }}>
        {comment? moment.utc(comment.createTime).local().format("YYYY/MM/DD (ddd) HH:mm"): null}
      </Typography>        
      <Typography style={{ fontWeight: "bold", marginBottom: "5px" }}>
        {t("Comment")}
      </Typography>
      <Typography style={{ marginBottom: "5px" }}>
        {comment ? comment.description : null}
      </Typography>
    </Dialog>
  );
};

export default ModalCommentDetails;
