import React from "react";
import { withStyles } from "@material-ui/core/styles";

import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import FullscreenImage from "../components/FullscreenImage";
import { withTranslation } from 'react-i18next';

const styles = {
  root: {
    maxWidth: 400,
    flexGrow: 1,
    minHeight: 533,
    position: "relative",
    backgroundColor: "#060606"
  },
  img: {
    maxWidth: 400,
    overflow: "hidden",
    display: "block",
    width: "100%"
  }
};

class PlaceListPhotoStepper extends React.Component {
  state = {
    activeStep: 0,
    open: false
  };

  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
  };

  handleFullscreen = () => {
    // Make Selected Place Image Fullscreen
    const selectedImage = document.getElementById('current-place-image');
    selectedImage.requestFullscreen({ navigationUI: "show" }).then(() => {
      // Enable wheelzoom

    }).catch(err => {
      console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
    });
  };

  zoomIn = () => {
    console.log("IN");
  };

  zoomOut = () => {
    console.log("OUT");
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
 
  render() {
    const steps = this.props.photoURLs && this.props.photoURLs.length > 0? this.props.photoURLs.map((photoURL) => ({
          imgPath: photoURL
        })) : "";

    const { classes } = this.props;
    const maxSteps = steps.length;

    const selectedImageURL = this.props.photoURLs && this.props.photoURLs.length > 0? steps[this.state.activeStep].imgPath : "";
    const { t } = this.props;

    return (
      <div className={classes.root}>
        <FullscreenImage 
          open={this.state.open}
          close={this.handleClose}
          src={selectedImageURL}
        />
        <img
          className={classes.img}
          id="current-place-image"
          src={this.props.photoURLs && this.props.photoURLs.length > 0? steps[this.state.activeStep].imgPath : ""}
          alt="Hazard Place"
          onClick={this.handleOpen}
        />
        {maxSteps > 1 ? (
          <MobileStepper
            steps={maxSteps}
            position="static"
            variant="text"
            className="slide-show-stepper-buttons"
            activeStep={this.state.activeStep}
            nextButton={
              <Button
                size="small"
                onClick={this.handleNext}
                className={this.state.activeStep === maxSteps - 1? 'is-disabled-btn': null}
                disabled={this.state.activeStep === maxSteps - 1}
              >
                {t("Next")}
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={this.handleBack}
                className={this.state.activeStep === 0? 'is-disabled-btn': null}
                disabled={this.state.activeStep === 0}
              >
                <KeyboardArrowLeft />
                {t("Back")}
              </Button>
            }
          />
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation()(PlaceListPhotoStepper));
