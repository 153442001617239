import React from "react";

import FilterDropdownItem from "./FilterDropdownItem";
import { withTranslation } from 'react-i18next';


class FilterList extends React.Component {
  state = {
    parsedIncidents: []
  };

  componentDidMount() {
    this.setFilterData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.incidentTags !== this.props.incidentTags) {
      this.setFilterData();
    }
  }

  setFilterData = () => {
    var hasIncidents = this.props.incidentTags ? this.props.incidentTags : false;

    let parsedIncidents = [];

    if (hasIncidents) {
      this.props.incidentTags.forEach(item => {
        let color = item.customData ? item.customData.color : "blue";
        parsedIncidents.push({ name: item.name, color: color, id: item.id });
      });
    }

    this.setState({
      parsedIncidents
    });
  };

  filterItemBuilder = (title, index) => {
    const {t} = this.props;
    switch (title) {
      case t("Incident"):
        return (
          <FilterDropdownItem
            tag={title}
            filterList={this.state.parsedIncidents}
            filterListObjs={this.props.incidentTags}
            key={index}
            registerFilterItems={this.props.registerFilterItems}
            parentGroup={this.props.parentGroup}
            removePlaceIncident={this.props.removePlaceIncident}
            removeIncidentTag={this.props.removeIncidentTag}
            updateIncidentPlaces={this.props.updateIncidentPlaces}
            geocoreAddIncidentTag={this.props.geocoreAddIncidentTag}
            colorList={true}
            filterByTaisaku={this.props.filterByTaisaku}
            filterByLists={this.props.filterByLists}
            isLiveApp={this.props.isLiveApp}
          />
        );
      case t("Date Range"):
        return (
          <FilterDropdownItem
            tag={title}
            filterList={false}
            filterRange={this.props.storageLimit < 1? "": this.props.filterRange}
            setDates={this.props.setDates}
            key={index}
            registerFilterItems={this.props.registerFilterItems}
            filterByTaisaku={this.props.filterByTaisaku}
            filterByLists={this.props.filterByLists}
            isLiveTab={true}
            storageLimit={this.props.storageLimit}
          />
        );
      case t("Device Last Location"):
        return (
          <FilterDropdownItem
            tag={title}
            filterList={this.props.groups}
            key={index}
            registerFilterItems={this.props.registerFilterItems}
            filterByTaisaku={this.props.filterByTaisaku}
            filterByLists={this.props.filterByLists}
          />
        );
      default:
        break;
    }
  };

  render() {
    const {t, storageLimit} = this.props;
    const tagsArray = [t("Incident"), t("Date Range"), t("Device Last Location")];

    return (
      <div className="filters-main-container">
        <div className="scroll-hidden">
          {tagsArray.map((tag, index) =>
            this.filterItemBuilder(tag, index)
          )}
        </div>
      </div>
    );
  }
}
export default withTranslation()(FilterList);