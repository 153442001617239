import React from "react";
import { withStyles } from "@material-ui/styles";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useTranslation } from 'react-i18next';

const styles = {
  tab: {
    color: "#0e6aad",
    minWidth: "50%"
  }
};

const PlaceListTabs = ({ placeListTabSwitch, classes }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    placeListTabSwitch(newValue);
  };
  const { t } = useTranslation();

  return (
    <div className="place-list-tabs-container">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="places-list-tabs"
        TabIndicatorProps={{
          style: { 
            background: '#0e6aad'
          }
        }}
      >
        <Tab label={t("View All")} className={"tab-public-private"}/>
        <Tab label={t("Public Tab")} className={"tab-public-private"}/>
      </Tabs>
    </div>
  );
};

export default withStyles(styles)(PlaceListTabs);
