import React from "react";

import Typography from "@material-ui/core/Typography";

const PublicListEmptyAlert = ({ publicListEmptyAlert }) => {
  return (
    <div className={`public-list-empty ${publicListEmptyAlert? "is-empty" : "not-empty"}`}>
      <Typography>Public places have yet to be posted</Typography>
    </div>
  );
};

export default PublicListEmptyAlert;