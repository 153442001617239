import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { Dialog, Button } from "@material-ui/core";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const styles = {
  dialogPaper: {
    backgroundColor: "black"
  }
};

class FullscreenImage extends React.Component {
  render() {
    const { open, close, src, classes } = this.props;
    return (
      <Dialog
        open={open}
        onClose={close}
        className="fullscreen-image-modal"
        classes={{ paper: classes.dialogPaper }}
        fullScreen
      >
        <div className="dialog-transform-wrapper">
          <TransformWrapper>
            <TransformComponent>
              <img src={src} alt="Hazard Place" />
            </TransformComponent>
          </TransformWrapper>
        </div>
        <div className="close-snapshot-modal">
          <Button
            variant="contained"
            color="secondary"
            onClick={close}
          >
            Close
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(FullscreenImage);
