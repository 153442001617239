import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as PhotoCamera } from '../../images/camera-icon.svg';
import { ReactComponent as VideoCamera } from '../../images/video-camera.svg';
import PersonIcon from '@material-ui/icons/Person';
import { useTranslation } from 'react-i18next';

import '../../styles/place-list.scss';
import { use } from 'i18next';

const CustomCheckbox = withStyles({
  root: {
    color: '#57697d',
    '&$checked': {
      color: '#57697d',
    },
  },
  checked: {},
})(Checkbox);

const PlaceListItem = ({
  place,
  handlePlaceListClick,
  handlePlaceListHover,
  checkboxData,
  handleCheckbox,
  getImages,
  thumbnailURL,
}) => {
  const [images, setImages] = useState(null);
  const [video, setVideo] = useState(null);
  const [imageCount, setImageCount] = useState(null);
  const [videoCount, setVideoCount] = useState(null);

  const { t } = useTranslation(); 

  useEffect(() => {
    let isMounted = true; 
    
    const fetchData = async () => {
      const URLs = await getImages(place);
      if (isMounted) {
        setImages(URLs.photoURLs);
        setVideo(URLs.videoURL);
      }
    };

    if (!thumbnailURL && !images) {
      fetchData();
    }

    if(place && place.images) {
      setImageCount(place.images.filter((im) => !im.includes("low")).length);
    } else {
      setImageCount(0);
    }

    if(place && place.video) {
      setVideoCount(place.video.length);
    } else {
      setVideoCount(0);
    }

    return () => {
      isMounted = false;
    };
  }, [images, getImages, place]);


  const hasLevelName = (levelName, customName) => {
    let hazardName;
    switch (levelName) {
      case '緊急度1':
        hazardName = customName ? customName : t('Alert 4');
        return hazardName;
      case '緊急度2':
        hazardName = customName ? customName : t('Alert 3');
        return hazardName;
      case '緊急度3':
        hazardName = customName ? customName : t('Alert 2');
        return hazardName;
      case '緊急度4':
        hazardName = customName ? customName : t('Alert 1');
        return hazardName;
      default:
        break;
    }
  };

  const getIncidentTagNames = (incidentTags) => {
    if (incidentTags.length > 2) {
      return incidentTags.map((incidentTag) => (
        <div className="place-list-incident-tag" key={incidentTag.name}>
          <div
            className={`place-list-incident-tag-color ${
              incidentTag.customData && incidentTag.customData.color
                ? incidentTag.customData.color
                : ''
            }`}
          ></div>
        </div>
      ));
    } else {
      return incidentTags.map((incidentTag) => (
        <div className="place-list-incident-tag" key={incidentTag.name}>
          <div
            className={`place-list-incident-tag-color ${
              incidentTag.customData && incidentTag.customData.color
                ? incidentTag.customData.color
                : ''
            }`}
          ></div>
          <Typography variant="h5" className="place-list-incident-tag-name">
            {truncate(incidentTag.name, 8)}
          </Typography>
        </div>
      ));
    }
  };

  const truncate = (input, length) => {
    if (input.length > length) return input.substring(0, length) + '...';
    else return input;
  };

  const hasImages = images && images[0] && images.length;
  return (
    <ListItem
      key={place.placeId}
      className="list-item-container"
      onClick={() => handlePlaceListClick(place)}
      onMouseOver={(e) => handlePlaceListHover(e, place, true)}
      onMouseOut={(e) => handlePlaceListHover(e, place, false)}
    >
      <div className="place-list-checkbox-container">
          {place.isSos? null:
          <CustomCheckbox
            className="place-list-checkbox"
            checked={!!checkboxData[place.placeId]}
            onClick={handleCheckbox(place.placeId)}
            style={{ padding: 0 }}
            value={place.placeId}
          />}
        </div>
        {place.isLiveStream ? (
          <div
            className="place-thumb-image-container isLiveStream"
            style={{
              backgroundImage:
                "url(" + require("../../images/incoming-stream.jpg") + ")",
            }}
          />
        ) : (
          <div
            className={`place-thumb-image-container ${place.isSos? "sos-place-image": "std-place-image"}`}
            style={{
              backgroundImage: `url("${
                thumbnailURL? thumbnailURL:
                hasImages? images[0] : ""
            }")`,
            }}
          />
        )}
        <div className={`place-list-item-content ${place.isSos? "sos-place-card": "std-place-card"}`}>
          {place.taisaku === "true" ? (
            <span className={`taisaku-label is-public-${place.isPublic}`}>
              {t("Resolve")}
            </span>
          ) : null}
          {place.isPublic ? (
            <span className="public-place-label">{t("Public")}</span>
          ) : null}
          <div className="hazard-level-label list-label-hazard">
            <span
              className={`hazard-level-${
                place.hazardLevel.name.split("")[
                  place.hazardLevel.name.length - 1
                ]
              }`}
            >
            {place.isSos? "SOS!":
              <>
              {hasLevelName(
                place.hazardLevel.name,
                place.hazardLevel.customName
              )}
              </>
            }
            </span>
          </div>
          <div className="place-list-item-date">
            <Typography variant="h3">{place.createTime}</Typography>
          </div>
          <Typography variant="h4" className="place-list-item-group-name">
            {place.groupName}
          </Typography>
          <div className="place-list-item-username-container">
            <PersonIcon />
            <Typography variant="h5" className="username">
              {place.user}
            </Typography>
          </div>
          {place.comment ? (
            <Typography variant="h5" className="place-list-item-notes">
              {place.comment}
            </Typography>
          ) : (
            <div className="place-list-no-comment"></div>
          )}
          {place.incidentTags.length > 0 ? (
            <div className="place-list-incident-tags-container">
              {getIncidentTagNames(place.incidentTags)}
            </div>
          ) : (
            <div></div>
          )}
          
          <div className="place-list-media-icon">
            {place.isSos? null:
            <>
            {videoCount && videoCount > 0 ? <VideoCamera /> : <PhotoCamera />}
            <div className="circle-counter-places">
              <Typography>
                {imageCount}
              </Typography>
            </div>
            </>
            }
          </div>
          
          {place.hasRead && !place.isSos? (
            <div className="has-read-label">
              <Typography variant="h3">{t("Viewed")}</Typography>
            </div>
          ) : null}
        </div>
    </ListItem>
  );
};

export default PlaceListItem;
