import React from "react";

import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { useTranslation } from 'react-i18next';

const HazardUserSettingsNavbar = ({ handleClose }) => {
  const { t } = useTranslation();

  return (
    <div className="dialog-nav-container">
      <Button
        variant="contained"
        style={{ color: "white", backgroundColor: "#0D6AAD" }}
        onClick={() => handleClose()}
      >
        <ArrowBack />
        {t("Back")}
      </Button>
    </div>
  );
};

export default HazardUserSettingsNavbar;
