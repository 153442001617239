import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const RedRadio = withStyles({
  root: {
    color: "#f33",
    "&$checked": {
      color: "#f33"
    },
    maxHeight: "24px"
  },
  checked: {}
})(props => <Radio color="default" {...props} size="small" />);

const OrangeRadio = withStyles({
  root: {
    color: "#fc3",
    "&$checked": {
      color: "#fc3"
    },
    maxHeight: "24px"
  },
  checked: {}
})(props => <Radio color="default" {...props} size="small" />);

const GreenRadio = withStyles({
  root: {
    color: "#cf3",
    "&$checked": {
      color: "#cf3"
    },
    maxHeight: "24px"
  },
  checked: {}
})(props => <Radio color="default" {...props} size="small" />);

const BlueRadio = withStyles({
  root: {
    color: "#3cf",
    "&$checked": {
      color: "#3cf"
    },
    maxHeight: "24px"
  },
  checked: {}
})(props => <Radio color="default" {...props} size="small" />);

class EditPlaceListRadioButtons extends React.Component {
  state = {
    hazardLevel: this.props.hazardLevel
  };

  handleChange = e => {
    this.setState({ hazardLevel: e.target.value }, () => {
      this.props.setHazardLevel(this.state.hazardLevel);
    });
  };

  render() {
    const { parsedLevels } = this.props;
    return (
      <RadioGroup>
        <FormControlLabel 
          value="緊急度1"
          control={
            <RedRadio
              checked={this.state.hazardLevel === "緊急度1"}
              onChange={this.handleChange}
              value="緊急度1"
              inputProps={{ "aria-label": "red" }}
            />
          } 
          label={parsedLevels && parsedLevels.find(level => level.levelName === "緊急度1").name}
          className="color-picker-radio" 
        />
        <FormControlLabel 
          value="緊急度2"
          control={
            <OrangeRadio
              checked={this.state.hazardLevel === "緊急度2"}
              onChange={this.handleChange}
              value="緊急度2"
              inputProps={{ "aria-label": "orange" }}
            />
          } 
          label={parsedLevels && parsedLevels.find(level => level.levelName === "緊急度2").name}
          className="color-picker-radio"
        />
        <FormControlLabel 
          value="緊急度3"
          control={
            <GreenRadio
              checked={this.state.hazardLevel === "緊急度3"}
              onChange={this.handleChange}
              value="緊急度3"
              inputProps={{ "aria-label": "green" }}
            />
          } 
          label={parsedLevels && parsedLevels.find(level => level.levelName === "緊急度3").name}
          className="color-picker-radio"
        />
        <FormControlLabel 
          value="緊急度4"
          control={
            <BlueRadio
              checked={this.state.hazardLevel === "緊急度4"}
              onChange={this.handleChange}
              value="緊急度4"
              inputProps={{ "aria-label": "blue" }}
            />
          } 
          label={parsedLevels && parsedLevels.find(level => level.levelName === "緊急度4").name}
          className="color-picker-radio"
        />
      </RadioGroup>
    );
  }
}

export default EditPlaceListRadioButtons;
