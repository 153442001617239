import React from "react";

import moment from "moment";
import MomentUtils from "@date-io/moment";

import { MuiThemeProvider, createMuiTheme, Button } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import { withTranslation } from 'react-i18next';
import i18n from '../i18n';

const isLiveApp = process.env.REACT_APP_TYPE === "LIVE" && process.env.REACT_APP_TYPE !== "VIEW"? true: false;

const materialTheme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#0E6AAD"
      }
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: "#2a73a7"
      }
    },
    MuiTab: {
      "&$selected": {
        backgroundColor: "#2f84c1"
      }
    },
    MuiPrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: "#ffc800"
      }
    },
    MuiPickersDay: {
      isSelected: {
        backgroundColor: "#2a73a7",
        color: "#fff!important"
      },
      current: {
        color: "#2a73a7"
      },
    },
    MuiPickersCalendar: {
      week: {
        "& div": {
          "&:first-child": {
            "& button": {
              color: "#F33"          
            }
          },
          "&:last-child": {
            "& button": {
              color: "#0E6AAD"         
            }
          }
        },
      }
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#2a73a7"
      }
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: "#0E6AAD"
      }
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: "#0E6AAD"
      },
      noPoint: {
        backgroundColor: "#0E6AAD"
      },
      thumb: {
        borderColor: "#0E6AAD"
      }
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        "&:first-child": {
          color: "#F33"
        },
        "&:last-child": {
          color: "#0E6AAD"
        }
      }
    }
  }
});

const materialThemeDark = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: "#fff",
        "&$focused": {
          color: "#fff!important"
        }
      },
    },
    MuiTypography: {
      body1: {
        color: "#ffffff"
      }
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "1px solid #ddd",
        },
        "&:after": {
          borderBottom: "2px solid #ffc000",
        },
      }
    },
    MuiDialog: {
      paper: {
        backgroundColor: "#0e0e0e"
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#000000"
      }
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: "#2a73a7"
      }
    },
    MuiTab: {
      "&$selected": {
        backgroundColor: "#2f84c1"
      }
    },
    MuiPrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: "#ffc800"
      }
    },
    MuiPickersDay: {
      isSelected: {
        backgroundColor: "#0095ff",
        color: "#fff!important"
      },
      isDisabled: {
        color: "#505050!important"
      },
      day: {
        color: "#fff",
      },
      current: {
        color: "#0095ff"
      },
    },
    MuiPickersCalendar: {
      week: {
        "& div": {
          "&:first-child": {
            "& button": {
              color: "#F33"          
            }
          },
          "&:last-child": {
            "& button": {
              color: "#0095ff"         
            }
          }
        },
      }
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#ffc616"
      }
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: "#0E6AAD"
      }
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: "#0E6AAD"
      },
      noPoint: {
        backgroundColor: "#0E6AAD"
      },
      thumb: {
        borderColor: "#0E6AAD"
      }
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "#0e0e0e",
        "&> *": {
          backgroundColor: "#0e0e0e",
          "&> *": {
            color: "#fff"
          }
        }
      },
      dayLabel: {
        color: "#fff",
        "&:first-child": {
          color: "#F33"
        },
        "&:last-child": {
          color: "#0095ff"
        }
      }
    }
  }
});

class LocalizedUtils extends MomentUtils {
  
  getCalendarHeaderText(date){
    return date.format(i18n.t("Date Format Header"));
  }
  getDatePickerHeaderText(date) {
    return date.format(i18n.t("Date Format"));
  }
}


class FilterDates extends React.Component {
  state = {
    startDate: moment().subtract(1, "week").unix(),
    endDate: moment().utc().unix(),
    range: "week"
  };
  
  handleStartDateChange = startDateObj => {
    // check if start date is after end date,
    //if so reset end date to have a hour ahead the time
    const {t} = this.props;
    const range = t("Custom");
    const startDate = startDateObj.unix();
    const endDate = this.state.endDate;

    if (startDate > endDate) {
      this.setState(
        {
          range,
          startDate,
          endDate: startDateObj.add(1, "h").unix()
        },
        () => {
          this.props.setDates(this.state.startDate, this.state.endDate, range);
        }
      );
    } else {
      this.setState(
        {
          range,
          startDate,
          endDate
        },
        () => {
          this.props.setDates(this.state.startDate, this.state.endDate, range);
        }
      );
    }
  };

  handleEndDateChange = endDateObj => {
    // if end date is before start date
    // reset the start date to be 1 hour before the end date
    const {t} = this.props;

    const range = t("Custom");
    const startDate = this.state.startDate;
    const endDate = endDateObj.unix();

    if (endDate < startDate) {
      this.setState(
        {
          range,
          startDate: endDateObj.subtract(1, "h").unix(),
          endDate
        },
        () => {
          this.props.setDates(this.state.startDate, this.state.endDate, range);
        }
      );
    } else {
      this.setState(
        {
          range,
          startDate,
          endDate
        },
        () => {
          this.props.setDates(this.state.startDate, this.state.endDate, range);
        }
      );
    }
  };

  searchMonthBefore = () => {
    const range = "month";
    const startDate = moment().subtract(1, range).startOf("day").unix();
    const endDate = moment().endOf("day").unix();
    const {t} = this.props;
    this.setState(
      {
        range,
        startDate,
        endDate,
      },
      () => {
        this.props.setDates(startDate, endDate, t("< Month"));
      }
    );
  };

  searchWeekBefore = () => {
    const range = "week";
    const startDate = moment().subtract(1, range).startOf("day").unix();
    const endDate = moment().endOf("day").unix();
    const {t} = this.props;
    this.setState(
      {
        range,
        startDate,
        endDate,
      },
      () => {
        this.props.setDates(startDate, endDate, t("< Week"));
      }
    );
  };

  searchToday = () => {
    const range = "today";
    const startDate = moment().startOf("day").unix();
    const endDate = moment().endOf("day").unix();
    const {t} = this.props;

    this.setState(
      {
        range,
        startDate,
        endDate,
      },
      () => {
        this.props.setDates(startDate, endDate, t("Today"));
      }
    );
  };

  storageLimitDates = (date) => {
    const dateRestriction = moment().subtract(this.props.storageLimit, "day").startOf("day");
    console.log("dateRestriction",dateRestriction);
    console.log("date", date);
    if(dateRestriction > date){
      return true
    } else {
      return false
    }
  }

  render() {
    const { startDate, endDate, range } = this.state;
    const { disabled, storageLimit, t } = this.props;
    const currentStartDate = startDate? startDate * 1000 : null;
    const currentEndDate = endDate? endDate * 1000 : null;
    return (
      <React.Fragment>
        {storageLimit > 1 || !isLiveApp?
          <>
          <div className="date-filter-buttons">
            {isLiveApp?
            <p className="storage-limit-info">{t("Storage Plan")} <span>{storageLimit}{t("Days")}</span></p>
            :null}
            <Button
              variant="contained"
              className={`filter-button today ${range === "today"? "active-range" : ""}`}
              size="small"
              onClick={this.searchToday}
              disabled={storageLimit < 1? true: disabled}
            >
              {t("Today")}
            </Button>
            <Button
              variant="contained"
              className={`filter-button week ${range === "week"? "active-range" : ""}`}
              size="small"
              onClick={this.searchWeekBefore}
              disabled={storageLimit < 7? true: disabled}
            >
              {t("< Week")}
            
            </Button>
            <Button
              variant="contained"
              className={`filter-button month ${range === "month"? "active-range" : ""}`}
              size="small"
              onClick={this.searchMonthBefore}
              disabled={storageLimit < 30? true: disabled}
            >
              {t("< Month")}
            </Button>
          </div>
          <MuiPickersUtilsProvider utils={LocalizedUtils}>
            <MuiThemeProvider theme={isLiveApp? materialThemeDark: materialTheme}>
              <div className="picker-start">
                <DatePicker
                  value={currentStartDate}
                  onChange={this.handleStartDateChange}
                  label={t("Start Date")}
                  cancelLabel={t("Cancel")}
                  ampm={"false"}
                  shouldDisableDate={isLiveApp? this.storageLimitDates: null}
                  format={t("Date Format")}
                />
              </div>
              <div className={"picker-end"}>
                <DatePicker
                  value={currentEndDate}
                  onChange={this.handleEndDateChange}
                  label={t("End Date")}
                  cancelLabel={t("Cancel")}
                  ampm={"false"}
                  shouldDisableDate={isLiveApp? this.storageLimitDates: null}
                  format={t("Date Format")}
                />
              </div>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
          </>:<p className="storage-limit-info no-storage-plan">{t("Storage Plan")} <span>{storageLimit? storageLimit: 0}{t("Days")}</span></p>}
      </React.Fragment>
    );
  }
}
export default withTranslation()(FilterDates)