import React from "react";

const mapStyles = [
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      { visibility: "off" }
    ]
  }
];

class RealtimeMap extends React.Component {
  onScriptLoad = () => {
    const map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      this.props.options
    );
    map.setOptions({ 
      styles: mapStyles
    });

    this.props.onMapLoad(map);
  };

  componentDidMount() {
    if (!window.google) {
      let script = document.createElement("script");

      script.type = "text/javascript";
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD7WC_mBt8r9GXRw7-ZYYGWweMB02D9y5w`;

      let topLevelScript = document.getElementsByTagName("script")[0];
      topLevelScript.parentNode.insertBefore(script, topLevelScript);
      script.addEventListener("load", e => {
        this.onScriptLoad();
      });
    } else {
      this.onScriptLoad();
    }
  }

  render() {
    return <div className="realtime-map" id={this.props.id} />;
  }
}

export default RealtimeMap;
