import React from "react";

import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';

const PlaceAmount = ({
  placeAmount,
  filterByTaisaku,
  disabledTaisaku,
  taisakuAmount,
  amountSelected,
}) => {
  const { t, i18n  } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <div>
      <div className="places-found-container">
        {amountSelected > 0 ? (
          <Typography variant="caption" className="places-found">{`${amountSelected} ${t("Selected")}`}</Typography>
        ) : (
          <div>
            {filterByTaisaku || disabledTaisaku? 
            <Typography variant="caption" className="places-found">
              {currentLanguage === "en"?
              `${taisakuAmount} ${t("Resolved")}`:
              `対策済 ${taisakuAmount}件`
              }
            </Typography>
            :
            <div>
              <Typography variant="caption" className="places-found">
                <b>
                  {currentLanguage === "en"?
                  `${t("Total Reports")}: ${placeAmount + taisakuAmount}`
                  :`全投稿 ${placeAmount + taisakuAmount}件`}
                  </b>
              </Typography>
              <Typography variant="caption" className="places-found">
                <b>
                  {currentLanguage === "en"?  
                  `Reports Available: ${placeAmount}`
                  :`未対策 ${placeAmount}件 表示中`}
                </b>
              </Typography>
              <Typography variant="caption" className="places-found">
                {currentLanguage === "en"?
                `(Reports Resolved: ${taisakuAmount} Hidden)`:
                `(対策済 ${taisakuAmount}件 非表示)`
              }
              </Typography>
            </div>}
          </div>
        )}
      </div>
    </div>
  );
};

export default PlaceAmount;
